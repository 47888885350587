export enum PhStatusChipType {
  submit = 'submit',
  open = 'open',
  info = 'info',

  inaudit = 'inaudit',
  days30 = 'days30',
  days45 = 'days45',

  approved = 'approved',
  registered = 'registered',
  credit = 'credit',

  cancel = 'cancel',
  denied = 'denied',
  noben = 'noben',
  duplicate = 'duplicate',
  days60 = 'days60',

  inactive = 'inactive',
  closed = 'closed',
}

export enum PhStatusChipCategory {
  generic = 'generic',
  contractLifecycle = 'contract-lifecycle',
}

export interface StatusChipData {
  type: PhStatusChipType;
  category: PhStatusChipCategory;
  tooltip?: string;
  label?: string;
}
