import { Directive, effect, ElementRef, input, InputSignal, Renderer2 } from '@angular/core';

import { BadgePosition, BadgeSize } from './badge';

@Directive({
  selector: '[phBadge]',
  standalone: true,
})
export class PhBadgeDirective {
  value: InputSignal<string> = input.required({ alias: 'phBadge' });
  badgeSize: InputSignal<BadgeSize> = input('medium');
  badgePosition: InputSignal<BadgePosition> = input('top-right');
  badgeBgColor: InputSignal<string> = input('');
  badgeTextColor: InputSignal<string> = input('');
  badgeVisible: InputSignal<boolean> = input(true);
  badgeTitle: InputSignal<string> = input('');

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {
    effect(() => {
      if (this.badgeVisible()) {
        this._createBadge(this.value());

        if (this.badgeBgColor()) {
          this._setProperty('--bg-color', this.badgeBgColor());
        }

        if (this.badgeTextColor()) {
          this._setProperty('--color', this.badgeTextColor());
        }
      }
    });
  }

  private _createBadge(value: string): void {
    const badge: HTMLElement = this.renderer.createElement('span');
    this._setClasses(badge);
    badge.textContent = value;
    this.elRef.nativeElement.classList.add('ph-badge-container');
    if (this.badgeTitle) {
      this.elRef.nativeElement.setAttribute('title', this.badgeTitle());
    }
    this.elRef.nativeElement.appendChild(badge);
  }

  private _setClasses(el: HTMLElement): void {
    el.classList.add('ph-badge');
    el.classList.add(`ph-badge--${this.badgeSize()}`);

    const [horizontal, vertical] = this.badgePosition().split('-');
    el.classList.add(`ph-badge--${horizontal}`, `ph-badge--${vertical}`);
  }

  private _setProperty(name: string, value: string): void {
    this.elRef.nativeElement.style.setProperty(name, value);
  }
}
