import { PowerUpProduct } from '@ph-model/power-up-product';
import { GetProductsResponse, ProductItem } from '@ph-model/response';
import { AvailableOptions, Products } from '@ph-model/response/interface';

import { PowerUpProductsResponse } from './powerup-products.response.model';

export function mapToGetProductsResponse(resp: PowerUpProductsResponse): GetProductsResponse {
  return {
    availableOptions: mapToAvailableOptions(resp),
    standardResponse: {
      status: resp.status,
      responseCode: resp.responseCode,
      responseDescription: resp.responseDescription,
    },
  };
}

function mapToAvailableOptions(resp: PowerUpProductsResponse): AvailableOptions {
  return {
    dealer: resp.data.dealerDetails.dealerCode,
    vin: null,
    vehicleMake: null,
    vehicleModel: null,
    vehicleYear: null,
    financeAmount: null,
    saleDate: null,
    inServiceDate: null,
    isAfterSale: null,
    vehiclePurchaseDate: null,
    vehicleTrim: null,
    financeType: null,
    vehicleCondition: null,
    vehicleCost: null,
    msrp: null,
    programName: resp.data.dealerDetails.programName,
    products: mapToProducts(resp),
    vehicleMSRP: null,
  };
}

function mapToProducts(resp: PowerUpProductsResponse): Products {
  const dealer = resp.data.dealerDetails.dealerCode;

  return {
    productItem: resp.data.productRateDetails.map((prod: PowerUpProduct) => mapToProduct(prod, dealer)),
  };
}

function mapToProduct(product: PowerUpProduct, dealer: string): ProductItem {
  return {
    dealer,
    vendor: null,
    productType: product.productType,
    productCode: product.productCode,
    productCategory: null,
    productDescription: {
      enUsProductDescription: product.productDescription,
      frCaProductDescription: '',
    },
    planCode: '',
    planName: {
      enUsPlanName: product.coverageDescription,
      frCaPlanName: '',
    },
    planSKU: product.productSku,
    usageType: null,
    minTerm: product.termFrom?.toString(),
    maxTerm: product.termTo?.toString(),
    isBundled: false,
    isDiscounted: false,
    termMileage: product.termMileage?.toString(),
    termInput: null,
    deductible: product.deductible?.toString(),
    disappearingDeductible: product.disappearingDeductible,
    vehicleClass: product.classCode,
    financeAmountFrom: product.vehicleCostMin?.toString(),
    financeAmountTo: product.vehicleCostMax?.toString(),
    msrpTo: null,
    msrpFrom: null,
    vehicleSellingPriceFrom: null,
    vehicleSellingPriceTo: null,
    grossCapCostFrom: null,
    grossCapCostTo: null,
    dealerCost: product.dealerCost,
    regulatedRate: product.regulatedRate,
    retailRateType: product.regulatedRate,
    retailRate: product.retailCost,
    surchargeOptions: { surchargeOption: [] },
    startingInterval: product.startingInterval,
    formNumber: product.formNumber,
    formDescription: product.formDescription,
    vehicleClassing: '',
    coverageCode: product.coverageCode,
    coverageDescription: product.coverageDescription,
    coverageOptions: { coverageOption: [] }, // PowerUp Products resp has other CoverageOption structure
    minDollarAmount: null,
    maxDollarAmount: null,
    paymentType: product.paymentType,
    startingIntervalName: product.startingIntervalName,
    minOdometer: product.odometerMin,
    maxOdometer: product.odometerMax,
    programType: product.programType,
    planType: product.planName,
    childProducts: null, // ChildProducts
    taxes: null, // ? Tax
    externalProductReferenceCode: null,
    vehicleCondition: product.vehicleCondition,
    programAgentCode: product.programAgentCode,
    isPowerUpProduct: true,
  };
}
