import { inject, Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ContractSearchDetails } from '@ph-model/api/contract-search-details';
import { ContractSearchItem } from '@ph-model/api/contract-search-item';
import {
  loadContractDetailsData,
  loadContractDetailsDataFailure,
  loadContractDetailsDataSuccess,
  loadContractSearchData,
  loadContractSearchDataFailure,
  loadContractSearchDataSuccess,
  updateContractSearchCurrentPage,
} from '@ph-store/contract-search/contract-search.actions';
import { selectContractSearchParams } from '@ph-store/contract-search/contract-search.selectors';
import { ContractSearchService } from '@ph-store/contract-search/contract-search.service';

@Injectable()
export class ContractSearchEffects {
  actions$: Actions = inject(Actions);
  store: Store = inject(Store);
  contractSearchService: ContractSearchService = inject(ContractSearchService);

  getContractSearchData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadContractSearchData),
      switchMap(({ vin, firstName, lastName }: { vin: string; firstName: string; lastName: string }) =>
        this.loadContracts(1, vin, firstName, lastName)
      )
    );
  });

  updateContractSearchCurrentPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateContractSearchCurrentPage),
      concatLatestFrom(() => this.store.select(selectContractSearchParams)),
      switchMap(
        ([{ pageNumber }, { vin, firstName, lastName }]: [
          { pageNumber: number },
          { vin: string; firstName: string; lastName: string },
        ]) => this.loadContracts(pageNumber, vin, firstName, lastName)
      )
    );
  });

  getContractDetailsData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadContractDetailsData),
      switchMap((data: { contractId: string }) => {
          return this.contractSearchService.loadContractDetails(data.contractId).pipe(
            map((contractDetails: ContractSearchDetails) => loadContractDetailsDataSuccess({ contractDetails })),
            catchError((error) => {
              console.error('Contract Details response error: ', error);

              return of(loadContractDetailsDataFailure({ error }));
            })
          )
        }
      )
    );
  });

  private loadContracts(pageNumber: number, vin: string, firstName: string, lastName: string) {
    return this.contractSearchService.loadContractSearchData(pageNumber, vin, firstName, lastName).pipe(
      map(({ data, rowCount }: { data: ContractSearchItem[]; rowCount: string }) =>
        loadContractSearchDataSuccess({
          contracts: data,
          rowCount: +rowCount,
          pagesCount: Math.ceil(+rowCount / this.contractSearchService.pageSize),
        })
      ),
      catchError((error) => {
        console.error('Contract Search response error: ', error);

        return of(loadContractSearchDataFailure({ error }));
      })
    );
  }
}
