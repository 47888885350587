import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import {
  PhOverlay,
  PhOverlayConfig,
  PhOverlayPositionBuilder,
  PhOverlayRef,
  PhOverlayHorizontalPosition,
  PhOverlayVerticalPosition,
} from '../overlay';

@Injectable({
  providedIn: 'root',
})
export class PhDialog {
  constructor(private overlay: PhOverlay, private pb: PhOverlayPositionBuilder) {
  }

  open<Component>(component: ComponentType<Component>, config?: PhOverlayConfig): PhOverlayRef<Component> {
    return this.overlay.attach(component, {
      position: this.pb.position({
        horizontal: PhOverlayHorizontalPosition.center,
        vertical: PhOverlayVerticalPosition.center,
      }),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass: 'ph-dialog',
      hasBackdrop: true,
      clickableBackdrop: true,
      ...config,
    });
  }
}
