import { Injectable } from '@angular/core';

import { SupportedLanguageCodes } from '@ph-static-data/languages';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  getLanguage(): SupportedLanguageCodes {
    return localStorage.getItem('languageCode') as SupportedLanguageCodes;
  }
}
