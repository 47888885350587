import { Directive, HostListener, input, InputSignal } from '@angular/core';

import { PhOverlayRef } from '@ph-core/services/overlay';

@Directive({
  selector: '[ph-overlay-close]',
  standalone: true,
})
export class PhOverlayCloseDirective {
  value: InputSignal<unknown> = input.required({ alias: 'ph-overlay-close' });

  constructor(private ref: PhOverlayRef<unknown>) {}

  @HostListener('click') close(): void {
    this.ref.close(this.value());
  }
}
