import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import { FormService } from '@ph-core/services/form.service';
import { PhOverlayRef } from '@ph-core/services/overlay';
import { RequiredValidator, ZipCodeValidator } from '@ph-core/validations';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { Lienholder } from '@ph-model/checkout/submit_cart/response/lienholder';
import { PhOverlayCloseDirective } from '@ph-shared/directives';
import { untilDestroyed } from '@ph-shared/utils';
import { updateCheckoutForm, updateLienholderWithNewlyAdded } from '@ph-store/cart/cart.actions';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  selector: 'app-add-lienholder-popup',
  templateUrl: './add-lienholder-popup.component.html',
  styleUrls: ['./add-lienholder-popup.component.scss'],
  standalone: true,
  imports: [
    FormInputControlComponent,
    IconComponent,
    PhButtonComponent,
    PhOverlayCloseDirective,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class AddLienholderPopupComponent implements OnInit {
  lienholderForm: FormGroup;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private store: Store,
    private formService: FormService,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private ref: PhOverlayRef<AddLienholderPopupComponent>
  ) {}

  ngOnInit(): void {
    this.lienholderForm = this.fb.group({
      lienholderName: this.fb.control('', [Validators.required]),
      lienholderAddress1: this.fb.control('', [Validators.required]),
      suite: this.fb.control(''),
      lienholderPostalCode: this.fb.control('', Validators.compose([RequiredValidator, ZipCodeValidator])),
      lienholderCity: this.fb.control('', [Validators.required]),
      lienholderCountryCode: this.fb.control('', [Validators.required]),
      lienholderStateProvince: this.fb.control('', [Validators.required]),
    });

    this.lienholderForm
      .get('lienholderPostalCode')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => this._handlePostalCode()),
        this.#untilDestroyed()
      )
      .subscribe();
  }

  addLienholder(): void {
    if (this.lienholderForm.invalid) {
      return;
    }
    this.httpClient
      .post<{ newLienholder: Lienholder }>(`${environment.apiUrl}/addlienholder`, this.lienholderForm.getRawValue())
      .pipe(
        catchError((error) => throwError(() => error)),
        take(1),
        tap((data: { newLienholder: Lienholder }) => {
          this.store.dispatch(updateCheckoutForm({ updatedFields: { lienholder: data.newLienholder } }));
          this.store.dispatch(updateLienholderWithNewlyAdded({ payload: data.newLienholder }));
          this.lienholderForm.reset();
        })
      )
      .subscribe();
  }

  close(): void {
    this.ref.close();
  }

  private _handlePostalCode(): Observable<null> {
    const [postalCode, city, stateProvince, countryCode] = [
      this.lienholderForm.get('lienholderPostalCode'),
      this.lienholderForm.get('lienholderCity'),
      this.lienholderForm.get('lienholderStateProvince'),
      this.lienholderForm.get('lienholderCountryCode'),
    ];

    if (postalCode && postalCode.value.length > 4) {
      const formattedValue = this.formService.getFormattedPostalCode(postalCode.value);

      return this.httpClient
        .post<PostalResponse>(`${environment.apiUrl}/getcitystate`, { postalcode: formattedValue })
        .pipe(
          catchError((error) => throwError(() => error)),
          map((postal) => {
            if (postal.status) {
              city.markAsPristine();
              stateProvince.markAsPristine();
              countryCode.markAsPristine();

              city.setValue(postal.city);
              stateProvince.setValue(postal.stateAbbr);
              countryCode.setValue(postal.country);
            } else {
              city.setValue('');
              stateProvince.setValue('');
              countryCode.setValue('');
            }

            return null;
          })
        );
    } else {
      city.setValue('');
      stateProvince.setValue('');
      countryCode.setValue('');

      return of(null);
    }
  }
}
