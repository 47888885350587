import { createAction, props } from '@ngrx/store';

import { RecentQuotes } from '@ph-model/api/response/recent-quotes.response.model';
import { Markup, UserResponse } from '@ph-model/login';
import { OktaLoginRequest } from '@ph-model/request/okta-login.request';
import { GetUserResponse } from '@ph-model/response/getuser.response';

/**
 * User login store. UI will call API and return all information that is
 * associated to that user if authenticated.
 */
export const login = createAction(
  '[User] LOGIN',
  props<{
    username: string;
    password: string;
    admin?: boolean;
    captcha: string;
  }>()
);
export const loginSuccess = createAction('[User] LOGIN SUCCESS', props<{ response: UserResponse; admin: boolean }>());
export const loginFailure = createAction('[User] LOGIN FAILURE', props<{ error: string }>());
export const logout = createAction('[User] LOGOUT', props<{ newVersion: boolean; version: string }>()); //{ newVersion: false, version: '' }
export const logoutSuccess = createAction('[User] LOGOUT_SUCCESS');
export const getRecentQuotes = createAction('[User] GET RECENT QUOTES');
export const getRecentQuotesSuccess = createAction(
  '[User] GET RECENT QUOTES SUCCESS',
  props<{ recentQuotes: RecentQuotes[] }>()
);
export const getRecentQuotesFailure = createAction('[User] GET RECENT QUOTES FAILURE', props<{ error: string }>());
export const getQuote = createAction('[User] GET QUOTE', props<{ dealer: string; vin: string }>());
export const deleteQuote = createAction('[User] DELETE QUOTE', props<{ dealer: string; vin: string }>());
export const deleteQuoteSuccess = createAction('[User] DELETE QUOTE SUCCESS', props<{ response: RecentQuotes[] }>());
export const updatePassword = createAction(
  '[User] UPDATE PASSWORD',
  props<{ response: UserResponse; admin: boolean }>()
);
export const resetSession = createAction('[User] RESET SESSION');
export const updateVehicleInfo = createAction('[User] UPDATE VEHICLE INFO');
export const saveMarkup = createAction('[Profile] SAVE MARKUP', props<{ markup: Markup }>());
export const updatePendingContractsCount = createAction(
  '[Remit] UPDATE PENDING CONTRACTS COUNT',
  props<{ count: number }>()
);
export const emulateDealer = createAction('[Profile] EMULATE DEALER', props<{ userResponse: UserResponse }>());
export const resetDealer = createAction('[Profile] RESET DEALER');
export const getUsers = createAction('[Profile Admin] GET USERS');
export const getUsersSuccess = createAction(
  '[Profile Admin] GET USERS SUCCESS',
  props<{ userResponse: GetUserResponse }>()
);
export const getUsersFailure = createAction('[Profile Admin] GET USERS FAILURE', props<{ error: string }>());
export const updateVersion = createAction('[User] UPDATE APP VERSION', props<{ version: string }>());
export const ssoOktaLogin = createAction('[User] SSO OKTA LOGIN', props<{ oktaLoginRequest: OktaLoginRequest }>());
export const ssoOktaLoginFailure = createAction('[User] SSO OKTA LOGIN FAILURE');
export const ssoValidateOktaPasswordResetToken = createAction(
  '[User] SSO VALIDATE OKTA PASSWORD RESET TOKEN',
  props<{ token: string }>()
);
