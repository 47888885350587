import { HttpErrorResponse } from '@angular/common/http';

import { createReducer, on } from '@ngrx/store';

import { ProductImage } from '@ph-model/api/product-images.model';
import { PrismicData } from '@ph-model/api/response/prismic-response.model';

import {
  loadPrismicData,
  loadPrismicDataSuccess,
  loadPrismicDataFailure,
  getProductImagesSuccess,
  getProductImagesFailure,
} from './prismic.actions';

export const featureKey = 'prismicState';

export interface PrismicState {
  data: PrismicData;
  loading: boolean;
  error: HttpErrorResponse;
  productImages: { [key: string]: ProductImage };
}

const InitialState: PrismicState = {
  data: undefined,
  loading: false,
  error: undefined,
  productImages: {},
};

export const reducer = createReducer(
  InitialState,
  on(
    loadPrismicData,
    (state): PrismicState => ({
      ...state,
      loading: true,
      error: undefined,
    })
  ),
  on(
    loadPrismicDataSuccess,
    (state, { data }): PrismicState => ({
      ...state,
      data,
      loading: false,
      error: undefined,
    })
  ),
  on(loadPrismicDataFailure, (state, { error }): PrismicState => ({ ...state, error, loading: false })),
  on(
    getProductImagesSuccess,
    (state, { productImages }): PrismicState => ({
      ...state,
      productImages,
    })
  ),
  on(getProductImagesFailure, (state): PrismicState => ({ ...state, productImages: {} }))
);
