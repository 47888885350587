import { PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';

import { PhNotificationItem } from '@ph-shared/components';

export type PhOverlayData = Record<string, unknown> | PhNotificationItem;

export interface PhOverlayConfig {
  scrollStrategy?: ScrollStrategy;
  panelClass?: string | string[];
  position?: PositionStrategy;
  backdropClass?: string;
  hasBackdrop?: boolean;
  data?: PhOverlayData;
  reusable?: boolean;
  clickableBackdrop?: boolean;
}

export enum PhConnectedPosition {
  before = 'before',
  below = 'below',
  after = 'after',
  above = 'above',

  belowStart = 'below-start',
  belowEnd = 'below-end',

  aboveStart = 'above-start',
  aboveEnd = 'above-end',

  belowAlignRight = 'below-align-right',
  belowAlignLeft = 'below-align-left',

  aboveAlignRight = 'above-align-right',
  aboveAlignLeft = 'above-align-left',
}

export enum PhOverlayVerticalPosition {
  bottom = 'bottom',
  center = 'center',
  top = 'top',
}

export enum PhOverlayHorizontalPosition {
  end = 'end',
  center = 'center',
  start = 'start',
}

export interface PhOverlayPosition {
  horizontal?: PhOverlayHorizontalPosition;
  vertical?: PhOverlayVerticalPosition;
}
