import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductViewType } from '@ph-core/enums';

import { ConfigState, featureKey } from './config.reducer';

export const selectConfigState = createFeatureSelector<ConfigState>(featureKey);

export const selectInitialLoading = createSelector(selectConfigState, (state) => state.initialLoading);
export const selectLoading = createSelector(selectConfigState, (state) => state.loading);
export const selectOktaLoading = createSelector(selectConfigState, (state) => state.oktaLoading);
export const selectTemplateServiceLoading = createSelector(selectConfigState, (state) => state.templateServiceLoading);
export const selectSubsequentLoading = createSelector(
  selectLoading,
  selectOktaLoading,
  selectTemplateServiceLoading,
  (loading, oktaLoading, templateServiceLoading) => loading || oktaLoading || templateServiceLoading
);
export const selectClientConfig = createSelector(selectConfigState, (state) => state.clientConfig);
export const selectDealerView = createSelector(selectConfigState, (state) => state.dealerView);
export const selectDealerViewValue = createSelector(selectDealerView, (isDealer: boolean) =>
  isDealer ? ProductViewType.Dealer : ProductViewType.Customer
);
export const selectProgramId = createSelector(selectClientConfig, (config) => config.programId);
