import { createReducer, on } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { ApplicationCode } from '@ph-env/environment.model';

import { ProductViewType } from '@ph-core/enums';
import { ClientConfigResponse } from '@ph-model/api/response/client-config/client-config.response.model';

import {
  getInitialLayout,
  getInitialLayoutFailure,
  getInitialLayoutSuccess,
  resetLayout,
  resetLoading,
  resetOktaLoading,
  resetTemplateServiceLoading,
  setLoading,
  setOktaLoading,
  setTemplateServiceLoading,
  testLayout,
  toggleDealerView,
} from './config.actions';

export const featureKey = 'configState';

export interface ConfigState {
  application: ApplicationCode | undefined;
  error: string;
  success: boolean;
  clientConfig: ClientConfigResponse;
  initialClientConfig: ClientConfigResponse;
  dealerView: boolean;
  initialLoading: boolean;
  loading: boolean;
  oktaLoading: boolean;
  templateServiceLoading: boolean;
}

const InitialState: ConfigState = {
  application: undefined,
  dealerView: environment.features.defaultProductVewType === ProductViewType.Dealer,
  error: '',
  success: false,
  clientConfig: new ClientConfigResponse(),
  initialClientConfig: new ClientConfigResponse(),
  initialLoading: false,
  loading: false,
  oktaLoading: false,
  templateServiceLoading: false,
};

export const reducer = createReducer(
  InitialState,
  on(
    getInitialLayout,
    (state, { application }): ConfigState => ({
      ...state,
      application,
      initialLoading: true,
    })
  ),
  on(getInitialLayoutSuccess, (state, { clientConfigResponse }): ConfigState => {
    const updatedClientConfigResponse = {
      ...clientConfigResponse,
      css: {
        ...clientConfigResponse.css,
      },
    };

    return {
      ...state,
      initialLoading: false,
      clientConfig: updatedClientConfigResponse,
      initialClientConfig: updatedClientConfigResponse,
      success: true,
      // add more when you start calling the effects API for initial layout
    };
  }),
  on(
    getInitialLayoutFailure,
    (state, { error }): ConfigState => ({
      ...state,
      success: false,
      initialLoading: false,
      error,
    })
  ),
  on(
    testLayout,
    (state, { clientConfigResponse }): ConfigState => ({
      ...state,
      clientConfig: clientConfigResponse,
    })
  ),
  on(
    resetLayout,
    (state): ConfigState => ({
      ...state,
      clientConfig: state.initialClientConfig,
    })
  ),
  on(
    toggleDealerView,
    (state, { dealerView }): ConfigState => ({
      ...state,
      dealerView,
    })
  ),
  on(
    setLoading,
    (state): ConfigState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    resetLoading,
    (state): ConfigState => ({
      ...state,
      loading: false,
    })
  ),
  on(
    setOktaLoading,
    (state): ConfigState => ({
      ...state,
      oktaLoading: true,
    })
  ),
  on(
    resetOktaLoading,
    (state): ConfigState => ({
      ...state,
      oktaLoading: false,
    })
  ),
  on(
    setTemplateServiceLoading,
    (state): ConfigState => ({
      ...state,
      templateServiceLoading: true,
    })
  ),
  on(
    resetTemplateServiceLoading,
    (state): ConfigState => ({
      ...state,
      templateServiceLoading: false,
    })
  )
);
