import { IconName } from '@ph-shared/components';

import { PhNotificationType } from './notification.interface';

export function getNotificationLevel(type: PhNotificationType): string {
  switch (type) {
    case PhNotificationType.submit:
    default:
      return 'ph-notification-level-1';
    case PhNotificationType.open:
      return 'ph-notification-level-1';
    case PhNotificationType.info:
      return 'ph-notification-level-1';

    case PhNotificationType.inaudit:
      return 'ph-notification-level-2';

    case PhNotificationType.approved:
      return 'ph-notification-level-3';
    case PhNotificationType.credit:
      return 'ph-notification-level-3';

    case PhNotificationType.cancel:
      return 'ph-notification-level-4';
    case PhNotificationType.denied:
      return 'ph-notification-level-4';
    case PhNotificationType.noben:
      return 'ph-notification-level-4';

    case PhNotificationType.inactive:
      return 'ph-notification-level-5';
    case PhNotificationType.closed:
      return 'ph-notification-level-5';
  }
}

export function getNotificaitonIcon(type: PhNotificationType): IconName {
  const icons: IconName[] = ['circle_checkmark', 'circle_warning', 'circle_info'];

  switch (type) {
    case PhNotificationType.approved:
      return icons[0];
    case PhNotificationType.credit:
      return icons[0];
    case PhNotificationType.open:
      return icons[0];

    case PhNotificationType.inaudit:
      return icons[1];
    case PhNotificationType.denied:
      return icons[1];
    case PhNotificationType.noben:
      return icons[1];
    case PhNotificationType.cancel:
      return icons[1];

    default:
      return 'circle_info';
  }
}
