import { createAction, props } from '@ngrx/store';

import { ContractHistoryResponse, ContractType } from '@ph-model/api';
import { SearchFilter } from '@ph-model/filters/search-filter';

export const getContracts = createAction(
  '[History] GET CONTRACTS',
  props<{ firstName: string; lastName: string; vin: string; searchAllDealers: boolean }>()
);
export const getContractsSuccess = createAction(
  '[History] GET CONTRACTS SUCCESS',
  props<{ contracts: ContractHistoryResponse; filters: SearchFilter }>()
);
export const undoDeclineContract = createAction(
  '[History] UNDO DECLINE CONTRACT',
  props<{ contractType: ContractType }>()
);
export const undoDeclineContractSuccess = createAction(
  '[History] UNDO DECLINE CONTRACT SUCCESS',
  props<{ contracts: ContractType[]; filters: SearchFilter; gridContracts: ContractType[] }>()
);
export const filterHistoryGrid = createAction('[History] FILTER GRID', props<{ filters: SearchFilter }>());
export const historyFailure = createAction('[History] HISTORY FAILURE', props<{ error: string }>());
export const resetContracts = createAction('[History] RESET CONTRACTS');
