import { Directive, ElementRef, HostListener } from '@angular/core';

import { specialKeys } from '@ph-static-data/keyboard-key-codes';

@Directive({
  selector: '[appTwoDecimalPlaces]',
  standalone: true,
})
export class TwoDecimalPlacesDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: string[] = specialKeys;

  constructor(private _el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this._el.nativeElement.value;
    const position = this._el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
