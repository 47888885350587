import { createAction, props } from '@ngrx/store';

import { ContractType, RemittedContractResponse } from '@ph-model/api';
import { PendingContractResponse } from '@ph-model/api/response/pending-contract.response.model';
import { ContractFilter } from '@ph-model/filters/contract-filter.model';
import { Filter } from '@ph-model/filters/filter';

export const getPendingContracts = createAction('[Remit] GET PENDING_CONTRACTS');
export const getPendingContractsSuccess = createAction(
  '[Remit] GET PENDING_CONTRACTS SUCCESS',
  props<{ contracts: PendingContractResponse; filters: Filter[] }>()
);
export const filterPendingGrid = createAction(
  '[Remit] FILTER PENDING GRID',
  props<{ filter: Filter[]; searchTerm?: string }>()
);
export const addToPendingRemit = createAction('[Remit] ADD PENDING TO REMIT', props<{ contractType: ContractType }>());
export const deleteFromPendingRemit = createAction(
  '[Remit] DELETE FROM PENDING TO REMIT',
  props<{ econContractId: string }>()
);
export const submitAllPendingRemit = createAction('[Remit] SUBMIT ALL PENDING REMIT');
export const submitAllPendingRemitSuccess = createAction(
  '[Remit] SUBMIT ALL PENDING REMIT SUCCESS',
  props<{
    submittedRemittedRequest: ContractType[];
    contracts: PendingContractResponse;
    filters: Filter[];
  }>()
);
export const declinePendingContract = createAction(
  '[Remit] DECLINE PENDING CONTRACT',
  props<{ contractType: ContractType }>()
);
export const reinstatePendingContract = createAction(
  '[Remit] REINSTATE PENDING CONTRACT',
  props<{ contractType: ContractType }>()
);
export const addAllToPendingRemit = createAction('[Remit] ADD ALL FROM PENDING TO REMIT');
export const removeAllFromPendingRemitOnly = createAction(
  '[Remit] REMOVE ALL FROM PENDING TO REMIT (exclude Declined)'
);
export const removeAllFromDeclinedRemitOnly = createAction(
  '[Remit] REMOVE ALL FROM PENDING TO REMIT (excluded remit pending)'
);
export const remitFailure = createAction('[remit] REMIT FAILURE', props<{ error: string }>());

export const getRemittedContracts = createAction('[Remit] GET REMITTED CONTRACTS');
export const getRemittedContractsSuccess = createAction(
  '[Remit] GET INITIAL LAYOUT SUCCESS',
  props<{ contracts: RemittedContractResponse; filters: ContractFilter[] }>()
);
export const filterRemittedGrid = createAction(
  '[Remit] FILTER REMITTED GRID',
  props<{ filter: ContractFilter[]; searchTerm?: string }>()
);
export const addToPayment = createAction('[Remit] ADD TO PAYMENT');
export const deleteFromPayment = createAction('[Remit] DELETE FROM PAYMENT');
export const payAllFromPayment = createAction('[Remit] PAY ALL FROM PAYMENT');
export const payAllFromPaymentSuccess = createAction('[Remit] PAY ALL PAYMENT SUCCESS');
export const addAllToPayment = createAction('[Remit] ADD ALL TO PAY FOR REMIT');
export const removeAllFromPayment = createAction('[Remit] REMOVE ALL FROM PAYMENT');
