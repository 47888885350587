import { Directive, HostListener, input, InputSignal } from '@angular/core';
import { NgControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { isNonReg } from '@ph-core/enums';
import { Markup } from '@ph-model/login';
import { ProductItem } from '@ph-model/response';
import { recalculateCustomerCost } from '@ph-store/product/product.helpers';

@Directive({
  selector: '[appCustomerCostValidation]',
  standalone: true,
})
export class GridCostValidationDirective {
  product: InputSignal<ProductItem> = input.required();
  markup: InputSignal<Markup> = input.required();

  constructor(
    private control: NgControl,
    private translate: TranslateService
  ) {}

  @HostListener('blur', ['$event']) onBlurEvent() {
    const value = +this.control.value;
    if (value === null || value === undefined) {
      const validatedProduct = recalculateCustomerCost(this.product(), this.markup(), []);
      this.control.control.setValue(validatedProduct.customerCost);
    } else {
      if (isNonReg(this.product().retailRateType)) {
        this.product().customerCost = value;
        this.product().costPlaceholder = value;
        this.control.control.setValue(this.product().customerCost);
      }
    }
    // validate errorFlag after resetting control value
    this._validateGridCostByRetailType(+this.control.value, this.product(), this.translate);
  }

  @HostListener('input', ['$event']) onInputEvent() {
    this._validateGridCostByRetailType(+this.control.value, this.product(), this.translate);
  }

  private _validateGridCostByRetailType(value: number, product: ProductItem, translate: TranslateService) {
    product.errorFlag = false;
    product.errorMessage = '';
    if (
      (product.retailRateType === 'MAX' && value > +product.retailRate) ||
      (product.retailRateType === 'NON-REG-CAP' && value > +product.maxCost)
    ) {
      product.errorFlag = true;
      product.errorMessage = `${translate.instant('errors.costExceed')} ${
        product.retailRateType === 'MAX' ? +product.retailRate : +product.maxCost
      }`;
    }

    if (value === null || value === undefined) {
      product.errorFlag = true;
    }

    if (product.errorFlag !== true) {
      product.customerCost = value;
    }
  }
}
