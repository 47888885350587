import { FormControl } from '@angular/forms';

/*
 * Required Validator for any field except DATES
 * */
export function RequiredValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;

  if (value === null || value === '' || value === undefined || (value && value.toString().trim() === '')) {
    return {
      required: true,
      errorMessage: 'errors.required',
    };
  }

  return null;
}

/*
 * Required Validator for any field except DATES
 * */
export function RequiredDateValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  if (value === null || value === '' || value === undefined) {
    return {
      required: true,
      errorMessage: 'errors.dateFormat',
    };
  }

  return null;
}

export function ZipCodeValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value ? control.value.toUpperCase() : null;
  const ZIPCODE_REGEX =
    /(^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$)|(^\d{5}(-\d{4})?$)/;
  if (value && !ZIPCODE_REGEX.test(value)) {
    return {
      required: true,
      errorMessage: 'errors.zipCode',
    };
  }

  return null;
}

export function EmailValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value && !EMAIL_REGEX.test(value)) {
    return {
      required: true,
      errorMessage: 'errors.email',
    };
  }

  return null;
}

export function NumberValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;

  if (value && (isNaN(value) || value.toString().toLowerCase().includes('e'))) {
    return {
      required: true,
      errorMessage: 'errors.numbersOnly',
    };
  }

  return null;
}

export function IntegerValidator(control: FormControl): null | { required: boolean; errorMessage: string } {
  const value = control.value;
  const numberPattern = /^[0-9]+$/;

  if (value && !value.match(numberPattern)) {
    return {
      required: true,
      errorMessage: 'errors.numbersOnly',
    };
  }

  return null;
}

export function GreaterThanZeroNumberValidator(
  control: FormControl
): null | { required: boolean; errorMessage: string } {
  const value = control.value;

  if (value?.length && typeof +value === 'number' && value <= 0) {
    return {
      required: true,
      errorMessage: 'Value must be greater than 0',
    };
  }

  return null;
}
