import { createAction, props } from '@ngrx/store';

import { ApplicationCode } from '@ph-env/environment.model';

import { ClientConfigResponse } from '@ph-model/api/response/client-config/client-config.response.model';

/**
 * When User initially visits our page, UI will
 * get all images and css
 */
export const getInitialLayout = createAction('[Config] GET INITIAL LAYOUT', props<{ application: ApplicationCode }>());
export const getInitialLayoutSuccess = createAction(
  '[Config] GET INITIAL LAYOUT SUCCESS',
  props<{ clientConfigResponse: ClientConfigResponse }>()
);
export const getInitialLayoutFailure = createAction(
  '[Config] GET INITIAL LAYOUT FAILURE',
  props<{ error: string }>()
);
export const testLayout = createAction('[Admin] TEST LAYOUT', props<{ clientConfigResponse: ClientConfigResponse }>());
export const resetLayout = createAction('[Admin] RESET LAYOUT');
export const toggleDealerView = createAction('[Config] TOGGLE DEALER VIEW', props<{ dealerView: boolean }>());
export const setLoading = createAction('[Config] SET LOADING');
export const resetLoading = createAction('[Config] RESET LOADING');
export const setOktaLoading = createAction('[Config] SET OKTA LOADING');
export const resetOktaLoading = createAction('[Config] RESET OKTA LOADING');
export const setTemplateServiceLoading = createAction('[Config] SET TEMPLATE SERVICE LOADING');
export const resetTemplateServiceLoading = createAction('[Config] RESET TEMPLATE SERVICE LOADING');
