import { animate, state, style, transition, trigger } from '@angular/animations';

export const notificationRevealTrigger = trigger('notificationReveal', [
  state(
    '*',
    style({
      transform: 'translate(0, 0)',
      opacity: 1,
    })
  ),
  state(
    'void',
    style({
      transform: 'translate(100%, 0)',
      opacity: 0,
    })
  ),
  transition('* => void', [animate('300ms cubic-bezier(0.4, 0.0, 0.6, 1)')]),
  transition('void => *', [animate('300ms cubic-bezier(0.0, 0.0, 0.2, 1)')]),
]);
