import { createReducer, on } from '@ngrx/store';

import { ShoppingStepDefinition } from '@ph-shared/modules/shopping-stepper';

import {
  initializeSteps,
  resetShoppingProgress,
  switchShoppingPath,
  switchToStep,
  switchToStepByUrl,
  updateCurrentStep,
  updateShoppingStepsData,
  updateStepByUrl,
  nextStep,
  prevStep,
  markAllStepsAsValidAndVisited,
  markAllStepsWithNoDestination,
  resetShoppingProgressToFirstStep,
} from './shopping-stepper.actions';
import { selectCurrentShoppingSteps, selectShoppingStepIndexByUrl } from './shopping-stepper.selectors';
import { ShoppingStepsUrls } from '../models/shopping-step-urls.model';
import { ShoppingStep } from '../models/shopping-step.model';

export const featureKey = 'shoppingStepperState';

export interface ShoppingStepperState {
  currentStepIdx?: number;
  currentStep?: ShoppingStep;
  steps: ShoppingStep[];
  data: Record<string, unknown>;
}

const initialState: ShoppingStepperState = {
  currentStepIdx: 0,
  steps: [],
  currentStep: undefined,
  data: {},
};

const decorateSteps = (steps: ShoppingStepDefinition[]): ShoppingStep[] =>
  steps.map((elem: ShoppingStepDefinition) => ({
    ...elem,
    visited: false,
    valid: false,
    paths: elem.paths ? elem.paths.map((path) => ({ ...path, steps: decorateSteps(path.steps) })) : undefined,
  }));

const updateStep = (steps: ShoppingStep[], step: ShoppingStep, mask: Partial<ShoppingStep>): ShoppingStep[] => {
  return steps.map((elem: ShoppingStep) =>
    elem.url === step.url
      ? {
          ...elem,
          ...mask,
          paths: elem.paths
            ? elem.paths.map((path) => ({ ...path, steps: updateStep(path.steps as ShoppingStep[], step, mask) }))
            : undefined,
        }
      : {
          ...elem,
          paths: elem.paths
            ? elem.paths.map((path) => ({ ...path, steps: updateStep(path.steps as ShoppingStep[], step, mask) }))
            : undefined,
        }
  );
};

const invalidateStepsAfter = (
  stepsTree: ShoppingStep[],
  currentSteps: ShoppingStep[],
  index: number
): ShoppingStep[] => {
  let updatedSteps = stepsTree;
  for (let idx = index + 1; idx < currentSteps.length; idx++) {
    updatedSteps = updateStep(updatedSteps, currentSteps[idx], { valid: false });
  }

  return updatedSteps;
};

const toggleAllStepsValidity = (steps: ShoppingStep[], isValid: boolean): ShoppingStep[] => {
  const mask = { valid: isValid, visited: isValid };

  return steps.map((step: ShoppingStep) => {
    const update =
      step.paths && step.paths.length
        ? {
            paths: step.paths.map((path) => ({ ...path, steps: path.steps.map((s) => ({ ...s, ...mask })) })),
            ...mask,
          }
        : { ...mask };

    return {
      ...step,
      ...update,
    };
  });
};

const blankAllStepUrls = (steps: ShoppingStepDefinition[]): ShoppingStep[] =>
  steps.map((elem: ShoppingStepDefinition) => ({
    ...elem,
    url: ShoppingStepsUrls.Empty,
    visited: true,
    valid: true,
    paths: elem.paths ? elem.paths.map((path) => ({ ...path, steps: decorateSteps(path.steps) })) : undefined,
  }));

const updateShoppingProgress = (
  stepsTree: ShoppingStep[],
  currentSteps: ShoppingStep[],
  targetIdx: number
): ShoppingStep[] => {
  let updatedSteps = stepsTree;
  currentSteps.forEach((step, idx) => {
    if (idx < targetIdx) {
      updatedSteps = updateStep(updatedSteps, step, { valid: true, visited: true });
    } else if (idx === targetIdx) {
      updatedSteps = updateStep(updatedSteps, step, { visited: true });
    }
  });

  return updatedSteps;
};

export const reducer = createReducer(
  initialState,
  on(initializeSteps, (state, { stepDefinitions }): ShoppingStepperState => {
    if (state.steps.length) {
      return state;
    } else {
      const steps = decorateSteps(stepDefinitions);

      return {
        ...state,
        currentStepIdx: 0,
        currentStep: steps?.length && steps[0],
        steps: steps?.length ? [{ ...steps[0], visited: true }, ...steps.slice(1)] : [],
      };
    }
  }),
  on(switchShoppingPath, (state, { name }): ShoppingStepperState => {
    return { ...state, steps: updateStep(state.steps, state.currentStep, { currentPath: name }) };
  }),
  on(
    nextStep,
    (state): ShoppingStepperState => ({
      ...state,
      currentStepIdx: state.currentStepIdx + 1,
      currentStep: selectCurrentShoppingSteps({ [featureKey]: state })[state.currentStepIdx + 1],
    })
  ),
  on(
    prevStep,
    (state): ShoppingStepperState => ({
      ...state,
      currentStepIdx: state.currentStepIdx - 1,
      currentStep: selectCurrentShoppingSteps({ [featureKey]: state })[state.currentStepIdx - 1],
    })
  ),
  on(
    switchToStep,
    (state, { index, options }): ShoppingStepperState => ({
      ...state,
      currentStepIdx: index,
      currentStep: selectCurrentShoppingSteps({ [featureKey]: state })[index],
      steps: options?.updateProgress
        ? updateShoppingProgress(state.steps, selectCurrentShoppingSteps({ [featureKey]: state }), index)
        : state.steps,
    })
  ),
  on(switchToStepByUrl, (state, { url, options }): ShoppingStepperState => {
    const index = selectShoppingStepIndexByUrl(url)({ [featureKey]: state });
    if (index !== undefined) {
      return {
        ...state,
        currentStepIdx: index,
        currentStep: selectCurrentShoppingSteps({ [featureKey]: state })[index],
        steps: options?.updateProgress
          ? updateShoppingProgress(state.steps, selectCurrentShoppingSteps({ [featureKey]: state }), index)
          : state.steps,
      };
    } else {
      return state;
    }
  }),
  on(
    updateShoppingStepsData,
    (state, { data }): ShoppingStepperState => ({
      ...state,
      data: { ...state.data, ...data },
    })
  ),
  on(resetShoppingProgress, (): ShoppingStepperState => ({ ...initialState })),
  on(
    resetShoppingProgressToFirstStep,
    (state): ShoppingStepperState => ({ ...state, steps: toggleAllStepsValidity(state.steps, false) })
  ),
  on(
    updateCurrentStep,
    (state, { data }): ShoppingStepperState => ({
      ...state,
      steps: updateStep(
        data.valid === false
          ? invalidateStepsAfter(state.steps, selectCurrentShoppingSteps({ [featureKey]: state }), state.currentStepIdx)
          : state.steps,
        state.currentStep,
        { ...data }
      ),
    })
  ),
  on(updateStepByUrl, (state, { url, data }): ShoppingStepperState => {
    const index = selectShoppingStepIndexByUrl(url)({ [featureKey]: state });
    if (index !== undefined) {
      return {
        ...state,
        steps: updateStep(state.steps, selectCurrentShoppingSteps({ [featureKey]: state })[index], data),
      };
    } else {
      return state;
    }
  }),
  on(markAllStepsAsValidAndVisited, (state): ShoppingStepperState => {
    return {
      ...state,
      steps: toggleAllStepsValidity(state.steps, true),
    };
  }),
  on(markAllStepsWithNoDestination, (state): ShoppingStepperState => {
    return {
      ...state,
      steps: blankAllStepUrls(state.steps),
    };
  })
);
