import { ApplicationCode } from '@ph-env/environment.model';

import { Role } from '@ph-core/enums';
import { Markup, SiteInfo } from '@ph-model/login/index';
import {
  AllDealers,
  AvailableProduct,
  AvailableProductText,
  DealerInfo,
  Menu,
  PendingDays,
} from '@ph-model/login/index';

import { AccessScope } from './access-scopes';

export class UserResponse {
  authenticated: boolean;
  applicationId: string;
  application: ApplicationCode;
  applicationName: string;
  cmsDealerNumber: string;
  isTempPassword: string;
  compliance: boolean;
  complianceId: string;
  cancellationURL: string;
  programId: string;
  userName: string;
  fullName: string;
  emailId: string;
  languageCode: string;
  languageId: string;
  displayProblem: boolean;
  availableProductText: AvailableProductText;
  defaultGetProduct: string;
  defaultSaveProduct: string;
  name: string;
  status: boolean;
  message: string;
  token?: string;
  availableProducts: AvailableProduct[];
  dealerInfo: DealerInfo;
  siteInfo: SiteInfo;
  roles: Role[];
  roleIds: string[];
  pendingDays: PendingDays;
  pendingContracts: number;
  menus: Menu[];
  userDefaults: { markup: Markup };
  isRelatedDealer: boolean;
  aggregatorURL: string;
  aggregateService?: boolean;
  productCategory: { [key: string]: string };
  disableSaveQuote: boolean;
  relatedApplicationId: string;
  allDealers: AllDealers[];
  useDealerGroup: boolean;
  actualDealerGroup: string;
  resourceView: string;
  programDefaults: {
    markup: Markup;
    linkCard: { displayName: string; id: string }[];
    languageCode: string;
  };
  scopes: AccessScope[];

  constructor() {
    this.userDefaults = {
      markup: new Markup(),
    };
  }
}
