import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { CarfaxService } from '@ph-core/services/carfax.service';
import { CarfaxResponse } from '@ph-model/api/response/carfax-response.model';
import { DecodeVinResponse } from '@ph-model/api/response/decode-vin.response';
import { ManualResponse } from '@ph-model/api/response/manual.response';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { ProductFormResponse } from '@ph-model/api/response/product-form.response.model';
import { selectCartTaxFormValues, selectCheckoutFormValues } from '@ph-store/cart/cart.selectors';
import { selectUser } from '@ph-store/user/user.selectors';

import { ControlDateFormatter } from 'app/shared/utils/date-control-formater';

import {
  decodeVin,
  decodeVinFailure,
  decodeVinManual,
  decodeVinSuccess,
  getCustomerPostal,
  getCustomerPostalSuccess,
  getMake,
  getModel,
  getProductForm,
  getProductFormSuccess,
  getTrim,
  getVehicleDetailsFromCarfax,
  getVehicleDetailsFromCarfaxSuccess,
  getYear,
  updateFinanceForm,
  vehicleFailure,
} from './vehicle.actions';
import { selectManualDecode, selectVehicleState } from './vehicle.selectors';
import { updateCheckoutForm } from '../cart/cart.actions';

@Injectable()
export class VehicleEffects {
  getVehicleForm$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductForm),
      switchMap(() =>
        this.httpClient
          .post<ProductFormResponse>(`${environment.apiUrl}/getproductform`, { application: environment.application })
          .pipe(
            map((productFormResponse) => getProductFormSuccess({ productFormResponse })),
            catchError((error) => of(vehicleFailure({ error })))
          )
      )
    );
  });

  decodeVin$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(decodeVin),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([{ vin }, { cmsDealerNumber }]) =>
        this.httpClient
          .get<DecodeVinResponse>(
            environment.features.supportClassicVinDecodeApi
              ? `${environment.apiUrl}/vindecode`
              : `${environment.apiUrl}/vindecode/v2`,
            { params: { vin, dealer: cmsDealerNumber } }
          )
          .pipe(
            map((decodeVinResponse: DecodeVinResponse) =>
              decodeVinSuccess({ decodeVinResponse: { ...decodeVinResponse, loadQuote: false } })
            ),
            catchError((error) => of(decodeVinFailure({ error })))
          )
      )
    );
  });

  vinFailure$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(decodeVinFailure),
      concatLatestFrom(() => this.store.select(selectVehicleState)),
      switchMap(([, vehicleState]) =>
        environment.features.supportManualVinDecoding
          ? this.httpClient
              .get<ManualResponse>(`${environment.apiUrl}/manualdecode`, {
                params: {
                  programId: localStorage.getItem('programId'),
                  year: null,
                  make: null,
                  model: null,
                },
              })
              .pipe(
                catchError((error) => throwError(() => error)),
                map((manualResponse: ManualResponse) => {
                  const vinManual = new DecodeVinResponse();
                  vinManual.year = manualResponse.year;

                  return decodeVinManual({ manualResponse });
                }),
                catchError((error) => {
                  const vinError = new DecodeVinResponse();
                  vinError.vin = vehicleState.vin.vin;

                  return of(vehicleFailure({ error }));
                })
              )
          : of()
      )
    );
  });

  getYear$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getYear),
      concatLatestFrom(() => this.store.select(selectManualDecode)),
      switchMap(([{ category }]) =>
        this.httpClient
          .get<ManualResponse>(`${environment.apiUrl}/manualdecode`, {
            params: {
              programId: localStorage.getItem('programId'),
              year: null,
              make: null,
              model: null,
              ...(category && { vehicleType: category }),
            },
          })
          .pipe(
            map((response: ManualResponse) => decodeVinManual({ manualResponse: { year: response.year } })),
            catchError((error) => of(vehicleFailure({ error })))
          )
      )
    );
  });

  getMake$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMake),
      concatLatestFrom(() => this.store.select(selectManualDecode)),
      switchMap(([{ category, year }, manualResponse]) =>
        this.httpClient
          .get<ManualResponse>(`${environment.apiUrl}/manualdecode`, {
            params: {
              programId: localStorage.getItem('programId'),
              year: year,
              make: null,
              model: null,
              ...(category && { vehicleType: category }),
            },
          })
          .pipe(
            map(({ make }: ManualResponse) => decodeVinManual({ manualResponse: { ...manualResponse, make } })),
            catchError((error) => of(vehicleFailure({ error })))
          )
      )
    );
  });

  getModel$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getModel),
      concatLatestFrom(() => this.store.select(selectManualDecode)),
      switchMap(([{ category, year, make }, manualResponse]) =>
        this.httpClient
          .get<ManualResponse>(`${environment.apiUrl}/manualdecode`, {
            params: {
              programId: localStorage.getItem('programId'),
              year: year,
              make: make,
              model: null,
              ...(category && { vehicleType: category }),
            },
          })
          .pipe(
            map((response: ManualResponse) =>
              decodeVinManual({
                manualResponse: {
                  ...manualResponse,
                  model:
                    response.model?.length === 0 || (response.model?.length === 1 && response.model[0] == '')
                      ? ['OTHER']
                      : [...response.model],
                },
              })
            ),
            catchError((error) => (error.status === 330 ? EMPTY : of(vehicleFailure({ error }))))
          )
      )
    );
  });

  getTrim$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTrim),
      concatLatestFrom(() => this.store.select(selectManualDecode)),
      switchMap(([{ year, make, model, category }, manualResponse]) =>
        this.httpClient
          .get<ManualResponse>(`${environment.apiUrl}/manualdecode`, {
            params: {
              programId: localStorage.getItem('programId'),
              year: year,
              make: make,
              model: model,
              ...(category && { vehicleType: category }),
            },
          })
          .pipe(
            map(({ trim }: ManualResponse) => decodeVinManual({ manualResponse: { ...manualResponse, trim } })),
            catchError((error) => (error.status === 330 ? EMPTY : of(vehicleFailure({ error }))))
          )
      )
    );
  });

  getInServiceDate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getVehicleDetailsFromCarfax),
      switchMap(({ vin }) =>
        this.carfaxService.getCarDetails(vin).pipe(
          filter((details: CarfaxResponse) => !!details),
          map((details: CarfaxResponse) =>
            getVehicleDetailsFromCarfaxSuccess({
              inServiceDate: details.inServiceDate ? ControlDateFormatter.encode(details.inServiceDate) : null,
            })
          )
        )
      )
    );
  });

  getCustomerPostal$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCustomerPostal),
      switchMap(({ zipCode }) =>
        this.httpClient.post<PostalResponse>(`${environment.apiUrl}/getcitystate`, { postalcode: zipCode }).pipe(
          map((response: PostalResponse) => getCustomerPostalSuccess({ postal: response })),
          catchError((error) => of(vehicleFailure({ error })))
        )
      )
    );
  });

  // update finance + products total on financeAmount changes
  updateFinanceFormValues$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateFinanceForm),
      concatLatestFrom(() => [this.store.select(selectCartTaxFormValues), this.store.select(selectCheckoutFormValues)]),
      map(([{ values }, taxValues, checkoutValues]) => {
        const cartTotal = taxValues.reduce((total, item) => {
          const taxTotal = item.tax['total'] || item.tax['cost'];

          return total + taxTotal;
        }, 0);

        const grandTotal = +values.financeAmount + cartTotal;

        return updateCheckoutForm({
          updatedFields: {
            financeForm: {
              ...checkoutValues.financeForm,
              ...values,
              financeAmount: grandTotal,
            },
          },
        });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private carfaxService: CarfaxService,
    private store: Store
  ) {}
}
