import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { environment } from '@ph-env/environment';

import { authGuard } from './core/route-guards/auth.guard';
import { loginGuard } from './core/route-guards/login.guard';
import { redirectGuard } from './core/route-guards/redirect.guard';

@Component({ template: '' })
class MockRouteComponent {}

const sandboxRoute: Routes = !environment.production
  ? [
      {
        path: 'sandbox',
        loadChildren: () => import('./sandbox/sandbox-routes').then((m) => m.SANDBOX_ROUTES),
      },
    ]
  : [];

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home-routes').then((m) => m.HOME_ROUTES),
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component'),
    canActivate: [loginGuard],
  },
  {
    path: 'callback',
    loadChildren: () => import('./callback/callback-routes').then((m) => m.CALLBACK_ROUTE),
  },
  ...sandboxRoute,
  { path: 'not-found', loadComponent: () => import('./not-found/not-found.component') },
  { path: '**', canActivate: [redirectGuard], component: MockRouteComponent },
];
