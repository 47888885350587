import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-initial-loader[loading]',
  templateUrl: './initial-loader.component.html',
  styleUrls: ['./initial-loader.component.scss'],
  standalone: true,
})
export class InitialLoaderComponent {
  loading: InputSignal<boolean> = input.required();
}
