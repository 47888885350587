export class CustomerInfo {
  constructor(
    public firstName: string,
    public lastName: string,
    public middleName: string,
    public businessName: string,
    public primaryPhone: string,
    public seconderyPhone: string,
    public email: string,
    public address1: string,
    public address2: string,
    public city: string,
    public state: string,
    public zipCode: string,
    public countryCode: string
  ) {}
}
