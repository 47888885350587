import { createReducer, on } from '@ngrx/store';

import { RemitService } from '@ph-core/services/remit.service';
import { ContractType } from '@ph-model/api';
import { ContractFilter } from '@ph-model/filters/contract-filter.model';
import { Filter } from '@ph-model/filters/filter';

import {
  getPendingContracts,
  getPendingContractsSuccess,
  filterPendingGrid,
  addToPendingRemit,
  deleteFromPendingRemit,
  declinePendingContract,
  reinstatePendingContract,
  addAllToPendingRemit,
  removeAllFromPendingRemitOnly,
  removeAllFromDeclinedRemitOnly,
  submitAllPendingRemitSuccess,
  getRemittedContractsSuccess,
  filterRemittedGrid,
} from './remit.actions';

export const featureKey = 'remitState';

export interface RemitState {
  pendingContracts: ContractType[];
  pendingGridContracts: ContractType[];
  pendingToRemitContracts: ContractType[];
  pendingToDeclineContracts: ContractType[];
  pendingFilters: Filter[];
  pendingSearchTerm: string;
  submittedPendingContracts: ContractType[];
  loadingPendingContracts: boolean;

  remittedContracts: ContractType[];
  remittedGridContracts: ContractType[];
  remittedFilters: ContractFilter[];
  remittedSearchTerm: string;
}

const InitialState: RemitState = {
  pendingContracts: [],
  pendingGridContracts: [],
  pendingToRemitContracts: [],
  pendingToDeclineContracts: [],
  pendingFilters: [],
  pendingSearchTerm: '',
  submittedPendingContracts: [],
  loadingPendingContracts: false,

  remittedContracts: [],
  remittedGridContracts: [],
  remittedFilters: [],
  remittedSearchTerm: '',
};

export const reducer = createReducer(
  InitialState,
  on(
    getPendingContracts,
    (state): RemitState => ({
      ...state,
      loadingPendingContracts: true,
    })
  ),

  on(
    getPendingContractsSuccess,
    (state, { contracts, filters }): RemitState => ({
      ...state,
      pendingContracts: contracts.pendingContracts,
      pendingGridContracts: contracts.pendingContracts,
      pendingToRemitContracts: contracts.pendingToRemitContracts,
      pendingToDeclineContracts: contracts.pendingToDeclineContracts,
      pendingFilters: filters,
      loadingPendingContracts: false,
    })
  ),
  on(filterPendingGrid, (state, { searchTerm, filter }): RemitState => {
    const newSearchTerm = searchTerm !== undefined ? searchTerm : state.pendingSearchTerm;
    const contracts = RemitService.filterPendingGrid(filter, newSearchTerm, state.pendingContracts);

    return {
      ...state,
      pendingFilters: [...filter],
      pendingSearchTerm: newSearchTerm,
      pendingGridContracts: contracts,
    };
  }),

  on(
    addToPendingRemit,
    (state, { contractType }): RemitState => ({
      ...state,
      pendingGridContracts: state.pendingGridContracts.map((item: ContractType) => {
        if (item.econContractId === contractType.econContractId) {
          item.addToRemit = true;

          return item;
        } else {
          return item;
        }
      }),
      pendingToRemitContracts: [...state.pendingToRemitContracts, contractType],
    })
  ),

  on(
    deleteFromPendingRemit,
    (state, { econContractId }): RemitState => ({
      ...state,
      pendingGridContracts: state.pendingGridContracts.map((item: ContractType) => {
        if (item.econContractId === econContractId) {
          item.addToRemit = false;

          return item;
        } else {
          return item;
        }
      }),
      pendingToRemitContracts: state.pendingToRemitContracts.filter(
        (item: ContractType) => item.econContractId !== econContractId
      ),
    })
  ),

  on(
    declinePendingContract,
    (state, { contractType }): RemitState => ({
      ...state,
      pendingGridContracts: RemitService.declineAndUpdateContracts(state.pendingGridContracts, contractType),
      pendingToDeclineContracts: [...state.pendingToDeclineContracts, contractType],
    })
  ),

  on(reinstatePendingContract, (state, { contractType }): RemitState => {
    const contracts = RemitService.reinstateAndUpdateContracts(state.pendingGridContracts, contractType);

    return {
      ...state,
      pendingGridContracts: contracts,
      pendingToDeclineContracts: state.pendingToDeclineContracts.filter(
        (item: ContractType) => item.econContractId !== contractType.econContractId
      ),
      pendingToRemitContracts: contracts.filter((item) => item.addToRemit === true),
    };
  }),

  on(addAllToPendingRemit, (state): RemitState => {
    const addedContracts = state.pendingGridContracts.map((item: ContractType) => {
      if (!item.dupError && !item.decline && !item.addToRemit) {
        item.addToRemit = true;
        item.decline = false;

        return item;
      } else {
        return item;
      }
    });

    return {
      ...state,
      pendingGridContracts: addedContracts,
      pendingToRemitContracts: addedContracts.filter((item: ContractType) => {
        return item.addToRemit;
      }),
    };
  }),

  on(
    removeAllFromPendingRemitOnly,
    (state): RemitState => ({
      ...state,
      pendingGridContracts: state.pendingGridContracts.map((item: ContractType) => {
        if (item.addToRemit === true) {
          item.addToRemit = false;
        }

        return item;
      }),
      pendingToRemitContracts: [],
    })
  ),

  on(removeAllFromDeclinedRemitOnly, (state): RemitState => {
    const contracts = state.pendingGridContracts.reduce(
      (prevValue: ContractType[], elem: ContractType) => RemitService.reinstateAndUpdateContracts(prevValue, elem),
      state.pendingGridContracts
    );

    return {
      ...state,
      pendingGridContracts: contracts,
      pendingToDeclineContracts: [],
      pendingToRemitContracts: contracts.filter((elem: ContractType) => {
        return elem.addToRemit;
      }),
    };
  }),

  on(
    submitAllPendingRemitSuccess,
    (state, { contracts, filters, submittedRemittedRequest }): RemitState => ({
      ...state,
      pendingContracts: contracts.pendingContracts,
      pendingGridContracts: contracts.pendingContracts,
      pendingToRemitContracts: contracts.pendingToRemitContracts,
      pendingToDeclineContracts: contracts.pendingToDeclineContracts,
      pendingFilters: filters,
      // copies over submitted request for printing
      submittedPendingContracts: submittedRemittedRequest,
    })
  ),
  on(
    getRemittedContractsSuccess,
    (state, { contracts, filters }): RemitState => ({
      ...state,
      remittedContracts: contracts.remittedContracts,
      remittedGridContracts: contracts.remittedContracts,
      remittedFilters: filters,
    })
  ),

  on(filterRemittedGrid, (state, { searchTerm, filter }): RemitState => {
    const newSearchTerm = searchTerm !== undefined ? searchTerm : state.remittedSearchTerm;
    const contracts = RemitService.filterRemittedGrid(filter, newSearchTerm, state.remittedContracts);

    return {
      ...state,
      remittedFilters: [...filter],
      remittedSearchTerm: newSearchTerm,
      remittedGridContracts: contracts,
    };
  })
);
