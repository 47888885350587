import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from '@ph-env/environment';
import { catchError, map, of } from 'rxjs';

import { PrismicService } from '@ph-store/prismic/prismic.service';

export const redirectGuard: CanActivateFn = (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const service = inject(PrismicService);

  return service.getPrismicData().pipe(
    map((data) =>
      data?.results?.length && data.results[0].data?.redirect_urls?.length
        ? data.results[0].data.redirect_urls.map((prismicRedirectUrl) => ({
            src: prismicRedirectUrl?.slug?.length && prismicRedirectUrl.slug[0].text,
            dest: prismicRedirectUrl?.destination_uri?.url,
          }))
        : []
    ),
    catchError(() => of([])),
    map((prismicRedirectUrls) => {
      const redirectMatch = (prismicRedirectUrls || [])
        .concat(environment.redirectUrls)
        .find((elem) => elem.src === state.url);
      if (redirectMatch) {
        window.location.href = redirectMatch.dest;

        return true;
      } else {
        return router.parseUrl('/not-found');
      }
    })
  );
};
