import { FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { GreaterThanZeroNumberValidator } from './generic.validation';

export function LessOrEqual(comparedControlPath: string, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control.parent || !control.root) {
      return null;
    }

    const rootControl = control.root;

    const comparedControl = rootControl.get(comparedControlPath);
    const comparedValue = +comparedControl.value;
    const value = +control.value;

    if (isNaN(value) || !(value <= comparedValue)) {
      return { lessOrEqual: true, errorMessage };
    }
  };
}

export function GreaterOrEqual(comparedControlPath: string, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control.parent || !control.root) {
      return null;
    }

    const rootControl = control.root;

    const comparedControl = rootControl.get(comparedControlPath);
    const comparedValue = +comparedControl.value;
    const value = +control.value;

    if (isNaN(value) || !(value >= comparedValue)) {
      return { greaterOrEqual: true, errorMessage };
    }
  };
}


export function SameProvince(province: string, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    if (value?.toLowerCase() !== province?.toLowerCase()) {
      return { notSameProvince: true, errorMessage };
    }
  };
}

export function SameValue(comparedControlPath: string, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control.parent || !control.root) {
      return null;
    }

    const rootControl = control.root;
    const comparedControl = rootControl.get(comparedControlPath);

    if (control.value !== comparedControl.value) {
      return { sameValue: true, errorMessage };
    }
  };
}

export function Pattern(pattern: string | RegExp, errorMessage: string): ValidatorFn {
  const patternValidatorFn = Validators.pattern(pattern);

  return (control: FormControl) => patternValidatorFn(control) === null ? null : { pattern: true, errorMessage };
}

export function MaxValue(maxValue: number, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = +control.value;

    if (isNaN(value) || !(value <= maxValue)) {
      return { maxValue: true, errorMessage };
    }
  };
}

export function LengthValidation(length: number = 10, errorMessage: string = 'errors.phoneLength'): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.length !== length) {
      return { length: true, errorMessage };
    }
  };
}

export function MinLengthValidation(length: number = 10, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.length < length) {
      return { length: true, errorMessage };
    }
  };
}

export function MaxLengthValidation(length: number = 15, errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.length >= length) {
      return { length: true, errorMessage };
    }
  };
}

export function DateRangeValue(range: number[], errorMessage: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control.value && !control.value?.formatted) {
      return null;
    }
    const selectedDate = new Date(control.value.formatted);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - range[0]);
    minDate.setHours(0,0,0,0);

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + range[1]);
    maxDate.setHours(0,0,0,0);

    if (selectedDate < minDate || selectedDate > maxDate) {
      return { dateRangeValue: true, errorMessage };
    }
  };
}

export function GreaterThanZero(): ValidatorFn {
  return GreaterThanZeroNumberValidator;
}
