import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Env } from '@ph-core/enums';
import { PhNotification } from '@ph-core/services/notification';
import { ClientConfigResponse } from '@ph-model/api/response/client-config/client-config.response.model';
import { PhNotificationType } from '@ph-shared/components';

import { getInitialLayout, getInitialLayoutFailure, getInitialLayoutSuccess } from './config.actions';

@Injectable()
export class ConfigEffects {
  private notification: PhNotification = inject(PhNotification);
  private actions$: Actions = inject(Actions);
  private httpClient: HttpClient = inject(HttpClient);

  private getInitialLayout$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(getInitialLayout),
      mergeMap(({ application }) =>
        this.httpClient
          .get<ClientConfigResponse>(`${environment.apiUrl}/getclientinfo`, { params: { application } })
          .pipe(
            tap((clientConfigResponse: ClientConfigResponse) => {
              if (
                environment.type !== Env.PROD &&
                (!clientConfigResponse.applicationId || !clientConfigResponse.programId)
              ) {
                this.notification.display({
                  type: PhNotificationType.info,
                  value: {
                    title: 'API is not configured',
                    message: clientConfigResponse['message'] ?? 'Application ID not found',
                  },
                });
              }
            }),
            map((clientConfigResponse: ClientConfigResponse) => getInitialLayoutSuccess({ clientConfigResponse })),
            catchError((error) => of(getInitialLayoutFailure({ error })))
          )
      )
    ) }
  );
}
