import { Component } from '@angular/core';

import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-okta-auth-placeholder',
  templateUrl: './okta-auth-placeholder.component.html',
  styleUrls: ['./okta-auth-placeholder.component.scss'],
  standalone: true,
  imports: [SpinnerComponent],
})
export class OktaAuthPlaceholderComponent {}
