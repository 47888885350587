import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Injector } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';

import { PhOverlayConfig } from './index';

export class PhOverlayRef<Type> {
  private componentRef?: ComponentRef<Type>;
  private _afterClosed$: Subject<void | string> = new Subject<void | string>();
  private _injector: Injector;
  private _reusable: boolean;

  constructor(
    private ref: OverlayRef,
    private template: ComponentType<Type> | TemplatePortal<Type>
  ) {}

  init(injector: Injector, { reusable }: PhOverlayConfig): PhOverlayRef<Type> {
    this._injector = injector;
    this._reusable = reusable;

    return this;
  }

  updateData(data: { message: string }): void {
    const i = this.componentRef.instance as { update: (data: { message: string }) => void };
    i?.update(data);
  }

  attach(data?: unknown): PhOverlayRef<Type> {
    if (this.ref.hasAttached()) {
      return this;
    }

    if (this.template instanceof TemplatePortal) {
      const templateRef = this.ref.attach<Type>(this.template);
      Object.assign(templateRef.context, data);
    } else {
      const portal = new ComponentPortal(this.template, null, this._injector);
      const componentRef = this.ref.attach<Type>(portal);
      Object.assign(componentRef.instance, data);
      this.componentRef = componentRef;
    }

    return this;
  }

  toggle(data?: unknown): PhOverlayRef<Type> {
    this.ref.hasAttached() ? this.close() : this.attach(data);

    return this;
  }

  close(value = null): Observable<void | string | boolean | Record<string, unknown>> {
    if (this._reusable) {
      this.ref.detach();
    } else {
      this.ref.dispose();
    }

    this._afterClosed$.next(value);
    !this._reusable && this._afterClosed$.complete();

    return of(value);
  }

  afterClosed<T = void>(): Observable<T | void | string | boolean> {
    return this._afterClosed$.asObservable();
  }
}
