import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

import { ControlDateFormatter } from './date-control-formater';

@Injectable()
export class PhDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    const { formattedUS } = ControlDateFormatter.encode(date.toString());

    return formattedUS;
  }
}
