import { createFeatureSelector, createSelector } from '@ngrx/store';

import { environment } from '@ph-env/environment';

import { AccessScope } from '@ph-model/login/access-scopes';
import { UserResponse } from '@ph-model/login/user.response.model';
import { evaluateReadOnlyRole } from '@ph-store/user/user.helpers';

import { featureKey, UserState } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>(featureKey);
export const selectUser = createSelector(selectUserState, (state) => state.user);
export const selectUsers = createSelector(selectUserState, (state) => state.users);
export const selectUserAccessScopes = createSelector(selectUserState, (state) => state.user?.scopes || []);
export const selectUserHasAccessScope = (scope: AccessScope) =>
  createSelector(selectUserAccessScopes, (scopes) => !!(scopes && scopes.includes(scope)));
export const selectCanEmulateDealer = createSelector(
  selectUserHasAccessScope('DEALER_EMULATE'),
  selectUserHasAccessScope('DEALER_EMULATE_ALL'),
  (emulate, emulateAll) => emulate || emulateAll
);
export const selectRoles = createSelector(selectUserState, (state) => state.user.roles);
export const selectMenus = createSelector(selectUserState, (state) => state.user.menus);
export const selectMarkup = createSelector(selectUserState, (state) => state.markup);
export const selectEmulatedDealer = createSelector(selectUserState, (state) => state.emulatedDealer);
export const selectPendingContracts = createSelector(selectUserState, (state) => state.pendingContractsCount);
export const selectRecentQuotes = createSelector(selectUserState, (state) => state.recentQuotes);

export const selectIsShoppingFlowReadOnly = createSelector(selectUser, (user: UserResponse) =>
  evaluateReadOnlyRole(environment.shoppingFlowReadOnlyRoles, user?.roles)
);
export const selectCancellationURL = createSelector(selectUserState, (state) => state.user.cancellationURL);
export const selectCmsDealerNumber = createSelector(selectUserState, (state) => state.user.cmsDealerNumber);
