export interface IComplexDate {
  date: {
    year: number;
    month: number;
    day: number;
  };

  jsdate: string;
  formatted: string;
  formattedUS: string;
  epoc: number;
}

export class ControlDateFormatter {
  static encode(value: string): IComplexDate {
    if (!value) {
      return null;
    }

    const date = new Date(value);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formatted = [
      year,
      ('00' + month).slice(-2),
      ('00' + day).slice(-2),
    ].join('/');
    const formattedUS = [
      ('00' + month).slice(-2),
      ('00' + day).slice(-2),
      year,
    ].join('/');

    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      },
      jsdate: date.toISOString(),
      formatted,
      formattedUS,
      epoc: Math.round(date.getTime() / 1000),
    };
  }

  decode(dateObj: IComplexDate): string {
    return dateObj?.formatted || null;
  }
}
