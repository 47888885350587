import { Component, HostBinding, input, InputSignal } from '@angular/core';

@Component({
  template: '{{ message() }}',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
})
export class PhTooltipComponent {
  @HostBinding('class') ngClass: string = 'ph-dialog-card';

  message: InputSignal<string> = input.required();
}
