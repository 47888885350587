import { createReducer, on } from '@ngrx/store';

import { environment } from '@ph-env/environment';

import { FinanceType, Options, ShoppingFlowType, VehicleConditionType, VehicleType } from '@ph-core/enums';
import { DecodeVinResponse } from '@ph-model/api/response/decode-vin.response';
import { ManualResponse } from '@ph-model/api/response/manual.response';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { ProductFormResponse } from '@ph-model/api/response/product-form.response.model';
import { FinanceForm } from '@ph-model/form/finance-form';
import { IComplexDate } from '@ph-shared/utils';

import {
  clearCustomerPostal,
  clearSurcharges,
  decodeVinFailure,
  decodeVinManual,
  decodeVinSuccess,
  getCustomerPostalSuccess,
  getProductFormSuccess,
  getVehicleDetailsFromCarfaxSuccess,
  loadVehicleQuote,
  resetProductForm,
  setCreditStatus,
  setSaveCredit,
  setSurcharges,
  setVehicleCondition,
  showInserviceDateAndOdo,
  toggleChargerForm,
  toggleVehicleForm,
  updateBorrowInfoForm,
  updateChargerForm,
  updateDOTForm,
  updateFinanceForm,
  updateProductForm,
  vehicleFailure,
} from './vehicle.actions';

export const featureKey = 'vehicleState';

export interface VehicleState {
  productForm: ProductFormResponse;
  selectedSurcharges: { name: string; selected: boolean }[];
  values: VehicleValues;
  vin: DecodeVinResponse;
  postal: PostalResponse;
  error: string;
  status: boolean;
  financeForm: FinanceForm;
  borrowInfoForm: BorrowInfoForm;
  decodeVinTries: number;
  manualDecode: ManualResponse;
  disableInServiceDate: boolean;
  chargerForm: ChargerForm;
  dotInfo: {
    dotForm: DOTForm[];
  };
  chargerFormEnabled: boolean;
  vehicleFormEnabled: boolean;
}

export interface DOTForm {
  rimSize?: string;
  quantity?: number;
  dot?: string[];
}

export interface VehicleValues {
  vin: string;
  hin?: string;
  pid?: string;
  tin?: string;
  vehicleType: VehicleType;
  year: string;
  make: string;
  model: string;
  trim: string;
  other: string;
  odometer: string;
  isAfterSale: Options;
  financeType: FinanceType;
  vehicleCondition: VehicleConditionType;
  surcharges: { name: string; selected?: boolean }[];
  msrp: string;
  grossCapCost: string;
  financeAmount: number;
  loanTerm: number;
  apr: number;
  purchasePrice: string;
  amortizationPeriod: number;
  residualValue: string;
  coBorrower: Options;
  province: string;
  provinceCoBorrower: string;
  DOBBorrower: IComplexDate;
  DOBCoBorrower: IComplexDate;
  flowType: ShoppingFlowType;
  addInsurance: Options;
  customerTaxExempt: Options;
  negativeEquity: Options;
  registrationNumber: string;
  saleDate: IComplexDate;
  inServiceDate: IComplexDate;
  purchaseDate: IComplexDate;
  monthlyPayment?: string;
  isCreditAvailable?: boolean;
  isValidSaveCredit?: boolean;
  isOldVehicleCondition?: boolean;
}

export interface ChargerForm {
  serialNumber: string;
  chargerYear: string;
  chargerModel: string;
  chargerPurchaseDate: IComplexDate;
  chargerPurchasePrice: string;
}

const initialChargerForm: ChargerForm = {
  serialNumber: '',
  chargerYear: '',
  chargerModel: '',
  chargerPurchaseDate: null,
  chargerPurchasePrice: '',
};

const initialDOTForm: DOTForm = {
  rimSize: '',
  quantity: null,
  dot: [],
};

export interface BorrowInfoForm {
  DOBBorrower: Date | IComplexDate;
  DOBCoBorrower: Date | IComplexDate;
  coBorrower: Options;
  provinceBorrower: string;
  provinceCoBorrower: string;
}

const InitialFinanceFormState = {
  financeType: null as FinanceType,
  financeAmount: null,
  apr: null,
  loanTerm: null,
  amortizationPeriod: null,
  monthlyPayment: null,
  residualValue: null,
};

const InitialBorrowInfoFormState: BorrowInfoForm = {
  DOBBorrower: null,
  DOBCoBorrower: null,
  coBorrower: Options.NO,
  provinceBorrower: null,
  provinceCoBorrower: null,
};

const InitialState: VehicleState = {
  productForm: new ProductFormResponse(),
  selectedSurcharges: [],
  values: {} as VehicleValues,
  postal: new PostalResponse(),
  vin: new DecodeVinResponse(),
  error: '',
  status: false,
  financeForm: { ...InitialFinanceFormState },
  borrowInfoForm: { ...InitialBorrowInfoFormState },
  decodeVinTries: 0,
  manualDecode: new ManualResponse(),
  disableInServiceDate: false,
  chargerForm: initialChargerForm,
  dotInfo: {
    dotForm: [initialDOTForm],
  },
  chargerFormEnabled: false,
  vehicleFormEnabled: true,
};

export const reducer = createReducer<VehicleState>(
  InitialState,
  on(
    getProductFormSuccess,
    (state, { productFormResponse }): VehicleState => ({
      ...state,
      productForm: { ...state.productForm, ...productFormResponse },
    })
  ),

  on(
    updateProductForm,
    (state, { values }): VehicleState => ({
      ...state,
      values: { ...state.values, ...values },
    })
  ),

  on(
    updateChargerForm,
    (state, { values }): VehicleState => ({ ...state, chargerForm: { ...state.chargerForm, ...values } })
  ),
  on(updateDOTForm, (state, { values }): VehicleState => ({ ...state, dotInfo: { dotForm: [...values] } })),
  on(
    updateBorrowInfoForm,
    (state, { DOBBorrower, DOBCoBorrower, coBorrower, provinceBorrower, provinceCoBorrower }): VehicleState => ({
      ...state,
      borrowInfoForm: {
        ...state.borrowInfoForm,
        DOBBorrower,
        DOBCoBorrower,
        coBorrower,
        provinceBorrower,
        provinceCoBorrower,
      },
    })
  ),

  on(toggleChargerForm, (state, { value }): VehicleState => ({ ...state, chargerFormEnabled: value })),

  on(toggleVehicleForm, (state, { value }): VehicleState => ({ ...state, vehicleFormEnabled: value })),

  on(
    updateFinanceForm,
    (state, { values }): VehicleState => ({
      ...state,
      financeForm: { ...state.financeForm, ...values },
    })
  ),

  on(
    getCustomerPostalSuccess,
    (state, { postal }): VehicleState => ({
      ...state,
      postal,
    })
  ),

  on(
    decodeVinSuccess,
    (state, { decodeVinResponse }): VehicleState => ({
      ...state,
      vin: decodeVinResponse,
      decodeVinTries: 0,
      error: '',
    })
  ),

  on(
    decodeVinFailure,
    (state): VehicleState => ({
      ...state,
      decodeVinTries: state.decodeVinTries + 1,
    })
  ),

  on(
    clearCustomerPostal,
    (state): VehicleState => ({
      ...state,
      postal: new PostalResponse(),
    })
  ),

  on(
    decodeVinManual,
    (state, { manualResponse }): VehicleState => ({
      ...state,
      manualDecode: manualResponse,
      error: '',
    })
  ),

  on(
    showInserviceDateAndOdo,
    (state): VehicleState => ({
      ...state,
      vin: { ...state.vin, manualDecode: true },
      error: '',
    })
  ),
  on(loadVehicleQuote, (state, { loadQuote }) => {
    loadQuote.VehicleState.vin.loadQuote = true;

    const useQuoteOnlyEndpoint: boolean = environment.features.useQuoteOnlyEndpoint;
    let chargerForm;
    let dotInfo: { dotForm: DOTForm[] };
    if (useQuoteOnlyEndpoint && loadQuote.VehicleState.chargerForm) {
      chargerForm = {
        serialNumber: loadQuote.VehicleState.chargerForm.serialNumber,
        chargerModel: loadQuote.VehicleState.chargerForm.chargerModel,
        chargerYear: loadQuote.VehicleState.chargerForm.chargerYear,
        chargerPurchaseDate: loadQuote.VehicleState.chargerForm.chargerPurchaseDate,
        chargerPurchasePrice: loadQuote.VehicleState.chargerForm.chargerPurchasePrice,
      };
    } else {
      chargerForm = initialChargerForm;
    }
    if (useQuoteOnlyEndpoint && loadQuote.VehicleState?.dotInfo?.dotForm?.length) {
      dotInfo = { dotForm: [...loadQuote.VehicleState.dotInfo.dotForm] };
    } else if (useQuoteOnlyEndpoint && loadQuote.VehicleState['dotForm']) {
      dotInfo = {
        dotForm: [
          {
            rimSize: loadQuote.VehicleState['dotForm'].rimSize,
            quantity: loadQuote.VehicleState['dotForm'].quantity,
            dot: loadQuote.VehicleState['dotForm'].dot,
          },
          initialDOTForm,
        ],
      };
    } else if (useQuoteOnlyEndpoint && loadQuote.VehicleState['dotForm1']) {
      dotInfo = {
        dotForm: [
          {
            rimSize: loadQuote.VehicleState['dotForm1'].rimSize,
            quantity: loadQuote.VehicleState['dotForm1'].quantity,
            dot: loadQuote.VehicleState['dotForm1'].dot,
          },
        ],
      };
      if (loadQuote.VehicleState['dotForm2']) {
        dotInfo.dotForm.push({
          rimSize: loadQuote.VehicleState['dotForm2'].rimSize,
          quantity: loadQuote.VehicleState['dotForm2'].quantity,
          dot: loadQuote.VehicleState['dotForm2'].dot,
        });
      }
    } else {
      dotInfo = { dotForm: [initialDOTForm] };
    }

    const borrowInfoForm: BorrowInfoForm = loadQuote.VehicleState.borrowInfoForm
      ? {
          DOBBorrower: loadQuote.VehicleState.borrowInfoForm.DOBBorrower,
          DOBCoBorrower: loadQuote.VehicleState.borrowInfoForm.DOBCoBorrower,
          coBorrower: loadQuote.VehicleState.borrowInfoForm.coBorrower,
          provinceBorrower: loadQuote.VehicleState.borrowInfoForm.provinceBorrower,
          provinceCoBorrower: loadQuote.VehicleState.borrowInfoForm.provinceCoBorrower,
        }
      : InitialBorrowInfoFormState;

    const { financeType, financeAmount, apr, loanTerm, monthlyPayment, amortizationPeriod, residualValue } =
      loadQuote.VehicleState.values;

    const financeForm: FinanceForm = {
      financeType,
      financeAmount,
      apr,
      loanTerm,
      monthlyPayment,
      amortizationPeriod,
      residualValue,
    };

    return {
      ...state,
      productForm: loadQuote.VehicleState.productForm,
      borrowInfoForm,
      values: loadQuote.VehicleState.values,
      status: loadQuote.VehicleState.status,
      vin: loadQuote.VehicleState.vin,
      postal: loadQuote.VehicleState.postal ? loadQuote.VehicleState.postal : new PostalResponse(),
      chargerForm,
      dotInfo,
      vehicleFormEnabled: loadQuote.VehicleState.vehicleFormEnabled,
      financeForm,
    };
  }),

  on(vehicleFailure, (state, { error }): VehicleState => {
    const vinReset = new DecodeVinResponse();
    vinReset.vin = state.vin.vin;

    return {
      ...state,
      error,
      vin: vinReset,
      decodeVinTries: state.decodeVinTries + 1,
    };
  }),

  on(
    resetProductForm,
    (state): VehicleState => ({
      ...state,
      values: {} as VehicleValues,
      decodeVinTries: 0,
      vin: new DecodeVinResponse(),
      postal: new PostalResponse(),
      error: '',
      status: false,
      selectedSurcharges: [],
      manualDecode: new ManualResponse(),
      disableInServiceDate: false,
      financeForm: { ...InitialFinanceFormState },
      borrowInfoForm: { ...InitialBorrowInfoFormState },
      chargerForm: initialChargerForm,
      dotInfo: {
        dotForm: [initialDOTForm],
      },
    })
  ),

  on(
    setVehicleCondition,
    (state, { isOldVehicleCondition }): VehicleState => ({
      ...state,
      values: {
        ...state.values,
        isOldVehicleCondition,
      },
    })
  ),

  on(
    setCreditStatus,
    (state, { isCreditAvailable }): VehicleState => ({
      ...state,
      values: {
        ...state.values,
        isCreditAvailable,
      },
    })
  ),

  on(
    setSaveCredit,
    (state, { isValidSaveCredit }): VehicleState => ({
      ...state,
      values: {
        ...state.values,
        isValidSaveCredit,
      },
    })
  ),

  on(
    setSurcharges,
    (state, { surcharges }): VehicleState => ({
      ...state,
      values: {
        ...state.values,
        surcharges,
      },
      selectedSurcharges: surcharges,
    })
  ),

  on(clearSurcharges, (state) => ({
    ...state,
    values: {
      ...state.values,
      surcharges:
        state.values.surcharges &&
        Array.isArray(state.values.surcharges) &&
        state.values.surcharges.map((s) => ({ ...s, selected: false })),
    },
    selectedSurcharges: state.selectedSurcharges && state.selectedSurcharges.map((s) => ({ ...s, selected: false })),
  })),

  on(
    getVehicleDetailsFromCarfaxSuccess,
    (state, { inServiceDate }): VehicleState => ({
      ...state,
      values: {
        ...state.values,
        inServiceDate,
      },
      disableInServiceDate: !!inServiceDate,
    })
  )
);
