<header ph-dialog-header>
  <h2 class="title" ph-notification-title>
    {{ 'shoppingPage.products.labels.customerAgreementLanguage' | translate }}
  </h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" />
</header>

<ph-select-dropdown [formControl]="customerAgreementLanguage" small boldLabel>
  @for (option of customerAgreementLanguageOptions; track option) {
    <ph-select-dropdown-option [value]="option.value">{{ option.label }}</ph-select-dropdown-option>
  }
</ph-select-dropdown>

<div class="footer" ph-dialog-actions>
  <button ui-ph-button size="small" state="secondary" (click)="close()">
    {{ 'contactPrintLanguageSelectionPopup.cancel' | translate }}
  </button>
  <button ui-ph-button size="small" (click)="confirmLang()">
    {{ 'contactPrintLanguageSelectionPopup.select' | translate }}
  </button>
</div>
