import { FinanceType, Vendor } from '@ph-core/enums';
import { Country } from '@ph-model/country-code';
import { DistanceType } from '@ph-model/distance-type';
import { ShoppingStepsUrls } from '@ph-shared/modules/shopping-stepper';
import { base } from '@ph-env/environment.base';
import { Environment } from '@ph-env/environment.model';
import { cancellationUrl } from '@ph-env/modifiers/cancellation-url';
import { enabledSSO } from '@ph-env/modifiers/enabled-sso';
import { SupportedLanguageCodes } from '@ph-static-data/languages';

export const environment: Omit<Environment, 'apiUrl'> = {
  ...base,
  application: 'caregapacuracom',
  vendorName: Vendor.Honda,
  prismicUid: 'acura',
  prismicTypeId: 'my.gm.uid',
  countryCode: Country.USA,
  languageCode: SupportedLanguageCodes.English,
  distanceType: DistanceType.MILE,
  showTooltipsForInServiceDate: true,
  invoiceBaseUrl: 'https://qasgx-eas.safe-guardproducts.com/sgacaui/#/auth',
  okta: {
    SSOCredentials: {
      issuer: 'https://safeguard-dev.oktapreview.com/oauth2/aus2o902flb3fHbRi1d7',
      clientId: '0oa4wphh6lEzZDuUM1d7',
      idps: [
        {
          idpId: '0oa3n7tcfozQVowR51d7',
          buttonLabelTranslationKey: 'loginPage.loginForm.loginUsingOkta',
        },
      ],
    },
  },
  ...cancellationUrl('https://qa-cancellationui.safe-guardproducts.com/cancellation-portal/#/acuracareus'),
  ...enabledSSO,
  features: {
    ...base.features,
    contractHistory: { displayUserName: true },
    customerEmailIsRequired: false,
    defaultTransactionType: FinanceType.Finance,
    isTokenRequiredForReports: true,
    isNewTokenAfterEmulation: true,
    logoutMode: 'soft',
    showAnnouncementPopup: true,
    showCartSummary: false,
    showOktaLoginButton: false,
    showSaveQuoteOnCartSummaryStep: true,
    showSaveQuoteOnCreateContractStep: true,
    supportCreditInsuranceFlow: false,
    supportCustomerFormAPR: true,
    supportDealerStatementsUrlOverride: true,
    supportDOB: false,
    supportGrossCapCost: false,
    supportProvidedLienholder: true,
    supportProvince: false,
    transactionTypes: [FinanceType.Cash, FinanceType.Finance, FinanceType.Balloon],
    useNewRatesApi: true,
    useQuoteOnlyEndpoint: true,
  },
  datepickerDateRanges: {
    ...base.datepickerDateRanges,
    saleDate: [90, 15],
  },
  shoppingFlow: {
    steps: [
      {
        title: 'shoppingStepper.steps.dealInfo.title',
        url: ShoppingStepsUrls.DealInformation,
      },
      {
        title: 'shoppingStepper.steps.productSelection.title',
        url: ShoppingStepsUrls.AncillaryProductSelection,
      },
      {
        title: 'shoppingStepper.steps.cartSummary.title',
        url: ShoppingStepsUrls.CartSummary,
      },
      {
        title: 'shoppingStepper.steps.customerInfo.title',
        url: ShoppingStepsUrls.CustomerInformation,
      },
      {
        title: 'shoppingStepper.steps.createContract.title',
        url: ShoppingStepsUrls.CreateContract,
      },
    ],
  },
};
