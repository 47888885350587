import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phAutocompleteFilter',
  standalone: true,
})
export class PhAutocompleteFilterPipe implements PipeTransform {
  transform<T>(items: T[], searchTerm: string, labelKeys?: string | string[]): T[] {
    if (!items || !searchTerm) {
      return items;
    }

    return items.filter((item: T) => {
      if (!labelKeys) {
        return item['name'].toLowerCase().includes(searchTerm.toLowerCase());
      }

      if (typeof labelKeys === 'string') {
        return item[labelKeys].toLowerCase().includes(searchTerm.toLowerCase());
      }

      return labelKeys.some((label) => {
        return item[label].toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  }
}
