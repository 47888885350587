import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  InputSignal,
  Signal,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { getStatusChipIcon, getStatusChipLabel, getStatusChipLevel } from './status-chip.helpers';
import { PhStatusChipType } from './status-chip.interface';
import { IconComponent } from '../icon/icon-component';
import { IconName } from '../icon/icons';

@Component({
  selector: 'ph-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class StatusChipComponent {
  @HostBinding('class') get colorClass(): { [key: string]: boolean } {
    return { [getStatusChipLevel(this.type())]: true };
  }

  type: InputSignal<PhStatusChipType> = input.required();
  label: InputSignal<string> = input.required({
    transform: (val: string) => val || this.translate.instant(getStatusChipLabel(this.type())),
  });

  icon: Signal<IconName> = computed(() => getStatusChipIcon(this.type()));

  private translate: TranslateService = inject(TranslateService);
}
