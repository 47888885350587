import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { NgxCaptchaModule } from 'ngx-captcha';
import { throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { PhOverlayRef } from '@ph-core/services/overlay';
import { EmailValidator, NumberValidator, RequiredValidator } from '@ph-core/validations';
import { DealerInfoResponse } from '@ph-model/api/response/dealer-info.response.model';
import { RequestAccountRequest } from '@ph-model/request/request-account.request';
import { PhOverlayCloseDirective } from '@ph-shared/directives';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  templateUrl: './request-account-popup.component.html',
  styleUrls: ['./request-account-popup.component.scss'],
  standalone: true,
  imports: [
    FormInputControlComponent,
    IconComponent,
    NgxCaptchaModule,
    PhButtonComponent,
    PhOverlayCloseDirective,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class RequestAccountPopupComponent implements OnInit {
  requestForm: FormGroup;
  emailSuccess: boolean = false;
  langValue: string = 'en-CA';

  isSearching: boolean = false;
  supportCaptcha: boolean = environment.features.supportCaptcha;
  recaptchaSiteKey: string = environment.recaptchaSiteKey;

  private _dealer: DealerInfoResponse;

  constructor(
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private ref: PhOverlayRef<RequestAccountPopupComponent>
  ) {}

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      firstName: this.fb.control(null, [RequiredValidator]),
      lastName: this.fb.control(null, [RequiredValidator]),
      title: this.fb.control(null, [RequiredValidator]),
      phone: this.fb.control(null, [RequiredValidator, NumberValidator]),
      email: this.fb.control(null, [RequiredValidator, EmailValidator]),
      dealer: this.fb.control(null, [RequiredValidator]),
      dealerInfo: this.fb.group({
        dealerName: [{ value: '', disabled: true }, [RequiredValidator]],
        dealerAddress1: { value: '', disabled: true },
        dealerZipCode: { value: '', disabled: true },
        dealerCity: { value: '', disabled: true },
        dealerState: { value: '', disabled: true },
      }),
      captcha: this.fb.control(null, this.supportCaptcha ? [RequiredValidator] : []),
    });
  }

  close(): void {
    this.ref.close();
  }

  submitRequestAccount(): void {
    if (this.requestForm.invalid) {
      return;
    }

    const { email, firstName, lastName, title, phone, dealerInfo, captcha } = this.requestForm.value;

    const request: RequestAccountRequest = {
      application: environment.application,
      email,
      firstName,
      lastName,
      title,
      phone,
      dealer: this._dealer ? { ...this._dealer } : dealerInfo,
      captcha,
    };

    this.httpClient
      .post<{ status: boolean }>(`${environment.apiUrl}/requestaccount`, request)
      .pipe(
        catchError((error) => throwError(() => error)),
        filter((data) => data && data.status),
        tap(() => (this.emailSuccess = true))
      )
      .subscribe();
  }

  verifyDealer(): void {
    this.requestForm.get('dealerInfo').reset();
    this.isSearching = true;
    this.httpClient
      .post<DealerInfoResponse>(`${environment.apiUrl}/getdealer`, {
        application: environment.application,
        cmsDealerNumber: this.requestForm.get('dealer').value,
      })
      .pipe(
        catchError((err) => {
          this.isSearching = false;

          return throwError(() => err);
        }),
        tap((data: DealerInfoResponse) => {
          this.isSearching = false;
          if (data) {
            this._dealer = data;
            this.requestForm.get('dealerInfo').patchValue(data);
            this.requestForm.get('dealer').disable();
          } else {
            this._dealer = null;
          }
        })
      )
      .subscribe();
  }

  resetDealer(): void {
    this.requestForm.get('dealer').enable();
    this.requestForm.get('dealer').reset();
    this.requestForm.get('dealerInfo').reset();
    this._dealer = null;
  }
}
