import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import * as prismic from '@prismicio/client';
import { Client, PrismicDocument, Query } from '@prismicio/client';
import { from, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ProductImage } from '@ph-model/api/product-images.model';
import { PrismicDataResponse, PrismicProductImageData } from '@ph-model/api/response/prismic-response.model';
import { ProductItem } from '@ph-model/response';
import { selectBrandProductImages, selectProductImages } from '@ph-store/prismic/prismic.selectors';

@Injectable({ providedIn: 'root' })
export class PrismicService {
  private _apiEndpoint: string = 'https://phoenix-ui.cdn.prismic.io/api/v2';
  private _accessToken: string = environment.prismicAccessToken;
  private _client: Client = prismic.createClient(this._apiEndpoint, { accessToken: this._accessToken });
  private readonly _uid: string = environment.prismicUid;
  private readonly _prismicTypeId: string = environment.prismicTypeId;
  private readonly _prismicProductImagesId: string = environment.prismicProductImagesId;
  private readonly _productImagePlaceholderKey: string = 'NO_IMAGE_PLACEHOLDER';

  constructor(private store: Store) {}

  getPrismicData(): Observable<PrismicDataResponse> {
    return from(
      this._client.get({
        filters: [prismic.filter.at(this._prismicTypeId, this._uid)],
      })
    ).pipe(map((response: Query) => response as unknown as PrismicDataResponse));
  }

  getPrismicProductImages(): Observable<{ [key: string]: ProductImage }> {
    return from(this._client.getSingle(this._prismicProductImagesId)).pipe(
      map((response: PrismicDocument) => this.remapProductImages(response.data.products))
    );
  }

  getImage(product: ProductItem): Observable<string> {
    return this.store.select(selectProductImages).pipe(
      withLatestFrom(this.store.select(selectBrandProductImages)),
      map(
        ([images, brandImages]: [{ [key: string]: ProductImage }, { [key: string]: ProductImage }]) =>
          brandImages[product.productType]?.url ||
          brandImages[product.productCode]?.url ||
          images[product.productType]?.url ||
          images[product.productCode]?.url ||
          images[this._productImagePlaceholderKey]?.url ||
          'assets/no-product-placeholder.jpg'
      )
    );
  }

  remapProductImages(products: PrismicProductImageData[]): { [key: string]: ProductImage } {
    return products?.reduce((acc, product) => {
      if (!product?.product_code[0]?.text) {
        return;
      }

      return {
        ...acc,
        [product.product_code[0].text]: {
          url: product.product_image.url,
          alt: product.product_image.alt || '',
          description: product.product_description[0]?.text || '',
          type: product.product_code[0].text,
        },
      };
    }, {}) ?? {};
  }
}
