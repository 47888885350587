<!--COPIED FROM EASE QUOTE PRINT PAGE-->
<div id="page" size="A4">
  <div id="test">
    <div class="invoice-box">
      <img id="logo" [src]="navbarLogo()" style="width: 175px" alt="Safe-guard-products" />
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td id="dportal"></td>
        </tr>
        <tr class="top">
          <td colspan="3">
            <table>
              <tr>
                <td class="quote">
                  <h1>{{ 'shoppingPage.products.labels.quote' | translate }}</h1>
                </td>
                <td>
                  <b>{{ 'shoppingPage.products.labels.printed' | translate }} : {{ date | date: 'shortDate' }}</b>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="3">
            @if ({ vehicle: vehicle$ | async }; as obs) {
              <table>
                <th>
                  @if (isChargerFlow) {
                    Charger Information
                  } @else {
                    {{ 'shoppingPage.products.labels.vehicleInformation' | translate }}
                  }
                </th>
                <th style="text-align: right">{{ 'shoppingPage.products.labels.dealerInformation' | translate }}</th>
                <tr>
                  <td>
                    {{ vinLabel }}: {{ obs.vehicle.vin }} <br />
                    {{ 'shoppingPage.products.labels.make' | translate }}: {{ obs.vehicle.make }} <br />
                    {{ 'shoppingPage.products.labels.model' | translate }}: {{ obs.vehicle.model }} <br />
                    {{ 'shoppingPage.products.labels.year' | translate }}: {{ obs.vehicle.year }} <br />
                  </td>
                  <td>
                    {{ user().fullName }} <br />
                    {{ user().name }} <br />
                    {{ user().dealerInfo?.dealerAddress1 }} <br />
                    {{ user().dealerInfo?.dealerCity }}
                    , {{ user().dealerInfo?.dealerState }} {{ user().dealerInfo?.dealerZipCode }} <br />
                    {{ user().dealerInfo?.dealerPhone }}
                  </td>
                </tr>
              </table>
            }
          </td>
        </tr>
      </table>
      <table id="basicTable">
        <tbody>
          @if (creditIns()?.insResponse?.financeForm) {
            <tr class="heading">
              <td style="width: 35%">Product</td>
              <td style="text-align: left">Description</td>
              <td style="width: 20%">Customer Price</td>
            </tr>
          }
          @for (item of cart(); track item; let i = $index) {
            @if (i !== 0 && (i === 8 || (i - 8) % 14 === 0)) {
              <tr class="item" style="page-break-after: always"></tr>
            }
            <tr class="item">
              @if (this.language === 'en-CA') {
                <td>
                  {{ item.product.productDescription.enUsProductDescription }}
                </td>
              }
              @if (this.language === 'fr-CA') {
                <td>
                  {{ item.product.productDescription.frCaProductDescription }}
                </td>
              }
              @if (this.language === 'en-US') {
                <td>
                  {{ item.product.productDescription.enUsProductDescription }}
                </td>
              }

              <td style="text-align: left">
                @if (this.language === 'en-CA') {
                  <span>{{ item.product.planName.enUsPlanName }}</span>
                } @else {
                  <span>{{ item.product.planName.frCaPlanName }}</span>
                }
                - {{ item.product.customerTerm }} MO/${{ item.product.deductible }},
                @if (showTermInQuotePdf) {
                  <span
                    >{{ 'shoppingPage.products.labels.termMiles' | translate }} - {{ item.product.termMileage }}</span
                  >
                }
              </td>
              <td style="text-align: center">${{ item.product.customerCost }}</td>
            </tr>
          }
          <tr></tr>
          <tr>
            @if (creditIns()?.insResponse?.generalInfo?.applicationId) {
              <td colspan="3">
                {{ 'shoppingPage.products.labels.quoteId' | translate }}
                : {{ creditIns()?.insResponse?.generalInfo?.applicationId }}
              </td>
            }
          </tr>
        </tbody>
      </table>
      @for (coverage of coverage$ | async; track coverage) {
        <table>
          <tbody>
            <tr class="heading">
              <td style="width: 35%">{{ 'shoppingPage.products.labels.coveredBorrower' | translate }}</td>
              <td style="text-align: left">{{ 'shoppingPage.products.labels.description' | translate }}</td>
              <td style="width: 20%">{{ 'shoppingPage.products.labels.premium' | translate }}</td>
              <td style="width: 20%">{{ 'shoppingPage.products.labels.tax' | translate }}</td>
            </tr>
            <tr class="item">
              <td>{{ coverage.borrCovered }}</td>
              <td style="text-align: left">
                {{ coverage.coverageDescription | translate }} -
                {{ 'shoppingPage.products.top-filter.term' | translate }} {{ coverage.insTerm }}
              </td>
              <td style="text-align: center">${{ coverage.premium }}</td>
              <td style="text-align: center">${{ coverage.tax }}</td>
            </tr>
          </tbody>
        </table>
      }
    </div>
    <div>
      @if (creditIns()?.insResponse?.financeForm) {
        <div style="text-align: center">
          <b>
            {{ 'shoppingPage.products.labels.note' | translate }}
          </b>
        </div>
      }
    </div>
  </div>
</div>
