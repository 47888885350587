<header ph-dialog-header>
  <h2 class="title" ph-notification-title>{{ 'requestedQuotePopup.title' | translate }}</h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" />
</header>

<form class="vehicle-form-content" [formGroup]="requestQuoteForm" novalidate>
  <ng-container formArrayName="coverages">
    @for (_ of requestQuoteForm.controls['coverages']['controls']; let i = $index; track _) {
      <div [formGroupName]="i" class="request-quote-group">
        <ph-form-input-control
          placeholder="Coverage"
          inputSize="medium"
          [label]="'requestedQuotePopup.coverage' | translate"
          formControlName="coverage"
        />

        <ph-form-input-control
          inputSize="medium"
          [label]="'requestedQuotePopup.borrowerCode' | translate"
          formControlName="borrower"
        />

        <ph-form-input-control
          inputSize="medium"
          [label]="'requestedQuotePopup.insuranceTerm' | translate"
          formControlName="insTerm"
        />

        <ph-form-input-control
          inputSize="medium"
          [label]="'requestedQuotePopup.premium' | translate"
          formControlName="premium"
        />

        <ph-form-input-control
          inputSize="medium"
          [label]="'requestedQuotePopup.tax' | translate"
          formControlName="tax"
        />
      </div>
    }
  </ng-container>
</form>

<div class="footer" ph-dialog-actions>
  <button ui-ph-button size="small" state="secondary" (click)="close()" ph-overlay-close="cancel">
    {{ 'requestedQuotePopup.cancel' | translate }}
  </button>
  <button ui-ph-button size="small" (click)="printQuote()" ph-overlay-close="add">
    {{ 'requestedQuotePopup.confirm' | translate }}
  </button>
</div>
