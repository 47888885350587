import { createReducer, on } from '@ngrx/store';

import { ClaimService } from '@ph-core/services/claim.service';
import { Claim } from '@ph-model/api/response/claims.response';
import { SearchFilter } from '@ph-model/filters/search-filter';

import { getClaimsSuccess, filterClaimsGrid } from './claims.actions';

export const featureKey = 'claimState';
export interface ClaimState {
  claims: Claim[];
  claimsFilter: SearchFilter;
  gridClaims: Claim[];
}

const InitialState: ClaimState = {
  claims: [],
  claimsFilter: new SearchFilter(),
  gridClaims: [],
};

export const reducer = createReducer(
  InitialState,
  on(
    getClaimsSuccess,
    (state, { claims, filters }): ClaimState => ({
      ...state,
      claims: claims,
      gridClaims: claims,
      claimsFilter: filters,
    })
  ),

  on(
    filterClaimsGrid,
    (state, { filter }): ClaimState => ({
      ...state,
      claimsFilter: { ...filter },
      gridClaims: ClaimService.filter(filter, state.claims),
    })
  )
);
