import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, InputSignal, Renderer2 } from '@angular/core';

import { Size, State } from './ph-button';

@Component({
  selector: 'button[ui-ph-button], a[ui-ph-button]',
  template: '<ng-content />',
  styleUrls: ['./ph-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    '[class.ui-inactive]': 'invalid()',
    '[class.ui-wide]': 'wide()',
    '[class.ui-borderless]': 'borderless()',
    '[class.ui-underlined]': 'underlined()',
  },
})
export class PhButtonComponent {
  invalid: InputSignal<boolean> = input(false);
  wide: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  borderless: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  underlined: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  size: InputSignal<Size> = input('large');
  state: InputSignal<State> = input('primary');

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
    effect(() => {
      this._renderer.removeClass(this._elementRef.nativeElement, `ui-size-${this.size()}`);
      this._renderer.addClass(this._elementRef.nativeElement, `ui-size-${this.size()}`);

      this._renderer.removeClass(this._elementRef.nativeElement, 'ui-primary-button');
      this._renderer.removeClass(this._elementRef.nativeElement, 'ui-secondary-button');
      this._renderer.addClass(this._elementRef.nativeElement, `ui-${this.state()}-button`);
    });
  }
}
