import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  HostListener,
  Inject,
  input,
  InputSignal,
  Optional,
  output,
  OutputEmitterRef,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { map, startWith, tap } from 'rxjs/operators';

import { FORM_ERRORS, untilDestroyed } from '@ph-shared/utils';

import { SelectDropdownOptionComponent } from './select-dropdown-option.component';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
import { IconComponent } from '../icon/icon-component';

@Component({
  selector: 'ph-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormControlLabelComponent, IconComponent, ControlErrorComponent],
  host: {
    '[class.ui-horizontal]': 'horizontal()',
    '[class.ui-small]': 'small()',
    '[class.ui-bold-label]': 'boldLabel()',
    '[class.ui-read-only]': 'readOnly()',
  },
})
export class SelectDropdownComponent implements ControlValueAccessor, AfterContentInit, DoCheck {
  @ContentChildren(SelectDropdownOptionComponent) optionComponents: QueryList<SelectDropdownOptionComponent>;
  change: OutputEmitterRef<string> = output<string>();

  required: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  horizontal: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  small: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  readOnly: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  boldLabel: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  placeholder: InputSignal<string> = input('');
  label: InputSignal<string> = input('');
  labelFormatter: InputSignal<(value: string) => string> = input();

  options: { value: string; label: string }[] = [];
  isExpanded: boolean = false;

  value: string = '';
  disabled: boolean = false;
  errorMessage: string = '';

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(FORM_ERRORS) private _errors,
    @Self() @Optional() private control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  @HostListener('focusout') onTouched(): void {
    this.propagateTouched();
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched, errors } = this.control;
    if (errors) {
      if (errors.errorMessage) {
        this.errorMessage = errors.errorMessage;
      } else {
        const firstKey = Object.keys(errors)[0];
        const getError = this._errors[firstKey];
        this.errorMessage = getError ? getError(errors[firstKey]) : '';
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  get valueLabel(): string {
    const found = this.options.find(
      (o: { value: string; label: string }) => JSON.stringify(o.value) === JSON.stringify(this.value)
    );

    return found?.label || null;
  }

  get hasValue(): boolean {
    return !(this.value === undefined || this.value === null || this.value === '');
  }

  ngDoCheck(): void {
    // replace with IterableDiffers maybe
    const options = this.optionComponents?.map(({ value, label }) => ({ value: value(), label }));
    if (JSON.stringify(this.options) !== JSON.stringify(options)) {
      if (options) {
        this.options = options;
      }
    }
  }

  ngAfterContentInit(): void {
    this.optionComponents.changes
      .pipe(
        startWith(this.optionComponents),
        map((optionComponents: QueryList<SelectDropdownOptionComponent>) => optionComponents.toArray()),
        tap((optionComponents: SelectDropdownOptionComponent[]) => {
          this.options = optionComponents.map(({ value, label }) => ({ value: value(), label }));
        }),
        this.#untilDestroyed()
      )
      .subscribe();
  }

  select(value: string): void {
    if (this.disabled) {
      return;
    }

    if (this.value !== value) {
      const found = this.options.find((o) => o.value === value);

      if (found) {
        this.value = found.value;
        this.cdr.markForCheck();
      }

      this.propagateChange(this.value);
      this.change.emit(this.value);
    }

    this.closeDropdown();
  }

  toggleExpand(): void {
    if (this.disabled || this.readOnly() || !this.options.length) {
      return;
    }

    this.isExpanded = !this.isExpanded;
  }

  closeDropdown(): void {
    this.isExpanded = false;
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: string) => undefined;
  propagateTouched = () => undefined;

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
