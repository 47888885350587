import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ContractSearchDetails } from '@ph-model/api/contract-search-details';
import { ContractSearchItem } from '@ph-model/api/contract-search-item';

@Injectable({ providedIn: 'root' })
export class ContractSearchService {
  readonly pageSize: number = 20;
  private http: HttpClient = inject(HttpClient);

  loadContractSearchData(
    pageNumber: number,
    vin: string,
    firstName: string,
    lastName: string
  ): Observable<{ data: ContractSearchItem[]; rowCount: string }> {
    const params: {
      pageNumber: number;
      vin?: string;
      firstName?: string;
      lastName?: string;
    } = { pageNumber };

    if (vin) {
      params.vin = vin;
    }

    if (firstName) {
      params.firstName = firstName;
    }

    if (lastName) {
      params.lastName = lastName;
    }

    return this.http
      .get<{ data: ContractSearchItem[]; rowCount: string }>(`${environment.apiUrl}/cms-contract/search`, {
        params,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  loadContractDetails(contractId: string): Observable<ContractSearchDetails> {
    return this.http
      .get<ContractSearchDetails>(`${environment.apiUrl}/cms-contract/${contractId}`)
      .pipe(catchError((error) => throwError(() => error)));
  }

  loadContractPdf(contractId: string): Observable<Blob> {
    return this.http
      .get<Blob>(`${environment.apiUrl}/cms-contract/${contractId}/pdf`, { responseType: 'blob' as 'json' })
      .pipe(catchError((error) => throwError(() => error)));
  }
}
