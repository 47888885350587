import { Options } from '@ph-core/enums';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { SubmitCartModel } from '@ph-model/cart/submit-cart.model';
import { DealerInfo } from '@ph-model/login';
import { RequestBase } from '@ph-model/request/request-base';
import { EContract } from '@ph-model/request/save-power-up-econtract/econtract.request';
import { parseToInteger, parseToNumber } from '@ph-shared/utils';
import * as VehicleReducer from '@ph-store/vehicle/vehicle.reducer';

export class SavePowerUpEContractRequest {
  constructor(
    public eContract: EContract,
    public requestBase: RequestBase = new RequestBase()
  ) {
    this.eContract = eContract;
    this.requestBase = requestBase;
  }

  static generateRequest(
    submitCart: SubmitCartModel,
    cartItem: CartItem,
    vehicleState: VehicleReducer.VehicleState,
    dealer: string,
    dealerInfo: DealerInfo
  ): SavePowerUpEContractRequest {
    const {
      firstName,
      lastName,
      middleInitial,
      businessName,
      businessFlag,
      address,
      suiteApt,
      city,
      stateProvince,
      postalCode,
      phone1,
      phone2,
      email,
      country,
    } = submitCart.customerForm;

    const {
      firstName: cosignerFirstName,
      lastName: cosignerLastName,
      middleInitial: cosignerMiddleName,
      address: cosignerAddress1,
      suiteApt: cosignerAddress2,
      city: cosignerCity,
      stateProvince: cosignerState,
      postalCode: cosignerZipcode,
      phone1: cosignerHomePhone,
      phone2: cosignerWorkPhone,
      email: cosignerEmail,
      country: cosignerCountryCode,
    } = submitCart.cosignerForm;

    const {
      name: lienholderName,
      address1: lienholderAddress1,
      address2: lienholderAddress2,
      city: lienholderCity,
      stateProvince: lienholderState,
      postalCode: lienholderZipCode,
      phone: lienholderPhone,
      fax: lienholderFax,
      email: lienholderEmail,
      countryCode: lienholderCountryCode,
    } = submitCart.lienholderForm.lienholder || {};

    const {
      formNumber,
      productCode,
      coverageCode,
      customerTerm,
      customerCost,
      deductible,
      startingInterval,
      termMileage,
      dealerCost,
      planSKU,
    } = cartItem.product;

    const { dealerAddress1, dealerCity, dealerPhone, dealerState, dealerZipCode } = dealerInfo;

    const { inServiceDate, make, odometer, trim, msrp, vehicleCondition } = vehicleState.values;

    const { serialNumber, chargerPurchaseDate, chargerModel, chargerYear, chargerPurchasePrice } =
      vehicleState.chargerForm;

    const vinValue: string = serialNumber;
    const saleDateValue: string = chargerPurchaseDate?.formatted.replace(/\//g, '-');

    let inServiceDateValue: string;
    if (inServiceDate) {
      inServiceDateValue = inServiceDate.formatted.replace(/\//g, '-');
    } else {
      const currentDate = new Date();

      if (chargerYear >= currentDate.getFullYear().toString()) {
        inServiceDateValue = saleDateValue;
      } else {
        inServiceDateValue = `${currentDate.getFullYear()}-01-01`;
      }
    }

    const isBusiness = (businessFlag as Options) === Options.YES;

    const lienholderEnabled = submitCart.lienholderEnabled;

    const eContract = new EContract(
      isBusiness ? '_' : firstName,
      isBusiness ? businessName : lastName,
      isBusiness ? '' : middleInitial,
      formNumber,
      address,
      suiteApt,
      city,
      stateProvince,
      postalCode,
      phone1,
      phone2,
      email,
      country.slice(0, 2),
      cosignerFirstName || null,
      cosignerLastName || null,
      cosignerMiddleName || null,
      cosignerAddress1 || null,
      cosignerAddress2 || null,
      cosignerCity || null,
      cosignerState || null,
      cosignerZipcode || null,
      cosignerHomePhone || null,
      cosignerWorkPhone || null,
      cosignerEmail || null,
      cosignerCountryCode?.slice(0, 2) || null,
      lienholderEnabled ? lienholderName : null,
      lienholderEnabled ? lienholderAddress1 : null,
      lienholderEnabled ? lienholderAddress2 : null,
      lienholderEnabled ? lienholderCity : null,
      lienholderEnabled ? lienholderState : null,
      lienholderEnabled ? lienholderZipCode : null,
      lienholderEnabled ? lienholderCountryCode : null,
      lienholderEnabled ? lienholderEmail : null,
      lienholderEnabled ? lienholderFax : null,
      lienholderEnabled ? lienholderPhone : null,
      '',
      dealer,
      dealerAddress1 || '',
      dealerCity || '',
      dealerState || '',
      dealerZipCode || '',
      dealerPhone || '',
      '',
      productCode,
      planSKU,
      coverageCode,
      customerTerm,
      customerCost,
      saleDateValue,
      parseToInteger(deductible),
      parseToInteger(startingInterval),
      parseToInteger(termMileage),
      vinValue,
      chargerYear || '',
      make || '',
      chargerModel?.toUpperCase() || '',
      trim || '',
      parseToInteger(odometer) || 0,
      parseToNumber(msrp) || 0,
      inServiceDateValue,
      (vehicleCondition as string) || 'USED',
      +chargerPurchasePrice,
      dealerCost,
      'USD',
      'P'
    );

    return new SavePowerUpEContractRequest(eContract);
  }
}
