<header ph-dialog-header>
  <h2 class="title" ph-notification-title>{{ 'loginPage.forgotUsername.forgotUsername' | translate }}</h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" />
</header>

@if (!emailSuccess()) {
  <form [formGroup]="usernameForm">
    <ph-form-input-control formControlName="email" [label]="'loginPage.forgotUsername.emailAddress' | translate" />

    @if (supportCaptcha) {
      <ngx-recaptcha2 [siteKey]="recaptchaSiteKey" formControlName="captcha" [hl]="langValue" />
    }
  </form>
}

@if (!emailSuccess()) {
  <div ph-dialog-actions>
    <button ui-ph-button size="small" state="secondary" (click)="close()" ph-overlay-close="cancel">
      {{ 'loginPage.forgotUsername.cancel' | translate }}
    </button>

    <button
      ui-ph-button
      size="small"
      (click)="submitUsernameUpdate()"
      [disabled]="usernameForm.invalid"
      ph-overlay-close="add"
    >
      {{ 'loginPage.forgotUsername.sendUsername' | translate }}
    </button>
  </div>
} @else {
  <div class="success-block">
    <p class="success-block-text">{{ 'loginPage.forgotUsername.usernameSent' | translate }}</p>
    <button
      class="success-block-btn"
      type="button"
      ui-ph-button
      size="small"
      ph-overlay-close="cancel"
      (click)="close()"
    >
      {{ 'loginPage.forgotUsername.continue' | translate }}
    </button>
  </div>
}
