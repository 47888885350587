export enum ClientCSSProps {
  brand_accent_color = '--brand-accent-color',
  brand_bar_color = '--brand-bar-color',
  button_color = '--button-color',
  error_color_1 = '--error-color-1',
  error_color_2 = '--error-color-2',
  success_color = '--success-color',
  warning_color = '--warning-color',
  info_color = '--info-color',
  font_color = '--font-color',
  logo_bg_color = '--logo-bkgrd-color',
  primary_color = '--primary-color',
  secondary_color = '--secondary-color',
  navbar_bg_color = '--navbar-bg-color',
  navbar_bg_color_hover = '--navbar-bg-color-hover',
  navbar_bg_separator_color = '--navbar-bg-separator-color',
  control_primary_color = '--control-primary-color',
  control_primary_color_darker = '--control-primary-color-darker',
  homepage_bg_color = '--homepage-bg-color',
}
