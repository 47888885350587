import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private verbose = localStorage.getItem('davinci.logger') === 'on';

  info(...args: string[]): void {
    if (!this.verbose) {
      return;
    }
    console.info(...args);
  }

  log(...args): void {
    if (!this.verbose) {
      return;
    }
    console.log(...args);
  }

  warn(...args: string[]): void {
    if (!this.verbose) {
      return;
    }
    console.warn(...args);
  }

  error(...args: (string | Record<string, unknown>)[]): void {
    if (!this.verbose) {
      return;
    }
    console.error(...args);
  }

  trace(...args: string[]): void {
    if (!this.verbose) {
      return;
    }
    console.trace(...args);
  }
}
