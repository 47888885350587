import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, InputSignal, Renderer2 } from '@angular/core';

import { BorderColor, Size, TextAlign } from './form-input';

@Component({
  selector: 'input[ui-ph-input]',
  template: '<ng-content />',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    '[class.ui-invalid]': 'invalid()',
    '[class.ui-read-only]': 'readOnly()',
  },
})
export class FormInputComponent {
  invalid: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  readOnly: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });

  size: InputSignal<Size> = input('large');
  textAlign: InputSignal<TextAlign> = input('left');
  borderColor: InputSignal<BorderColor> = input('grey');

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2
  ) {
    effect(() => {
      this._renderer.removeClass(this._elementRef.nativeElement, `ui-size-${this.size()}`);
      this._renderer.removeClass(this._elementRef.nativeElement, `ui-text-align-${this.textAlign()}`);
      this._renderer.removeClass(this._elementRef.nativeElement, `ui-border-color-${this.borderColor()}`);

      this._renderer.addClass(this._elementRef.nativeElement, `ui-size-${this.size()}`);
      this._renderer.addClass(this._elementRef.nativeElement, `ui-border-color-${this.borderColor()}`);
      this._renderer.addClass(this._elementRef.nativeElement, `ui-text-align-${this.textAlign()}`);
    });
  }
}
