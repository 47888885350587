import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'a[ph-icon-button], button[ph-icon-button]',
  standalone: true,
})
export class PhIconButtonDirective {
  constructor({ nativeElement }: ElementRef, renderer: Renderer2) {
    renderer.addClass(nativeElement, 'ph-icon-button');
  }
}
