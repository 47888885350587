import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureKey, RemitState } from './remit.reducer';

const selectRemitState = createFeatureSelector<RemitState>(featureKey);
export const selectPendingFilters = createSelector(selectRemitState, (state) => state.pendingFilters);
export const selectPendingContracts = createSelector(selectRemitState, (state) => state.pendingContracts);
export const selectPendingGridContracts = createSelector(selectRemitState, (state) => state.pendingGridContracts);
export const selectPendingToRemitContracts = createSelector(selectRemitState, (state) => state.pendingToRemitContracts);
export const selectPendingToDeclineContracts = createSelector(
  selectRemitState,
  (state) => state.pendingToDeclineContracts
);
export const selectRemittedFilters = createSelector(selectRemitState, (state) => state.remittedFilters);
export const selectRemittedGridContracts = createSelector(selectRemitState, (state) => state.remittedGridContracts);
export const selectSubmittedPendingContracts = createSelector(
  selectRemitState,
  (state) => state.submittedPendingContracts
);
export const selectLoadingPendingContracts = createSelector(selectRemitState, (state) => state.loadingPendingContracts);
