import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ClaimService } from '@ph-core/services/claim.service';
import { ClaimsResponse } from '@ph-model/api/response/claims.response';
import { selectUser } from '@ph-store/user/user.selectors';

import { claimsFailure, getClaims, getClaimsSuccess, getContractClaims } from './claims.actions';

@Injectable()
export class ClaimsEffects {
  getContracts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getClaims),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([, user]) => {
        const allDealers = JSON.parse(JSON.stringify(user.allDealers));

        if (allDealers && allDealers.length === 1) {
          allDealers[0].cmsDealerNumber = user.cmsDealerNumber;
        }

        return this.httpClient
          .post<ClaimsResponse>(`${environment.apiUrl}/getclaims`, {
            dealerId: user.cmsDealerNumber,
            allDealers: allDealers,
            searchCriteria: {
              vinCode: '',
              claimNumber: '',
              claimsStatus: '',
              endDate: '',
              repairOrderNo: '',
              startDate: '',
            },
            roleIds: user.roleIds,
          })
          .pipe(
            map((response: ClaimsResponse) => {
              const historyFilters = ClaimService.fetchFilters(response.claimStatusInformations);

              return getClaimsSuccess({ claims: response.claimStatusInformations, filters: historyFilters });
            }),
            catchError((error) => {
              console.error(error);

              return of(claimsFailure());
            })
          );
      })
    );
  });

  getContractClaims$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getContractClaims),
      switchMap(({ contractNumber }: { contractNumber: string }) =>
        this.httpClient.get<ClaimsResponse>(`${environment.apiUrl}/cms-contract/${contractNumber}/claims`).pipe(
          map((response: ClaimsResponse) =>
            getClaimsSuccess({
              claims: response.claimStatusInformations,
              filters: ClaimService.fetchFilters(response.claimStatusInformations),
            })
          ),
          catchError((error) => {
            console.error(error);

            return of(claimsFailure());
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private store: Store
  ) {}
}
