export class SiteInfo {
  appEmail: string;
  appEmail2?: string;
  claimEmail: string;
  customerServiceEmail: string;
  customerServicePhoneNumber: string;
  enrollEmail: string;
  claimsPhoneNumber: string;
  dealerSupportPhoneNumber: string;
  dealerSupportPhoneNumber2?: string;
  fax: string;
}
