import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';
import { NgxCaptchaModule } from 'ngx-captcha';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { PhOverlayRef } from '@ph-core/services/overlay';
import { EmailValidator, RequiredValidator } from '@ph-core/validations';
import { untilDestroyed } from '@ph-shared/utils';
import { selectProgramId } from '@ph-store/config/config.selectors';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  templateUrl: './forgot-username-popup.component.html',
  styleUrls: ['./forgot-username-popup.component.scss'],
  standalone: true,
  imports: [
    FormInputControlComponent,
    IconComponent,
    NgxCaptchaModule,
    PhButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class ForgotUsernamePopupComponent implements OnInit {
  usernameForm: FormGroup;
  emailSuccess: WritableSignal<boolean> = signal(false);
  langValue: SupportedLanguageCodes = SupportedLanguageCodes.EnglishCA;
  supportCaptcha: boolean = environment.features.supportCaptcha;
  recaptchaSiteKey: string = environment.recaptchaSiteKey;

  private _isSSOFlow: boolean = environment.auth.sso;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private ref: PhOverlayRef<ForgotUsernamePopupComponent>
  ) {}

  ngOnInit(): void {
    this.usernameForm = this.fb.group({
      email: this.fb.control(null, [RequiredValidator, EmailValidator]),
      captcha: this.fb.control(null, this.supportCaptcha ? [RequiredValidator] : []),
    });
  }

  submitUsernameUpdate(): void {
    if (this.usernameForm.invalid) {
      return;
    }
    if (this._isSSOFlow) {
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      });

      this.store
        .select(selectProgramId)
        .pipe(
          take(1),
          switchMap((programId) => {
            return this.httpClient
              .post<void>(
                `${environment.apiUrl}/okta/forgotusername`,
                {
                  email: this.usernameForm.get('email').value,
                  programId,
                  application: environment.application,
                  captcha: this.usernameForm.get('captcha').value,
                },
                { headers }
              )
              .pipe(catchError((error) => throwError(() => error)));
          })
        )
        .subscribe(() => this.emailSuccess.set(true));
    } else {
      this.store
        .select(selectProgramId)
        .pipe(
          take(1),
          switchMap((programId) => {
            return this.httpClient
              .post<{ status: boolean }>(`${environment.apiUrl}/forgotusername`, {
                application: environment.application,
                programId: programId,
                emailAddress: this.usernameForm.get('email').value,
                captcha: this.usernameForm.get('captcha').value,
              })
              .pipe(catchError((error) => throwError(() => error)));
          }),
          filter((data) => data && data.status),
          tap(() => this.emailSuccess.set(true)),
          this.#untilDestroyed()
        )
        .subscribe();
    }
  }

  close(): void {
    this.ref.close();
  }
}
