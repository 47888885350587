import { AvailableRole } from './getuser.response';

export class EditUser {
  modifiedBy: string;
  cmsDealerNumber: string;
  applicationId: string;
  userId: string;
  fullName: string;
  isActive: string;
  emailAddress: string;
  dealerId: string;
  languageId: string;
  roles: AvailableRole[];
}
