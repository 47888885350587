import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { ProductImage } from '@ph-model/api/product-images.model';
import { PrismicData } from '@ph-model/api/response/prismic-response.model';

export const loadPrismicData = createAction('[PRISMIC] LOAD PRISMIC DATA');
export const loadPrismicDataSuccess = createAction(
  '[PRISMIC] LOAD PRISMIC DATA SUCCESS',
  props<{ data: PrismicData }>()
);
export const loadPrismicDataFailure = createAction(
  '[PRISMIC] LOAD PRISMIC DATA FAILURE',
  props<{ error: HttpErrorResponse }>()
);

export const getProductImages = createAction('[PRISMIC] GET PRODUCT IMAGES');
export const getProductImagesSuccess = createAction(
  '[PRISMIC] GET PRODUCT IMAGES SUCCESS',
  props<{ productImages: { [key: string]: ProductImage } }>()
);
export const getProductImagesFailure = createAction('[PRISMIC] GET PRODUCT IMAGES FAILURE');
