<header ph-dialog-header>
  <h2 class="title" ph-notification-title>{{ 'lienholderPopup.addLienholder' | translate }}</h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" />
</header>

<form [formGroup]="lienholderForm">
  <ph-form-input-control
    class="full-width"
    required
    inputSize="medium"
    [label]="'lienholderPopup.lienholderName' | translate"
    formControlName="lienholderName"
  />

  <ph-form-input-control
    class="three-fr-width"
    required
    inputSize="medium"
    [label]="'lienholderPopup.address' | translate"
    formControlName="lienholderAddress1"
  />

  <ph-form-input-control
    class="one-fr-width"
    inputSize="medium"
    [label]="'editContractPopup.suite' | translate"
    formControlName="suite"
  />

  <ph-form-input-control
    class="three-fr-width"
    required
    inputSize="medium"
    [label]="'lienholderPopup.city' | translate"
    formControlName="lienholderCity"
  />

  <ph-form-input-control
    class="one-fr-width"
    required
    inputSize="medium"
    [label]="'lienholderPopup.postalCode' | translate"
    formControlName="lienholderPostalCode"
  />

  <ph-form-input-control
    class="one-fr-width"
    required
    inactive
    inputSize="medium"
    [label]="'lienholderPopup.country' | translate"
    formControlName="lienholderCountryCode"
  />

  <ph-form-input-control
    class="one-fr-width"
    required
    inactive
    inputSize="medium"
    [label]="'lienholderPopup.state' | translate"
    formControlName="lienholderStateProvince"
  />
</form>

<div class="footer" ph-dialog-actions>
  <button ui-ph-button size="small" state="secondary" (click)="close()" ph-overlay-close="cancel">
    {{ 'lienholderPopup.cancel' | translate }}
  </button>
  <button ui-ph-button size="small" (click)="addLienholder()" [disabled]="!lienholderForm.valid" ph-overlay-close="add">
    {{ 'lienholderPopup.add' | translate }}
  </button>
</div>
