export class ProductInfo {
  constructor(
    public productSKU: string,
    public term: number,
    public termMileage: number|string,
    public productCode: string,
    public planCode: string,
    public planName: string,
    public riskType: string,
    public coverageCode: string,
    public dealerCost: number,
    public saleDate: string,
    public customerCost: number,
    public form: string,
    public usageType: string,
    public productType: string,
    public quoteType: string,
    public QLIType: string,
    public rateDate: string,
    public contractSignatureDate: string,
    public contractCreatedDate: string,
    public deductible: string
  ) {}
}
