import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, input, InputSignal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ph-form-input-checkbox',
  templateUrl: 'form-input-checkbox.component.html',
  styleUrls: ['./form-input-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputCheckboxComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class FormInputCheckboxComponent implements ControlValueAccessor {
  label: InputSignal<string> = input.required();
  disabled: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });

  value: boolean = false;

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: (boolean) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  updateValue(val: boolean): void {
    this.value = val;
    this._onChange(this.value);
    this._onTouched();
  }

  private _onChange: (value: boolean) => void = () => undefined;
  private _onTouched: () => void = () => undefined;
}
