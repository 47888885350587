import { EContractStatusType } from '@ph-model/e-contract-status-type';
import { RequestBase } from '@ph-model/request/request-base';

export class UpdateStatusEContractRequest {
  constructor(
    public contractID: string,
    public status: EContractStatusType,
    public source: RequestBase = new RequestBase()
  ) {
    this.contractID = contractID;
    this.status = status;
    this.source = source;
  }
}
