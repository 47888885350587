import { ClientCSSProps } from '@ph-core/enums';
import { PrismicCSSVariables } from '@ph-model/api/response/prismic-response.model';

import { setCssVariable } from './css-replacer.util';

export function setClientCssValues(clientCss: PrismicCSSVariables): void {
  Object.keys(clientCss).forEach((prop: string) => {
    if (prop && clientCss[prop] && ClientCSSProps[prop]) {
      setCssVariable(ClientCSSProps[prop], clientCss[prop]);
    }
  });
}
