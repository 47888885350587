import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { initializeSteps, switchToStep, switchToStepByUrl, updateCurrentStep } from '../store/shopping-stepper.actions';
import { ShoppingStepperState } from '../store/shopping-stepper.reducer';
import { flattenedSteps, selectShoppingStepperState } from '../store/shopping-stepper.selectors';

export const shoppingStepperGuard = (route: ActivatedRouteSnapshot) => {
  const store$ = inject(Store);

  return store$.select(selectShoppingStepperState).pipe(
    map((state: ShoppingStepperState) => {
      if (!state.steps.length) {
        store$.dispatch(initializeSteps({ stepDefinitions: environment.shoppingFlow.steps }));
      }
      const destination = route.url[0].path;
      let destinationInCurrentShoppingPath = false;
      let earlierStepsCompleted = true;
      for (const step of flattenedSteps(state.steps)) {
        if ((step.url as string) === destination) {
          destinationInCurrentShoppingPath = true;
          break;
        }
        earlierStepsCompleted &&= step.valid;
      }
      if (!earlierStepsCompleted || !destinationInCurrentShoppingPath) {
        store$.dispatch(switchToStep({ index: 0, options: { updateProgress: true } }));

        return false;
      } else if ((state.currentStep?.url as string) !== destination) {
        store$.dispatch(switchToStepByUrl({ url: destination, options: { updateProgress: true } }));

        return false;
      } else {
        store$.dispatch(updateCurrentStep({ data: { visited: true } }));

        return true;
      }
    }),
    catchError(() => of(false))
  );
};
