import { Directive, HostListener, input, InputSignal } from '@angular/core';

@Directive({
  selector: '[phDateMask]',
  standalone: true,
})
export class DateMaskDirective {
  min: InputSignal<Date> = input.required();
  max: InputSignal<Date> = input.required();

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent): string {
    const input = event.target as HTMLInputElement;
    const trimmed = input.value.replace(/[^0-9]+/g, '');

    if (trimmed.length === 8) {
      const maxYear = this.max().getFullYear();
      const maxMonth = this.max().getMonth() + 1;
      const maxDay = this.max().getDate();
      const maxDate = this.max();

      const minYear = this.min().getFullYear();
      const minMonth = this.min().getMonth() + 1;
      const minDay = this.min().getDate();
      const minDate = this.min();

      const month = +trimmed.slice(0, 2) > 12 ? 12 : +trimmed.slice(0, 2) < 1 ? '01' : trimmed.slice(0, 2);
      const day = +trimmed.slice(2, 4) > 31 ? 31 : +trimmed.slice(2, 4) < 1 ? '01' : trimmed.slice(2, 4);
      const year =
        +trimmed.slice(4, 8) > maxYear ? maxYear : +trimmed.slice(4, 8) < minYear ? minYear : trimmed.slice(4, 8);

      const selectedDate = new Date(`${month}/${day}/${year}`);

      let result;
      if (selectedDate > maxDate) {
        result = `${maxMonth < 10 ? '0' + maxMonth : maxMonth}/${maxDay < 10 ? '0' + maxDay : maxDay}/${maxYear}`;
      } else if (selectedDate < minDate) {
        result = `${minMonth < 10 ? '0' + minMonth : minMonth}/${minDay < 10 ? '0' + minDay : minDay}/${minYear}`;
      } else {
        result = `${month}/${day}/${year}`;
      }

      return (input.value = result);
    } else {
      return (input.value = input.value.replace(/[^0-9/]+/g, '').slice(0, 10));
    }
  }
}
