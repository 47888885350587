import { environment as baseAcuraEnvironment } from '@ph-env/acura/environment.acura.base';
import { Environment } from '@ph-env/environment.model';
import { cancellationUrl } from '@ph-env/modifiers/cancellation-url';

export const environment: Environment = {
  ...baseAcuraEnvironment,
  production: true,
  apiUrl: 'https://uatacuracaregapcom.sgdavinci.com', // temporary until DNS is set up
  okta: {
    SSOCredentials: {
      ...baseAcuraEnvironment.okta.SSOCredentials,
      issuer: 'https://safeguard-uat.oktapreview.com/oauth2/aus2oug9jmJaurlRr1d7',
      clientId: '0oa56ml8ba4KN3q611d7',
      idps: [
        {
          idpId: '0oa45tqipobmQGznD1d7',
          buttonLabelTranslationKey: 'loginPage.loginForm.loginUsingOkta',
        },
      ],
    },
  },
  ...cancellationUrl('https://staging-cancellationui.safe-guardproducts.com/cancellation-portal/#/acuracareus'),
};
