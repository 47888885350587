export class EContract {
  constructor(
    public customerFirstName: string,
    public customerLastName: string,
    public customerMiddleName: string,
    public formNumber: string,
    public customerAddress1: string,
    public customerAddress2: string,
    public customerCity: string,
    public customerState: string,
    public customerZipcode: string,
    public customerHomePhone: string,
    public customerWorkPhone: string,
    public customerEmail: string,
    public customerCountryCode: string,
    public cosignerFirstName: string,
    public cosignerLastName: string,
    public cosignerMiddleName: string,
    public cosignerAddress1: string,
    public cosignerAddress2: string,
    public cosignerCity: string,
    public cosignerState: string,
    public cosignerZipcode: string,
    public cosignerHomePhone: string,
    public cosignerWorkPhone: string,
    public cosignerEmail: string,
    public cosignerCountryCode: string,
    public lienholderName: string,
    public lienholderAddress1: string,
    public lienholderAddress2: string,
    public lienholderCity: string,
    public lienholderState: string,
    public lienholderZipCode: string,
    public lienholderCountryCode: string,
    public lienholderEmail: string,
    public lienholderFax: string,
    public lienholderPhone: string,
    public dealerCompanyName: string,
    public dealerNumber: string,
    public dealerAddress: string,
    public dealerCity: string,
    public dealerState: string,
    public dealerZipCode: string,
    public dealerPhone: string,
    public dealerFax: string,
    public productCode: string,
    public productSKU: string,
    public coverageCode: string,
    public term: number,
    public customerCost: number,
    public saleDate: string,
    public deductible: number,
    public startingInterval: number,
    public termMileage: number,
    public vin: string,
    public year: string,
    public make: string,
    public model: string,
    public trim: string,
    public odometer: number,
    public msrp: number|string,
    public inServiceDate: string,
    public vehicleCondition: string,
    public vehiclePurchasePrice: number,
    public contractDealerCost: number,
    public currencyCode: string,
    public monthlyBillingType: string
  ) {
  }
}
