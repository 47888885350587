import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, HostListener, Inject, input, InputSignal, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';

import { Size } from '@ph-shared/components';
import { DecimalNumberDirective, NumberDirective } from '@ph-shared/directives';
import { FORM_ERRORS } from '@ph-shared/utils';

import { ControlErrorComponent } from '../control-error/control-error.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
import { FormInputComponent } from '../form-input/form-input.component';

@Component({
  selector: 'ph-form-input-control',
  templateUrl: 'form-input-control.component.html',
  styleUrls: ['./form-input-control.component.scss'],
  standalone: true,
  imports: [
    ControlErrorComponent,
    DecimalNumberDirective,
    FormControlLabelComponent,
    FormInputComponent,
    FormsModule,
    NumberDirective,
    ReactiveFormsModule,
  ],
  host: {
    '[class.ui-horizontal]': 'horizontal()',
  },
})
export class FormInputControlComponent implements ControlValueAccessor {
  label: InputSignal<string> = input('');
  placeholder: InputSignal<string> = input('');
  onlyNumbers: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  decimalNumbersOnly: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  type: InputSignal<string> = input('');
  required: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  readOnly: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  inputSize: InputSignal<Size> = input('large');
  maxLength: InputSignal<number> = input();
  inactive: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  horizontal: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });

  value: string = '';
  disabled: boolean = false;
  errorMessage: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(FORM_ERRORS) private _errors,
    @Self() @Optional() private control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  @HostListener('focusout') onTouched() {
    this._onTouched();
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched, errors } = this.control;

    if (errors) {
      if (errors.errorMessage) {
        this.errorMessage = errors.errorMessage;
      } else {
        const firstKey = Object.keys(errors)[0];
        const getError = this._errors[firstKey];
        this.errorMessage = getError ? getError(errors[firstKey]) : '';
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  writeValue(value: string): void {
    this.value = value || '';
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  updateValue(val: string): void {
    this.writeValue(val);
    this._onChange(this.value);
    this.cdr.markForCheck();
    this._onTouched();
  }

  private _onChange: (value: string) => void = () => undefined;
  private _onTouched: () => void = () => undefined;
}
