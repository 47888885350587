import { ProductItem } from '@ph-model/response';

export class SubmittedCartItem {
  constructor(
    public product: ProductItem,
    public key: string,
    public saved: boolean,
    public message: string,
    public code: string,
    public contractId: string,
    public contractSource: string,
    public errorType: string
  ) {
    this.product = product || new ProductItem();
    this.saved = saved || false;
    this.key = key || '';
    this.message = message || '';
    this.code = code || '';
    this.contractId = contractId || '';
    this.errorType = errorType || '';
  }
}
