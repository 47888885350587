import { HttpErrorResponse } from '@angular/common/http';

import { createReducer, on } from '@ngrx/store';

import { ContractSearchDetails } from '@ph-model/api/contract-search-details';
import { ContractSearchItem } from '@ph-model/api/contract-search-item';
import {
  loadContractDetailsData,
  loadContractDetailsDataFailure,
  loadContractDetailsDataSuccess,
  loadContractSearchData,
  loadContractSearchDataFailure,
  loadContractSearchDataSuccess,
  resetContractSearchData,
  resetContractSearchParams,
  updateContractSearchCurrentPage,
} from '@ph-store/contract-search/contract-search.actions';

export const featureKey = 'contractSearchState';

export interface ContractSearchState {
  contracts: ContractSearchItem[];
  contractsLoading: boolean;

  rowCount: number;
  pagesCount: number;
  currentPage: number;

  searchParams: { vin: string; firstName: string; lastName: string };

  contractDetails: ContractSearchDetails;
  contractDetailsLoading: boolean;

  error: HttpErrorResponse;
}

const InitialState: ContractSearchState = {
  contracts: [],
  rowCount: 0,
  pagesCount: 1,
  currentPage: 1,
  contractsLoading: false,
  searchParams: { vin: '', firstName: '', lastName: '' },
  contractDetails: undefined,
  contractDetailsLoading: false,
  error: undefined,
};

export const reducer = createReducer(
  InitialState,
  on(
    loadContractSearchData,
    (state, { firstName, lastName, vin }): ContractSearchState => ({
      ...state,
      searchParams: {
        firstName,
        lastName,
        vin,
      },
      rowCount: 0,
      pagesCount: 1,
      currentPage: 1,
      contractsLoading: true,
      contractDetailsLoading: false,
      error: undefined,
    })
  ),
  on(
    loadContractSearchDataSuccess,
    (state, { contracts, rowCount, pagesCount }): ContractSearchState => ({
      ...state,
      contracts,
      rowCount,
      pagesCount,
      contractsLoading: false,
      contractDetailsLoading: false,
      error: undefined,
    })
  ),
  on(
    updateContractSearchCurrentPage,
    (state, { pageNumber }): ContractSearchState => ({
      ...state,
      currentPage: pageNumber,
      contractsLoading: true,
      contractDetailsLoading: false,
      error: undefined,
    })
  ),
  on(
    loadContractSearchDataFailure,
    (state, { error }): ContractSearchState => ({
      ...state,
      error,
      contractsLoading: false,
      contractDetailsLoading: false,
    })
  ),
  on(
    resetContractSearchParams,
    (state): ContractSearchState => ({
      ...state,
      searchParams: { ...InitialState.searchParams },
    })
  ),

  on(
    loadContractDetailsData,
    (state): ContractSearchState => ({ ...state, contractDetails: undefined, contractDetailsLoading: true })
  ),

  on(
    loadContractDetailsDataSuccess,
    (state, { contractDetails }): ContractSearchState => ({ ...state, contractDetails, contractDetailsLoading: false })
  ),

  on(
    loadContractDetailsDataFailure,
    (state, { error }): ContractSearchState => ({ ...state, error, contractDetailsLoading: false })
  ),

  on(resetContractSearchData, (): ContractSearchState => ({ ...InitialState }))
);
