import { ComponentType, Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { PhOverlayConfig, PhOverlayData, PhOverlayRef, PH_OVERLAY_DATA } from './index';

@Injectable({
  providedIn: 'root',
})
export class PhOverlay {
  constructor(private o: Overlay) {}

  get scrollStrategies(): ScrollStrategyOptions {
    return this.o.scrollStrategies;
  }

  attach<Type>(component: ComponentType<Type>, config: PhOverlayConfig = {}): PhOverlayRef<Type> {
    return this.create<Type>(component, config).attach();
  }

  create<Type>(
    component: ComponentType<Type> | TemplatePortal<Type>,
    config: PhOverlayConfig = {}
  ): PhOverlayRef<Type> {
    const ref = this._createReference<Type>(component, config);
    const injector = this._createInjector<Type>(ref, config?.data);

    return ref.init(injector, config);
  }

  private _createReference<Type>(
    component: ComponentType<Type> | TemplatePortal<Type>,
    { position, scrollStrategy, hasBackdrop, panelClass, backdropClass, clickableBackdrop }: Partial<PhOverlayConfig>
  ): PhOverlayRef<Type> {
    const backdrop = typeof backdropClass === 'string' ? backdropClass : 'ph-overlay--backdrop';
    const overlayRef = this.o.create({
      backdropClass: backdrop,
      positionStrategy: position,
      scrollStrategy,
      hasBackdrop,
      panelClass,
    });

    const ref = new PhOverlayRef(overlayRef, component);

    if (hasBackdrop && clickableBackdrop) {

      // HACK setTimeout required to propagate mouse click to autocomplete overlay,
      // otherwise overlay will remain in DOM
      overlayRef.backdropClick().subscribe(() => setTimeout(() => ref.close(), 0));
    }

    return ref;
  }

  private _createInjector<Type>(ref: PhOverlayRef<Type>, data: PhOverlayData): Injector {
    return Injector.create({
      providers: [
        { provide: PH_OVERLAY_DATA, useValue: data },
        { provide: PhOverlayRef, useValue: ref },
      ],
    });
  }
}
