import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SaveAncillaryEContractRequest } from '@ph-model/request/save-ancillary-econtract';
import {
  GeneratePDFEContractRequest,
  SavePowerUpEContractRequest,
  UpdateStatusEContractRequest,
} from '@ph-model/request/save-power-up-econtract';
import {
  EContractSuccessResponse,
  GeneratePDFEContractResponse,
  StandardEContractResponse,
} from '@ph-model/response/e-contract.response';

@Injectable({ providedIn: 'root' })
export class EContractApiService {
  constructor(private httpClient: HttpClient) {}

  savePowerUpEContract(req: SavePowerUpEContractRequest): Observable<EContractSuccessResponse> {
    return this._eContractRequest<SavePowerUpEContractRequest, EContractSuccessResponse>('savePowerUpEContract', req);
  }

  saveAncillaryEContract(req: SaveAncillaryEContractRequest): Observable<EContractSuccessResponse> {
    return this._eContractRequest<SaveAncillaryEContractRequest, EContractSuccessResponse>(
      'saveAncillaryEContract',
      req
    );
  }

  updateStatusEContract(req: UpdateStatusEContractRequest): Observable<StandardEContractResponse> {
    return this._eContractRequest<UpdateStatusEContractRequest, StandardEContractResponse>(
      'updateStatusEContract',
      req
    );
  }

  generatePDFEContract(req: GeneratePDFEContractRequest): Observable<GeneratePDFEContractResponse> {
    return this._eContractRequest<GeneratePDFEContractRequest, GeneratePDFEContractResponse>(
      'generatePDFEContract',
      req
    ).pipe(catchError((error) => throwError(() => error)));
  }

  private _eContractRequest<T, U>(path: string, req: T): Observable<U> {
    const url = `${environment.apiUrl}/${path}`;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    return this.httpClient.post<U>(url, req, { headers }).pipe(catchError((err: HttpErrorResponse) => of(err.error)));
  }
}
