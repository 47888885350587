import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: () => 'errors.required',
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
