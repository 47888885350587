const QuoteCSSComponent = `
#logo {
    float: right;
    padding: 10px 15px 0px 0px;
}

.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    /*border: 1px solid #eee;
   */
    /*box-shadow: 0 0 10px rgba(0, 0, 0, .15);
   */
    font-size: 16px;
    line-height: 24px;
    font-family: "Playfair Display SC", serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 10px;
}

.information {
    border: 1px;
}

.invoice-box table tr.heading td {
    background: #ddd;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    margin-top: 20px;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 50%;
        display: block;
        text-align: center;
    }
}

.underline {
    text-decoration: underline;
}

.information table {
    border: 1px solid black;
    margin-bottom: 50px;
}

.information table td {
    width: 50%;
}

.information th {
    background: #ddd;
}

.quote {
    color: gray;
}

.dealerportal {
    width: 50%;
    max-width: 300px;
    margin-top: -40px;
    margin-bottom: 50px;
}

#basicTable {
    border-collapse: collapse;
}

#basicTable,
td,
th {
    border: 1px;
}`;

export { QuoteCSSComponent };
