import { createAction, props } from '@ngrx/store';

import { Options } from '@ph-core/enums';
import { DecodeVinResponse } from '@ph-model/api/response/decode-vin.response';
import { ManualResponse } from '@ph-model/api/response/manual.response';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { ProductFormResponse } from '@ph-model/api/response/product-form.response.model';
import { LoadQuote } from '@ph-model/cart/load-quote.model';
import { FinanceForm } from '@ph-model/form/finance-form';
import { IComplexDate } from '@ph-shared/utils';
import { ChargerForm, DOTForm, VehicleValues } from '@ph-store/vehicle/vehicle.reducer';

export const getProductForm = createAction('[VEHICLE] GET PRODUCT FORM');
export const getProductFormSuccess = createAction(
  '[VEHICLE] GET PRODUCT FORM SUCCESS',
  props<{ productFormResponse: ProductFormResponse }>()
);
export const resetProductForm = createAction('[VEHICLE] RESET PRODUCT');
export const decodeVin = createAction('[VEHICLE] DECODE VIN', props<{ vin: string }>());
export const decodeVinSuccess = createAction(
  '[VEHICLE] DECODE VIN SUCCESS',
  props<{ decodeVinResponse: DecodeVinResponse }>()
);
export const decodeVinFailure = createAction('[VEHICLE] DECODE VIN FAILURE', props<{ error: string }>());
export const getMake = createAction('[VEHICLE] GET MAKE', props<{ category: string; year: string }>());
export const getYear = createAction('[VEHICLE] GET YEAR', props<{ category: string }>());
export const getModel = createAction('[VEHICLE] GET MODEL', props<{ category: string; year: string; make: string }>());
export const getTrim = createAction(
  '[VEHICLE] GET TRIM',
  props<{ category: string; year: string; make: string; model: string }>()
);
export const updateChargerForm = createAction('[VEHICLE] UPDATE CHARGER FORM', props<{ values: ChargerForm }>());
export const updateDOTForm = createAction('[VEHICLE] UPDATE DOT FORM', props<{ values: DOTForm[] }>());
export const toggleChargerForm = createAction('[VEHICLE] TOGGLE CHARGER FORM', props<{ value: boolean }>());
export const toggleVehicleForm = createAction('[VEHICLE] TOGGLE VEHICLE FORM', props<{ value: boolean }>());
export const showInserviceDateAndOdo = createAction('[VEHICLE] SHOW INSERVICE DATE AND ODOMETER');
export const decodeVinManual = createAction('[VEHICLE] DECODE VIN MANUAL', props<{ manualResponse: ManualResponse }>());
export const updateProductForm = createAction(
  '[VEHICLE] UPDATE PRODUCT FORM',
  props<{ values: Partial<VehicleValues> }>()
);
export const updateFinanceForm = createAction(
  '[VEHICLE] UPDATE FINANCE FORM',
  props<{ values: Partial<FinanceForm> }>()
);
export const updateBorrowInfoForm = createAction(
  '[VEHICLE] UPDATE BORROW FORM',
  props<{
    DOBBorrower: IComplexDate;
    DOBCoBorrower: IComplexDate;
    coBorrower: Options;
    provinceBorrower: string;
    provinceCoBorrower: string;
  }>()
);
export const loadVehicleQuote = createAction('[VEHICLE] LOAD VEHICLE QUOTE', props<{ loadQuote: LoadQuote }>());
export const vehicleFailure = createAction('[VEHICLE] VEHICLE FAILURE', props<{ error: string }>());
export const getCustomerPostal = createAction(
  '[PRODUCT] GET CUSTOMER POSTAL',
  props<{ zipCode: string; values: Record<string, unknown> }>()
);
export const clearCustomerPostal = createAction('[PRODUCT] CLEAR CUSTOMER POSTAL');
export const getCustomerPostalSuccess = createAction(
  '[PRODUCT] GET CUSTOMER POSTAL SUCCESS',
  props<{ postal: PostalResponse }>()
);
export const setVehicleCondition = createAction(
  '[VEHICLE] SET VEHICLE CONDITION',
  props<{ isOldVehicleCondition: boolean }>()
);
export const setCreditStatus = createAction('[VEHICLE] SET_CREDIT_STATUS', props<{ isCreditAvailable: boolean }>());
export const setSaveCredit = createAction('[VEHICLE] SET_SAVE_CREDIT', props<{ isValidSaveCredit: boolean }>());
export const setSurcharges = createAction(
  '[VEHICLE] SET SURCHARGES',
  props<{ surcharges: { name: string; selected: boolean }[] }>()
);
export const clearSurcharges = createAction('[VEHICLE] CLEAR SURCHARGES');
export const getVehicleDetailsFromCarfax = createAction(
  '[VEHICLE] GET VEHICLE DETAILS FROM CARFAX',
  props<{ vin: string }>()
);
export const getVehicleDetailsFromCarfaxSuccess = createAction(
  '[VEHICLE] GET VEHICLE DETAILS FROM CARFAX SUCCESS',
  props<{ inServiceDate: IComplexDate }>()
);
