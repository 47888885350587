import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  input,
  InputSignal,
  Optional,
  output,
  OutputEmitterRef,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { RadioOption } from '@ph-model/form/radio-option';
import { FORM_ERRORS } from '@ph-shared/utils';

import { ControlErrorComponent } from '../control-error/control-error.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';

@Component({
  selector: 'ph-form-input-radio',
  templateUrl: 'form-input-radio.component.html',
  styleUrls: ['./form-input-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormControlLabelComponent, ControlErrorComponent],
  host: {
    '[class.ui-horizontal]': 'horizontal()',
  },
})
export class FormInputRadioComponent implements ControlValueAccessor {
  change: OutputEmitterRef<string> = output<string>();

  label: InputSignal<string> = input.required();
  options: InputSignal<RadioOption[]> = input.required();
  required: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  readOnly: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  initialSelection: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  horizontal: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });

  value: string = '';
  disabled: boolean = false;
  errorMessage: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(FORM_ERRORS) private _errors,
    @Self() @Optional() private control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched, errors } = this.control;
    if (errors) {
      if (errors.errorMessage) {
        this.errorMessage = errors.errorMessage;
      } else {
        const firstKey = Object.keys(errors)[0];
        const getError = this._errors[firstKey];
        this.errorMessage = getError ? getError(errors[firstKey]) : '';
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  writeValue(value: string): void {
    const { initialSelection, options } = this;

    this.value = value;
    this.cdr.markForCheck();

    let validValue = true;

    if (value && options().length) {
      validValue = !!options().find((option: RadioOption) => option.value === value);
    }

    if (initialSelection() && ((!value && options().length) || !validValue)) {
      this.updateValue(options()[0].value);
    }
  }

  registerOnChange(fn: (boolean) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  updateValue(val: string, event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.value = val;
    this.change.emit(val);
    this.cdr.markForCheck();
    this._onChange(this.value);
    this._onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private _onChange: (value: string) => void = () => undefined;
  private _onTouched: () => void = () => undefined;
}
