export class DealerInfo {
  constructor(
    public dealerName: string,
    public dealerCode: string,
    public programAgentCode: string,
    public dealerAddress: string,
    public dealerCity: string,
    public dealerState: string,
    public dealerZipCode: string,
    public dealerPhone: string
  ) {}
}
