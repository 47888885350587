import { PhOverlayRef } from '@ph-core/services/overlay';
import { PhNotificationComponent, PhOptionComponent } from '@ph-shared/components';

export interface PhNotificationData {
  message: string;
  title: string;
  innerHtml?: string;
}

export interface PhError extends PhNotificationData {
  status: string;
  show: boolean;
  duration?: number;
}

export interface PhNotificationAction {
  act?: (ref: PhOverlayRef<PhOptionComponent> | PhNotificationComponent) => void;
  text: string;
}

export enum PhNotificationType {
  submit = 'submit',
  open = 'open',
  info = 'info',

  inaudit = 'inaudit',

  approved = 'approved',
  credit = 'credit',

  cancel = 'cancel',
  denied = 'denied',
  noben = 'noben',

  inactive = 'inactive',
  closed = 'closed',
}

export interface PhNotificationItem {
  value: PhError | PhNotificationData;
  actions?: PhNotificationAction[];
  type: PhNotificationType;
  duration?: number;
  id?: string;
  wideView?: boolean;
}
