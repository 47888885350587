import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Claim } from '@ph-model/api/response/claims.response';
import { Filter } from '@ph-model/filters/filter';
import { SearchFilter } from '@ph-model/filters/search-filter';

import { SortingService } from './sorting.service';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

@Injectable({ providedIn: 'root' })
export class ClaimService {
  constructor(private httpClient: HttpClient) {}

  static fetchClaimGroup(claims: Claim[]): Filter[] {
    return claims
      .reduce((acc, claim) => {
        const date = new Date(claim.dateOfLoss);
        const year = date.getFullYear();
        const month = date.getMonth();

        if (!acc.some((bucket) => bucket.year === year && bucket.month === month)) {
          acc.push({ year, month });
        }

        return acc;
      }, [])
      .sort((a, b) => (b.year - a.year ? b.year - a.year : b.month - a.month))
      .map((bucket) => new Filter(MONTH_NAMES[bucket.month] + ' ' + bucket.year));
  }

  static fetchStatus(contracts: Claim[]): Filter[] {
    const status = contracts.reduce((prevVal, elem: Claim) => {
      const index = prevVal.some((item) => item.name === elem.status);

      if (!index) {
        prevVal.push(new Filter(elem.status));
      }

      return prevVal;
    }, []);

    return SortingService.sortStrings(status);
  }

  static fetchFilters(claims: Claim[]): SearchFilter {
    if (claims) {
      const month: Filter[] = this.fetchClaimGroup(claims);
      const status: Filter[] = this.fetchStatus(claims);

      return new SearchFilter(month, status);
    }
  }

  ////////// STATIC FILTER CONTRACTS ////////////////////////////////////////////////////////
  static filter(filters: SearchFilter, claims: Claim[]) {
    let filteredClaims = this.filterByGroup(filters, claims);
    filteredClaims = this.filterByStatus(filters, filteredClaims);
    filteredClaims = this.filterBySearchPhase(filters.searchPhrase, filteredClaims);

    return filteredClaims;
  }

  static filterByGroup(claimsFilter: SearchFilter, claims: Claim[]): Claim[] {
    // get changed claims
    let allClaims = true;

    // Filter claims by claim month
    const filteredClaims = claims.filter((data) => {
      const index = claimsFilter.month.findIndex((x) => {
        const date = new Date(data.dateOfLoss);
        const year = date.getFullYear();
        const month = date.getMonth();
        const claimLabel = MONTH_NAMES[month] + ' ' + year;

        return x.name === claimLabel;
      });
      if (index > -1) {
        // uncheck allClaims button
        if (claimsFilter.month[index].selected) {
          allClaims = false;
        }

        return claimsFilter.month[index].selected;
      } else {
        return true;
      }
    });

    if (allClaims) {
      return claims;
    }

    return filteredClaims;
  }

  static filterBySearchPhase(searchText: string, claims: Claim[]): Claim[] {
    if (!searchText) {
      return claims;
    }

    return claims.filter((claim: Claim) => {
      return (
        claim.customer.custName.toLowerCase().includes(searchText.toLowerCase()) ||
        claim.vehicle.vinNumber.toLowerCase().includes(searchText.toLowerCase()) ||
        claim.claimNumber.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  static filterByStatus(claimsFilter: SearchFilter, claims: Claim[]): Claim[] {
    // get changed product filters
    let allClaims = true;

    // reset term filter display
    claimsFilter.status.forEach((_: Filter, index: number) => {
      claimsFilter.status[index].display = false;
    });

    // Filter products by status
    const filteredClaims = claims.filter((data) => {
      //  const statusName = this.getStatusName(data.status);
      const index = claimsFilter.status.findIndex((x) => x.name === data.status);
      if (index > -1) {
        // uncheck allProduct button
        if (claimsFilter.status.some(({ selected }) => selected)) {
          allClaims = false;
        }
        claimsFilter.status[index].display = true;

        return claimsFilter.status[index].selected;
      } else {
        return true;
      }
    });

    if (allClaims) {
      return claims;
    }

    return filteredClaims;
  }

  generateClaimPDF(claimNumber: string): Observable<Blob> {
    return this.httpClient
      .get(`${environment.apiUrl}/claims/${claimNumber.trim()}/document`, { responseType: 'blob' as 'json' })
      .pipe(
        map((res: Blob) => new Blob([res], { type: 'application/pdf' })),
        tap((data: Blob) => window.open(URL.createObjectURL(data)))
      );
  }
}
