import { Component, effect, ElementRef, input, InputSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { PrismicIcons } from '@ph-model/api/response/prismic-response.model';
import { selectBrandIcons } from '@ph-store/prismic/prismic.selectors';

import { IconName, ICONS } from './icons';

@Component({
  selector: 'ph-icon',
  template: '<ng-template />',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
})
export class IconComponent {
  name: InputSignal<IconName> = input.required();
  width: InputSignal<string> = input('');
  height: InputSignal<string> = input('');
  color: InputSignal<string> = input('');

  private _icons!: PrismicIcons;

  constructor(
    private element: ElementRef,
    private store: Store
  ) {
    effect(() => {
      if (this.name()) {
        this.element.nativeElement.innerHTML = (this._icons && this._icons[this.name()]) || ICONS[this.name()] || '?';
      }

      if (this.width()) {
        this._setProperty('--width', this.width());
      }

      if (this.height()) {
        this._setProperty('--height', this.height());
      }

      if (this.color()) {
        this._setProperty('--fill-color', this.color());
      }
    });

    this.store
      .select(selectBrandIcons)
      .pipe(
        filter((icons) => !!icons),
        tap((icons: PrismicIcons) => (this._icons = icons)),
        catchError((error: Error) => {
          this._icons = ICONS;
          console.error(error);

          return throwError(() => error);
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  private _setProperty(name: string, value): void {
    this.element.nativeElement.style.setProperty(name, value);
  }
}
