export class VehicleDetails {
  constructor(
    public vin: string,
    public odometer: number|string,
    public vehicleCondition: string,
    public vehicleMsrp: number|string,
    public vehicleCost: number|string,
    public vehiclePurchasePrice: number|string,
    public inServiceDate: string,
    public year: string,
    public model: string,
    public make: string
  ) {}
}
