import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CartState, featureKey } from './cart.reducer';

export const selectCartState = createFeatureSelector<CartState>(featureKey);

export const selectCheckoutForms = createSelector(selectCartState, (state) => state.checkoutForms);
export const selectCheckoutFormValues = createSelector(selectCartState, (state) => state.checkoutFormValues);
export const selectCartTaxFormValues = createSelector(selectCartState, (state) => state.cartTaxFormValues);
export const selectCart = createSelector(selectCartState, (state) => state.cart);
export const selectValidatedCart = createSelector(selectCartState, (state) => state.validatedCart);
export const selectValidatedCartEmpty = createSelector(
  selectCartState,
  (state) => !state.validatedCart || state.validatedCart.length === 0
);
export const selectCartTotal = createSelector(selectCartState, (state) => state.cartTotal);
export const selectCheckoutFinanceForm = createSelector(selectCheckoutFormValues, (forms) => forms.financeForm);
export const selectFinanceMonthlyPayment = createSelector(selectCheckoutFinanceForm, (form) =>
  Number(form.monthlyPayment)
);
export const selectFinanceFinanceType = createSelector(selectCheckoutFinanceForm, (form) => form.financeType);
export const selectAllCartItemsValid = createSelector(selectCartState, (state) =>
  state.validatedCart.every((item) => item.valid && item.formValid)
);
export const selectCreditInsuranceForm = createSelector(
  selectCheckoutFormValues,
  (formValues) => formValues.creditInsuranceForm
);
export const selectRequestedQuote = createSelector(selectCartState, (state) => state.requestedQuote);
export const selectLoanProtectionCoverageOptions = createSelector(
  selectCartState,
  (state) => state.loanProtectionCoverageOptions
);
export const selectCreditInsurancePackageCoverageOptions = createSelector(
  selectCartState,
  (state) => state.creditInsurancePackageCoverageOptions
);
export const selectCheckoutCartContainsError = createSelector(
  selectCartState,
  (state) => state.checkoutCartContainsError
);
