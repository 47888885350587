import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { computed, Directive, ElementRef, HostListener, input, InputSignal, Signal } from '@angular/core';

import { PhConnectedPosition, PhOverlay, PhOverlayPositionBuilder, PhOverlayRef } from '@ph-core/services/overlay';
import { untilDestroyed } from '@ph-shared/utils';

import { PhTooltipComponent } from './tooltip.component';
import { PhTooltipOptions } from './tooltip.interface';

export const PH_DEFAULT_TOOLTIP_OPTIONS: PhTooltipOptions = {
  position: ['above', 'below', 'after', 'before'],
  component: PhTooltipComponent,
};

@Directive({
  selector: '[phTooltip], [phTooltipStandalone]',
  standalone: true,
})
export class PhTooltipDirective {
  message: InputSignal<string> = input('', { alias: 'phTooltip' });
  position: InputSignal<PhConnectedPosition[] | PhConnectedPosition | string[] | string> = input('', {
    alias: 'phTooltipPosition',
  });
  phTooltipOptions: InputSignal<PhTooltipOptions> = input();
  phTooltipStandalone: InputSignal<boolean | string> = input(true, { transform: coerceBooleanProperty });

  private options: Signal<PhTooltipOptions> = computed(() => ({
    ...PH_DEFAULT_TOOLTIP_OPTIONS,
    ...this.phTooltipOptions(),
  }));

  private tooltipRef: Signal<PhOverlayRef<unknown>> = computed(() => {
    if (this.phTooltipOptions()) {
      return this._createTooltip();
    }
  });

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private overlay: PhOverlay,
    private origin: ElementRef,
    private pb: PhOverlayPositionBuilder
  ) {}

  @HostListener('click') click(): void {
    this.tooltipRef().toggle({ message: this.message() });
  }

  private _createTooltip(): PhOverlayRef<unknown> {
    const tooltipRef = this.overlay.create(this.options().component, {
      position: this.pb
        .connectedPosition(this.origin, this.position() || this.options().position)
        .withFlexibleDimensions(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition({ autoClose: true }),
      data: this.options().data,
      backdropClass: '',
      clickableBackdrop: true,
      hasBackdrop: true,
      reusable: true,
    });

    tooltipRef
      .afterClosed()
      .pipe(this.#untilDestroyed())
      .subscribe((value: unknown) => {
        const { afterClosed } = this.options();

        afterClosed && afterClosed(value);
      });

    return tooltipRef;
  }
}
