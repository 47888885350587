import { tireProductCodes } from '@ph-static-data/tire-product-codes';

import { FinanceType, Options, VehicleConditionType } from '@ph-core/enums';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { SubmitCartModel } from '@ph-model/cart/submit-cart.model';
import { DealerInfo as DealerInformation } from '@ph-model/login';
import { RequestBase } from '@ph-model/request/request-base';
import {
  CustomerInfo,
  DealerInfo,
  Dot,
  FinanceInfo,
  LienholderInfo,
  ProductInfo,
  Surcharge,
  TaxInfo,
  VehicleDetails,
} from '@ph-model/request/save-ancillary-econtract';
import { SurchargeOption } from '@ph-model/response/interface';
import { parseToInteger, parseToNumber } from '@ph-shared/utils';
import * as VehicleReducer from '@ph-store/vehicle/vehicle.reducer';

export class SaveAncillaryEContractRequest {
  constructor(
    public apiVersion: string,
    public languageCode: string,
    public customerInfo: CustomerInfo,
    public coSignerInfo: CustomerInfo,
    public dealerInfo: DealerInfo,
    public lienholderInfo: LienholderInfo,
    public vehicleDetails: VehicleDetails,
    public finance: FinanceInfo,
    public taxes: TaxInfo[],
    public surchargeOptions: Surcharge[],
    public productInfo: ProductInfo,
    public dot: Dot,
    public source: RequestBase,
    public convertQuoteToContract: boolean,
    public theftRegistrationCode: string
  ) {}

  static generateRequest(
    submitCart: SubmitCartModel,
    cartItem: CartItem,
    vehicleState: VehicleReducer.VehicleState,
    dealer: string,
    dealerInfoData: DealerInformation,
    indexOfTireContract: number | null
  ): SaveAncillaryEContractRequest {
    const {
      firstName,
      lastName,
      middleInitial,
      businessName,
      businessFlag,
      address,
      suiteApt,
      city,
      stateProvince,
      postalCode,
      phone1,
      phone2,
      email,
      country,
    } = submitCart.customerForm;

    const {
      firstName: cosignerFirstName,
      lastName: cosignerLastName,
      middleInitial: cosignerMiddleName,
      address: cosignerAddress1,
      suiteApt: cosignerAddress2,
      city: cosignerCity,
      stateProvince: cosignerState,
      postalCode: cosignerZipcode,
      phone1: cosignerHomePhone,
      phone2: cosignerWorkPhone,
      email: cosignerEmail,
      country: cosignerCountryCode,
    } = submitCart.cosignerForm;

    const {
      name: lienholderName,
      address1: lienholderAddress1,
      address2: lienholderAddress2,
      city: lienholderCity,
      stateProvince: lienholderState,
      postalCode: lienholderZipCode,
      phone: lienholderPhone,
      fax: lienholderFax,
      email: lienholderEmail,
      countryCode: lienholderCountryCode,
    } = submitCart.lienholderForm.lienholder || {};

    const {
      formNumber,
      productCode,
      coverageCode,
      customerTerm,
      customerCost,
      deductible,
      termMileage,
      dealerCost,
      planSKU,
      planCode,
      usageType,
      productType,
      planName,
      riskType,
      surchargeOptions,
    } = cartItem.product;

    const {
      dealerAddress1,
      dealerCity,
      dealerPhone,
      dealerState,
      dealerZipCode,
      agentCode, //programAgentCode
      externalDealershipId,
      dealerName,
    } = dealerInfoData;

    const {
      vin,
      hin,
      pid,
      tin,
      saleDate,
      inServiceDate,
      year,
      make,
      model,
      odometer,
      msrp,
      vehicleCondition,
      financeType,
      grossCapCost,
      apr,
      registrationNumber,
      financeAmount,
      purchasePrice,
    } = vehicleState.values;

    const { dot, rimSize } = vehicleState.dotInfo?.dotForm[indexOfTireContract] || {};

    const vinValue: string = vin || hin || pid || tin;
    const saleDateValue: string = saleDate?.formatted.replace(/\//g, '-');

    let inServiceDateValue: string;
    if (inServiceDate) {
      inServiceDateValue = inServiceDate.formatted.replace(/\//g, '-');
    } else {
      const currentDate = new Date();

      if (year >= currentDate.getFullYear().toString()) {
        inServiceDateValue = saleDateValue;
      } else {
        inServiceDateValue = `${currentDate.getFullYear()}-01-01`;
      }
    }
    let dealerCostWOSurcharges: number = dealerCost;
    const surcharges: Surcharge[] = [];
    surchargeOptions.surchargeOption.forEach((s: SurchargeOption) => {
      if (s.added) {
        // Subtract Surcharges from dealerCost due to BE issue with contract cost validation
        dealerCostWOSurcharges -= parseToInteger(s.surchargeAmount);
        surcharges.push(new Surcharge(s.key, parseToNumber(s.surchargeAmount), s.type, s.description, s.mandatory));
      }
    });

    const isBusiness = (businessFlag as Options) === Options.YES;

    const customerInfo = new CustomerInfo(
      isBusiness ? '_' : firstName,
      isBusiness ? businessName : lastName,
      isBusiness ? '' : middleInitial,
      businessName || '',
      phone1 || '',
      phone2 || '',
      email || '',
      address || '',
      suiteApt || '',
      city || '',
      stateProvince || '',
      postalCode || '',
      country.slice(0, 2) || ''
    );

    const hasCoBorrower = vehicleState.values.coBorrower === Options.YES;

    const coSignerInfo = hasCoBorrower
      ? new CustomerInfo(
          cosignerFirstName || '',
          cosignerLastName || '',
          cosignerMiddleName || '',
          businessName || '',
          cosignerHomePhone || '',
          cosignerWorkPhone || '',
          cosignerEmail || '',
          cosignerAddress1 || '',
          cosignerAddress2 || '',
          cosignerCity || '',
          cosignerState || '',
          cosignerZipcode || '',
          cosignerCountryCode?.slice(0, 2) || ''
        )
      : null;
    const dealerInfo = new DealerInfo(
      dealerName || '',
      externalDealershipId || '',
      agentCode || '',
      dealerAddress1 || '',
      dealerCity || '',
      dealerState || '',
      dealerZipCode || '',
      dealerPhone || ''
    );
    const lienholderInfo =
      vehicleState.values.financeType !== FinanceType.Cash
        ? new LienholderInfo(
            lienholderName || '',
            lienholderAddress1 || '',
            lienholderAddress2 || '',
            lienholderCity || '',
            lienholderState?.slice(0, 2) || '',
            lienholderZipCode?.replace(/ +/g, '') || '',
            lienholderCountryCode || '',
            lienholderEmail || '',
            lienholderFax || '',
            lienholderPhone || '',
            ''
          )
        : null;
    const vehicleDetails = new VehicleDetails(
      vinValue || '',
      parseToNumber(odometer) || 0,
      vehicleCondition || VehicleConditionType.USED,
      parseToNumber(msrp) || 0,
      parseToNumber(purchasePrice),
      parseToNumber(purchasePrice),
      inServiceDateValue || '',
      year || '',
      model || '',
      make || ''
    );
    const finance = new FinanceInfo(
      customerTerm || 0,
      parseToNumber(purchasePrice),
      dealerCostWOSurcharges || 0,
      parseToNumber(msrp) || 0,
      parseToNumber(deductible),
      (financeType as string) || '',
      parseToNumber(grossCapCost),
      'USD',
      customerCost || 0,
      Number(apr || null),
      (financeType as string) || '',
      parseToNumber(financeAmount)
    );
    const productInfo = new ProductInfo(
      planSKU || '',
      customerTerm || null,
      parseToNumber(termMileage),
      productCode || '',
      planCode || '',
      planName?.enUsPlanName || '',
      riskType || '',
      coverageCode || '',
      dealerCostWOSurcharges || null,
      saleDateValue || '',
      customerCost || 0,
      formNumber || '',
      usageType || '',
      productType || '',
      '', // optional field
      '', // optional field
      '', // optional field
      '', // optional field
      '', // optional field
      deductible || ''
    );

    const dotData =
      dot?.length && tireProductCodes.includes(cartItem.product.productCode)
        ? new Dot(parseToInteger(rimSize), dot?.length, dot)
        : null;

    return new SaveAncillaryEContractRequest(
      '1.0',
      'en_US',
      customerInfo,
      coSignerInfo || ({} as CustomerInfo),
      dealerInfo,
      lienholderInfo || ({} as LienholderInfo),
      vehicleDetails,
      finance,
      [],
      surcharges,
      productInfo,
      dotData,
      new RequestBase(),
      false,
      registrationNumber || ''
    );
  }
}
