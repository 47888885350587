import { Options } from '@ph-core/enums';
import { Lienholder } from '@ph-model/checkout/submit_cart/response/lienholder';
import { FinanceForm } from '@ph-model/form/finance-form';
import { IComplexDate } from '@ph-shared/utils';
import { BorrowInfoForm } from '@ph-store/vehicle/vehicle.reducer';

export interface CreditInsuranceForm {
  addInsurance: string;
  loanTerm: number;
  loanAmount: number;
  monthlyPayment: string;
  interestRate: number;
  amortizationPeriod: number;
  residualValue: string;
  loanType?: string;
  loanEndDate?: string;
  downPayment?: string;
  firstPaymentDate?: IComplexDate;
  coMonthlyPayment?: string;
  primaryMonthlyPayment?: string;
}

export interface CustomerInfo {
  businessFlag?: Options;
  businessName?: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  address: string;
  suiteApt: string;
  postalCode: string;
  city: string;
  country: string;
  stateProvince: string;
  phone1: string;
  phone2: string;
  email: string;
  customerAgreementLanguage?: string;
  cosignerFlag?: Options;
}

export interface VehicleInfo {
  msrp: string;
  apr: string;
  financeAmount: string;
  registrationNumber: string;
  purchasePrice: string;
  grossCapCost?: string;
}

export interface CoverageInfoForm {
  coverageSelection: string;
  borrowerCoverage: string;
  insuranceTerm?: number;
}

export class CheckoutFormValues {
  customer: CustomerInfo;
  lienholder: Lienholder;
  creditInsurance: CreditInsuranceForm;
  creditInsuranceForm: CreditInsuranceForm;
  borrowInfoForm: BorrowInfoForm;
  coverageInfoForm: CoverageInfoForm;
  financeForm: FinanceForm;
  cosigner: CustomerInfo;
  vehicle: VehicleInfo;
  customerFormValid: boolean;
  cosignerFormValid: boolean;
  vehicleFormValid: boolean;
  lienholderFormValid: boolean;
  borrowerInfoFormValid: boolean;
  creditInsuranceFormValid: boolean;
  coverageInfoFormFormValid: boolean;
  financeFormValid: boolean;

  constructor() {
    this.customer = {} as CustomerInfo;
    this.cosigner = {} as CustomerInfo;
    this.vehicle = {
      msrp: '',
      grossCapCost: '',
      apr: '',
      financeAmount: '',
      registrationNumber: '',
      purchasePrice: '',
    };
    this.lienholder = {} as Lienholder;
    this.creditInsuranceForm = {
      addInsurance: Options.NO,
      loanTerm: null,
      loanAmount: null,
      monthlyPayment: null,
      interestRate: null,
      amortizationPeriod: null,
      residualValue: null,
    };
    this.borrowInfoForm = {} as BorrowInfoForm;
    this.coverageInfoForm = {
      coverageSelection: '',
      borrowerCoverage: '',
    };
    this.financeForm = {} as FinanceForm;
    this.customerFormValid = false;
    this.cosignerFormValid = false;
    this.vehicleFormValid = false;
    this.lienholderFormValid = false;
    this.borrowerInfoFormValid = false;
    this.creditInsuranceFormValid = false;
    this.coverageInfoFormFormValid = false;
    this.financeFormValid = false;
  }
}
