import { Directive, HostListener, input, InputSignal } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ProductItem } from '@ph-model/response';

@Directive({
  selector: '[appTermValidation]',
  standalone: true,
})
export class TermValidationDirective {
  product: InputSignal<ProductItem> = input.required();

  constructor(private control: NgControl) {}

  @HostListener('blur', ['$event']) onBlurEvent(): void {
    const value = this.control.value;
    if (+this.product().maxTerm < value) {
      this.control.control.setValue(+this.product().maxTerm);
    } else if (+this.product().minTerm > value) {
      this.control.control.setValue(+this.product().minTerm);
    }

    // validate errorFlag after resetting control value
    this.onInputEvent();
  }

  @HostListener('input', ['$event']) onInputEvent(): void {
    this.product().errorFlag = false;
    const value = this.control.value;
    if (+this.product().maxTerm < value) {
      this.product().errorFlag = true;
    } else if (+this.product().minTerm > value) {
      this.product().errorFlag = true;
    }
  }
}
