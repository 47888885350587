import { createReducer, on } from '@ngrx/store';

import { filterProducts } from '@ph-core/utils/filter-products';
import { ProductFilters, productFiltersInitialState } from '@ph-model/filters/product-filters';
import { SurchargeSelectionItem } from '@ph-model/filters/surcharge-selection-item';
import { Markup } from '@ph-model/login';
import { GetProductsRequest } from '@ph-model/request/product.request';
import { GetProductsResponse, ProductItem } from '@ph-model/response';
import { productIdGenerator } from '@ph-shared/utils';

import {
  applySurchargesSelection,
  changeMarkup,
  applyFilters,
  getAncillaryProducts,
  getPowerUpAndAncillaryProducts,
  getPowerUpProducts,
  getProductsFailure,
  getProductsSuccess,
  loadProductQuote,
  manualCustomerCostOverride,
  resetProducts,
  updateGrid,
} from './product.actions';
import { recalculateCustomerCost } from './product.helpers';

export const featureKey = 'productState';

export interface ProductState {
  productsResponse: GetProductsResponse;
  productsRequest: GetProductsRequest;
  allProducts: ProductItem[];
  visibleProducts: ProductItem[];
  filters: ProductFilters;
  surcharges: SurchargeSelectionItem[];
  markup: Markup;
  error: string;
  successful: boolean;
  sorted: boolean;
}

const InitialState: ProductState = {
  productsResponse: undefined,
  markup: new Markup(),
  productsRequest: null,
  allProducts: [],
  visibleProducts: [],
  filters: productFiltersInitialState,
  surcharges: [],
  error: '',
  successful: false,
  sorted: false,
};

export const reducer = createReducer(
  InitialState,
  on(
    getAncillaryProducts,
    (state, { request }): ProductState => ({
      ...state,
      productsRequest: request,
      error: undefined,
    })
  ),
  on(
    getPowerUpProducts,
    (state): ProductState => ({
      ...state,
      productsRequest: {} as GetProductsRequest,
      error: undefined,
    })
  ),
  on(
    getPowerUpAndAncillaryProducts,
    (state): ProductState => ({
      ...state,
      productsRequest: {} as GetProductsRequest,
      error: undefined,
    })
  ),
  on(
    getProductsSuccess,
    (state, { response, products, markup, filters }): ProductState => ({
      ...state,
      productsResponse: response,
      allProducts: products,
      visibleProducts: products.map((product) => ({ ...product })),
      filters: filters,
      markup: markup,
      error: undefined,
      successful: true,
      sorted: false,
    })
  ),
  on(
    getProductsFailure,
    (state, { error }): ProductState => ({
      ...state,
      productsResponse: undefined,
      error,
      successful: false,
      visibleProducts: [],
    })
  ),
  on(changeMarkup, (state, { markup }): ProductState => {
    const recalculatedProducts = state.allProducts
      ? recalculateCustomerCosts(state.allProducts, state.surcharges, markup)
      : state.allProducts;

    return {
      ...state,
      markup,
      allProducts: recalculatedProducts,
      visibleProducts: filterProducts(state.filters, recalculatedProducts),
    };
  }),
  on(applyFilters, (state, { filters }): ProductState => {
    return {
      ...state,
      visibleProducts: filterProducts(filters, state.allProducts),
      filters,
      sorted: false,
    };
  }),
  on(
    updateGrid,
    (state, { product, delta }): ProductState => ({
      ...state,
      visibleProducts: [
        ...state.visibleProducts.map((elem: ProductItem) =>
          productIdGenerator(elem) === productIdGenerator(product)
            ? {
                ...elem,
                ...delta,
              }
            : elem
        ),
      ],
    })
  ),
  on(
    loadProductQuote,
    (state, { response, gridProducts, filters, markup, request }): ProductState => ({
      ...state,
      productsResponse: response,
      visibleProducts: gridProducts,
      allProducts: gridProducts,
      filters: filters,
      markup: markup,
      productsRequest: request,
      successful: true,
    })
  ),
  on(
    resetProducts,
    (): ProductState => ({
      productsResponse: undefined,
      markup: new Markup(),
      productsRequest: null,
      visibleProducts: undefined,
      allProducts: undefined,
      filters: productFiltersInitialState,
      error: '',
      surcharges: undefined,
      successful: false,
      sorted: false,
    })
  ),
  on(applySurchargesSelection, (state, { surchargesSelection }): ProductState => {
    const allProducts = state.allProducts?.map((product) => ({ ...product, isAddedToCart: false })) || [];
    const productsWithSurchargesApplied = recalculateCustomerCosts(allProducts, surchargesSelection, state.markup);

    return {
      ...state,
      allProducts: productsWithSurchargesApplied,
      visibleProducts: filterProducts(state.filters, productsWithSurchargesApplied),
      surcharges: surchargesSelection,
    };
  }),
  on(manualCustomerCostOverride, (state, { product, customerCost }): ProductState => {
    const updatedProducts = state.allProducts.map((productItem) => {
      if (productIdGenerator(productItem) === productIdGenerator(product)) {
        const manualCustomerCostDelta = productItem.manualCustomerCostDelta + +customerCost - productItem.customerCost;
        const updatedCustomerCost = recalculateCustomerCost(
          { ...product, manualCustomerCostDelta },
          state.markup,
          state.surcharges
        ).customerCost;

        return {
          ...product,
          manualCustomerCostDelta,
          customerCost: updatedCustomerCost,
        };
      } else {
        return productItem;
      }
    });

    return {
      ...state,
      allProducts: updatedProducts,
      visibleProducts: filterProducts(state.filters, updatedProducts),
    };
  })
);

function recalculateCustomerCosts(
  productItems: ProductItem[],
  surchargesSelection: SurchargeSelectionItem[],
  markup: Markup
) {
  return productItems.map((product) => recalculateCustomerCost(product, markup, surchargesSelection));
}
