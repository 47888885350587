import { ValidationErrors } from '@angular/forms';

import { v4 as uuid } from 'uuid';

import { ProductItem } from '@ph-model/response';

import { Field } from '../form/field';

export class CartItem {
  product: ProductItem;
  key: string;
  valid: boolean;
  message: string;
  customerTaxForm: Field[];
  dealerTaxForm: Field[];
  formValid: boolean;
  errorType: 'DUPL' | 'ECON' | 'UNAV' | 'MULTI' | 'COST' | 'CREATE-CONTRACT-FAILURE' | '';
  errors: ValidationErrors | null | undefined;
  // customerTaxValues: Record<string, unknown>;
  // warning: Warning[];
  // containsWarning: boolean;
  // code: string;

  constructor(product: ProductItem) {
    this.product = product;
    this.valid = true;
    this.customerTaxForm = [];
    this.dealerTaxForm = [];
    this.key = uuid();
    this.message = '';
    this.formValid = true;
    this.errorType = '';
    // this.code = '';
    // this.customerTaxValues = {};
  }
}
