import { ChangeDetectionStrategy, Component, input, InputSignal, output, OutputEmitterRef } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'ph-option',
  template: `
    <div class="option" (click)="fireClickEvent()">
      <ng-content />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./ph-option.component.scss'],
  standalone: true,
})
export class PhOptionComponent {
  optionClicked: OutputEmitterRef<void> = output();

  value: InputSignal<string> = input('');

  click$: Subject<string> = new Subject<string>();

  fireClickEvent(): void {
    this.optionClicked.emit();
    this.click$.next(this.value());
  }
}
