import { RequestBase } from '@ph-model/request/request-base';

import { SupportedLanguageCodes } from "../../../static-data/languages";

export class GeneratePDFEContractRequest {
  constructor(
    public contractID: string,
    // TODO update prop name once API will be ready to accept lang
    public language?: SupportedLanguageCodes,
    public source: RequestBase = new RequestBase()) {
  }
}
