@if (viewData$ | async; as viewData) {
  <div class="operation-history">
    <div class="operation-history-title">{{ 'contractHistory.header' | translate }}</div>
    @if (viewData.loading) {
      <div class="loading-container">
        <app-spinner />
      </div>
      <small class="footer-message">{{ 'contractHistory.waitWhileLoading' | translate }}</small>
    } @else {
      @if (viewData.error) {
        <ng-container
          [ngTemplateOutlet]="statusMessage"
          [ngTemplateOutletContext]="{ message: 'contractHistory.loadingError' }"
        />
      } @else {
        @if (viewData.activityHistory?.length) {
          <div class="operations-list">
            @for (record of viewData.activityHistory | operationsByDate; track record) {
              <div class="operation-container">
                <app-contract-operation-record [record]="record" />
              </div>
            }
          </div>
        } @else {
          <ng-container
            [ngTemplateOutlet]="statusMessage"
            [ngTemplateOutletContext]="{ message: 'contractHistory.noRecords' }"
          />
        }
      }
    }
  </div>
}

<ng-template #statusMessage let-message="message">
  <div class="operation-history-status-container">
    <div class="operation-history-status">{{ message | translate }}</div>
  </div>
</ng-template>
