import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffix',
  standalone: true,
})
export class UsernameSuffixPipe implements PipeTransform {
  transform(text: string, suffix: string): string {
    if (!suffix || !text) {
      return text;
    } else {
      return text.replace(suffix, '');
    }
  }
}
