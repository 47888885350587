import { ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, input, InputSignal, OnInit, ViewContainerRef } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

import { fromEvent, Observable, race } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { untilDestroyed } from '@ph-shared/utils';

import { PhAutocompleteComponent } from './ph-autocomplete.component';

@Directive({
  selector: '[phAutocomplete]',
  standalone: true,
})
export class PhAutocompleteDirective implements OnInit {
  phAutocomplete: InputSignal<PhAutocompleteComponent> = input.required();

  private _overlayRef: OverlayRef;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private ngControl: NgControl,
    private vcr: ViewContainerRef,
    private overlay: Overlay
  ) {}

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  get origin(): HTMLInputElement {
    return this.host.nativeElement;
  }

  ngOnInit(): void {
    fromEvent(this.origin, 'keyup')
      .pipe(
        debounceTime(300),
        switchMap(() => {
          if (this._overlayRef) {
            this._close();
          }
          this._overlayRef = this.overlay.create({
            width: this.origin.offsetWidth,
            maxHeight: 170,
            backdropClass: '',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this._getOverlayPosition(),
          });

          this._overlayRef.attach(new TemplatePortal(this.phAutocomplete().rootTemplate(), this.vcr));

          return race(
            this.phAutocomplete().optionsClick().pipe(takeUntil(this._overlayRef.detachments())),
            overlayClickOutside(this._overlayRef, this.origin)
          );
        }),
        tap((value: string) => {
          if (value) {
            this.control.setValue(value);
          }
          this._close();
        }),
        this.#untilDestroyed()
      )
      .subscribe();
  }

  private _close(): void {
    this._overlayRef.detach();
    this._overlayRef = null;
  }

  private _getOverlayPosition(): FlexibleConnectedPositionStrategy {
    const positions = [
      new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);
  }
}

export function overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement): Observable<string> {
  return fromEvent<MouseEvent>(document, 'click').pipe(
    filter((event: MouseEvent) => {
      const clickTarget = event.target as HTMLElement;
      const notOrigin = clickTarget !== origin;
      const notOverlay = !!overlayRef && overlayRef.overlayElement.contains(clickTarget) === false;

      return notOrigin && notOverlay;
    }),
    map(() => ''),
    takeUntil(overlayRef.detachments())
  );
}
