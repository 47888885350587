import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ph-form-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class ControlErrorComponent {
  errorMessage: InputSignal<string> = input.required();
}
