import { UserResponse } from '@ph-model/login';
import { GetProductsRequest } from '@ph-model/request/product.request';

import { CartItem } from './cart-item.model';

export class CartTax {
  userName: string;
  cmsDealerNumber: string;
  productForm: GetProductsRequest;
  cart: CartItem[];

  constructor(user: UserResponse = new UserResponse(), cart: CartItem[] = [], productForm?: GetProductsRequest) {
    this.userName = user.userName;
    this.cmsDealerNumber = user.cmsDealerNumber;
    this.productForm = productForm;
    this.cart = cart;
  }
}
