import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  HostListener,
  Inject,
  input,
  InputSignal,
  Optional,
  output,
  OutputEmitterRef,
  Self,
  Signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { DateMaskDirective } from '@ph-shared/directives';
import { ControlDateFormatter, FORM_ERRORS, IComplexDate, PhDateAdapter, untilDestroyed } from '@ph-shared/utils';

import { ControlErrorComponent } from '../control-error/control-error.component';
import { FormControlLabelComponent } from '../form-control-label/form-control-label.component';
import { FormInputComponent } from '../form-input/form-input.component';

@Component({
  selector: 'ph-datepicker-control',
  templateUrl: 'datepicker-control.component.html',
  styleUrls: ['./datepicker-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ControlErrorComponent,
    DateMaskDirective,
    FormControlLabelComponent,
    FormInputComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: DateAdapter, useClass: PhDateAdapter }],
})
export class DatepickerControlComponent implements ControlValueAccessor {
  dateChange: OutputEmitterRef<IComplexDate> = output<IComplexDate>();

  input: Signal<ElementRef> = viewChild.required('input', { read: ElementRef });
  label: InputSignal<string> = input.required();
  placeholder: InputSignal<string> = input.required();
  required: InputSignal<boolean | string> = input(false, { transform: coerceBooleanProperty });
  inactive: InputSignal<boolean> = input(false);
  dateRange: InputSignal<number[]> = input([]);

  date: FormControl = new FormControl();
  disabled: boolean = false;
  errorMessage: string = '';
  minDate: Date = this._addDays(-36500);
  maxDate: Date = new Date();

  private _value: IComplexDate | null;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(FORM_ERRORS) private _errors,
    @Self() @Optional() private control: NgControl
  ) {
    this.control.valueAccessor = this;

    effect(() => {
      if (this.dateRange()) {
        this.minDate = this._addDays(-this.dateRange()[0]);
        this.maxDate = this._addDays(this.dateRange()[1]);
      }

      if (this.input()) {
        this.input().nativeElement.value = this._value ? this._value.formattedUS : '';

        fromEvent(this.input().nativeElement, 'keyup')
          .pipe(
            distinctUntilChanged(),
            debounceTime(500),
            filter(() => this.input().nativeElement.value.length === 10),
            tap(() => this._updateValue(this.input().nativeElement.value)),
            this.#untilDestroyed()
          )
          .subscribe();
      }
    });
  }

  @HostListener('focusout') onTouched(): void {
    this._onTouched();
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched, errors } = this.control;
    if (errors) {
      if (errors.errorMessage) {
        this.errorMessage = errors.errorMessage;
      } else {
        const firstKey = Object.keys(errors)[0];
        const getError = this._errors[firstKey];
        this.errorMessage = getError ? getError(errors[firstKey]) : '';
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  writeValue(value: IComplexDate): void {
    this._value = value;
    this.date.setValue(value ? new Date(value.jsdate) : '', { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  datepickerDateChange(date: MatDatepickerInputEvent<Date, Date | null>): void {
    const dataJson = date.targetElement['value'];
    if (!this._value || dataJson !== this._value.formattedUS) {
      this._updateValue(dataJson);
    }
  }

  private _updateValue(val: string): void {
    const value = ControlDateFormatter.encode(val);
    this.writeValue(value);
    this._onChange(value);
    this.dateChange.emit(value);
    this._onTouched();
  }

  private _onChange: (value: IComplexDate) => void = () => undefined;
  private _onTouched: () => void = () => undefined;

  private _addDays(days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + days);

    return date;
  }
}
