import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContractSearchState, featureKey } from '@ph-store/contract-search/contract-search.reducer';

const selectContractSearchState = createFeatureSelector<ContractSearchState>(featureKey);
export const selectContractSearchData = createSelector(selectContractSearchState, (state) => state.contracts);
export const selectContractSearchDataLoading = createSelector(selectContractSearchState, (state) => state.contractsLoading);
export const selectContractSearchRowCount = createSelector(selectContractSearchState, (state) => state.rowCount);
export const selectContractSearchParams = createSelector(selectContractSearchState, (state) => state.searchParams);
export const selectContractSearchPagesCount = createSelector(selectContractSearchState, (state) => state.pagesCount);
export const selectContractSearchCurrentPage = createSelector(selectContractSearchState, (state) => state.currentPage);

export const selectContractDetails = createSelector(selectContractSearchState, (state) => state.contractDetails);
export const selectContractDetailsLoading = createSelector(selectContractSearchState, (state) => state.contractDetailsLoading);
