import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';

import { SurchargeSelectionItem } from '@ph-model/filters/surcharge-selection-item';
import { AllDealers, Markup, UserResponse } from '@ph-model/login';
import { AggregatorRequest } from '@ph-model/request/aggregator.request';
import { GetProductsRequest } from '@ph-model/request/product.request';
import { GetProductsResponse, ProductItem } from '@ph-model/response';
import { AvailableOptions, StandardResponse, SurchargeOption } from '@ph-model/response/interface';
import { recalculateCustomerCost, productResponseDataMapper } from '@ph-store/product/product.helpers';

import { hideDealerCostProductCodes } from 'app/static-data/hide-dealer-cost';

@Injectable({ providedIn: 'root' })
export class ProductService {
  productResponseDataMapper(products: ProductItem[]): ProductItem[] {
    return productResponseDataMapper(products);
  }

  calculateCustomerCosts(
    products: ProductItem[],
    markup: Markup,
    surchargesSelection: SurchargeSelectionItem[]
  ): ProductItem[] {
    return products.map((product: ProductItem) => recalculateCustomerCost(product, markup, surchargesSelection));
  }

  aggregateProductRequestMapper(request: GetProductsRequest): AggregatorRequest {
    const req = new AggregatorRequest(request);

    // validate languageCode
    if (!req.languageCode) {
      req.languageCode = '';
    }

    // skipping any surchage modifications (this will be after the getproducts call)

    // validate inservice date
    if (!req.inServiceDate) {
      req.inServiceDate = '';
    }

    return req;
  }

  assignPropertiesToProducts(response: GetProductsResponse, user: UserResponse): GetProductsResponse {
    let resp: GetProductsResponse = response;
    if (resp?.availableOptions?.products?.productItem) {
      resp.availableOptions.products.productItem.forEach((data: ProductItem) => {
        // Assigning category to each product
        if (user.productCategory) {
          data.productCategory = user.productCategory[data.productCode];
        }

        // Assigning vendor to each product if isRelatedDealer exists
        if (user.isRelatedDealer) {
          const index = user.allDealers.findIndex((dealer: AllDealers) => dealer.cmsDealerNumber === data.dealer);
          if (index > -1) {
            data.vendor = user.allDealers[index].vendor;
          }
        }
      });
    } else {
      resp = {
        standardResponse: response as unknown as StandardResponse,
        availableOptions: { products: { productItem: [] } } as AvailableOptions,
      };
    }

    return resp;
  }

  prepProductsForSurcharges(request: GetProductsRequest, response: GetProductsResponse): GetProductsResponse {
    if (!request.surcharges) {
      return response;
    }

    response.availableOptions.products.productItem.forEach((productItem: ProductItem) => {
      productItem.surchargeOptions.surchargeOption.forEach((option: SurchargeOption) => (option.added = false));
      productItem.surchargeAdded = false;
    });

    return response;
  }

  handleDealerCost(response: GetProductsResponse): GetProductsResponse {
    const isSupressDealerCost = environment.features.suppressDealerCost;
    if (isSupressDealerCost) {
      response.availableOptions.products.productItem.forEach((productItem: ProductItem) => {
        productItem.hideDealerCost = hideDealerCostProductCodes.includes(productItem.productCode);
      });
    }

    return response;
  }
}
