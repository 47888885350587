import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { CartTax } from '@ph-model/cart/cart-tax.model';
import { Cart } from '@ph-model/cart/cart.model';
import { InsRequest } from '@ph-model/cart/request-quote';
import { SaveQuote } from '@ph-model/cart/save-quote.model';
import { ValidatedCart } from '@ph-model/cart/validate-cart.model';
import { Forms } from '@ph-model/form/form';

@Injectable({ providedIn: 'root' })
export class CartApiClient {
  private _URL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  validateCart(request: Cart) {
    const powerUpProducts = request.products.filter((product: CartItem) => product.product.productCode === 'GMWC');
    if (
      (powerUpProducts.length > 0 && request.products.length === powerUpProducts.length) ||
      !environment.features.supportCartValidation
    ) {
      return of({
        validatedCart: request.products,
        containsError: false,
        containsWarning: false,
      });
    }

    return this.http
      .post<ValidatedCart>(`${environment.apiUrl}/cartvalidation`, request)
      .pipe(catchError(this._errorHandler));
  }

  saveQuote(quote: SaveQuote) {
    return this.http.post<void>(`${environment.apiUrl}/saveQuote`, quote).pipe(catchError(this._errorHandler));
  }

  getRequestQuote(request: InsRequest) {
    return this.http
      .post<{ insResponse: InsuranceResponseModel }>(`${environment.apiUrl}/umu-insurance/quote`, request)
      .pipe(catchError(this._errorHandler));
  }

  getUMUCredit(request: InsRequest, locale?: string) {
    let headers = new HttpHeaders();
    if (locale) {
      headers = headers.set('Accept-Language', locale);
    }

    return this.http
      .post<{ insResponse: { responseStatus: string; applicationResponse: string } }>(
        `${environment.apiUrl}/umu-insurance/contract`,
        request,
        { headers }
      )
      .pipe(catchError(this._errorHandler));
  }

  getCheckoutForm(request: Cart): Observable<Forms> {
    return this.http.post<Forms>(`${environment.apiUrl}/getcheckoutform`, request).pipe(catchError(this._errorHandler));
  }

  taxCalculation(request: CartTax): Observable<{ cart: CartItem[] }> {
    return this.http
      .post<{ cart: CartItem[] }>(`${environment.apiUrl}/taxcalculation`, request)
      .pipe(catchError(this._errorHandler));
  }

  private _errorHandler(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}
