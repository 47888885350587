import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-loader[loading]',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
})
export class LoaderComponent {
  loading: InputSignal<boolean> = input.required();
}
