import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { PhDialog } from '@ph-core/services/dialog';
import { EContractApiService } from '@ph-core/services/econtract-api.service';
import { RemitService } from '@ph-core/services/remit.service';
import { ContractType, ErroredContractResponse, RemittedContractResponse } from '@ph-model/api';
import { PendingContractResponse } from '@ph-model/api/response/pending-contract.response.model';
import { EContractStatusType } from '@ph-model/e-contract-status-type';
import { UserResponse } from '@ph-model/login/user.response.model';
import { UpdateStatusEContractRequest } from '@ph-model/request/save-power-up-econtract';
import { SubmitPendingContractsRequest } from '@ph-model/request/submit-pending-contracts.request';
import { StandardEContractResponse } from '@ph-model/response/e-contract.response';
import { RemitSubmittedPopupComponent } from '@ph-shared/components';
import { selectUser } from '@ph-store/user/user.selectors';

import {
  getPendingContracts,
  getPendingContractsSuccess,
  getRemittedContracts,
  getRemittedContractsSuccess,
  remitFailure,
  submitAllPendingRemit,
  submitAllPendingRemitSuccess,
} from './remit.actions';
import { selectPendingToDeclineContracts, selectPendingToRemitContracts } from './remit.selectors';

@Injectable()
export class RemitEffects {
  getPendingContracts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPendingContracts),
      concatLatestFrom(() => [
        this.store.select(selectPendingToRemitContracts),
        this.store.select(selectPendingToDeclineContracts),
        this.store.select(selectUser),
      ]),
      mergeMap(([, pendingToRemitContracts, pendingToDeclineContracts, user]) => {
        const allDealers = JSON.parse(JSON.stringify(user.allDealers));

        if (allDealers && allDealers.length === 1) {
          allDealers[0].cmsDealerNumber = user.cmsDealerNumber;
        }

        return this.httpClient
          .post<PendingContractResponse>(`${environment.apiUrl}/getpendingcontracts`, {
            cmsdealernumber: user.cmsDealerNumber,
            pendingToRemitContracts: pendingToRemitContracts,
            pendingToDeclineContracts: pendingToDeclineContracts,
            programId: user.programId,
            allDealers: allDealers,
            pendingDays: user.pendingDays,
          })
          .pipe(
            tap((response: PendingContractResponse) => RemitService.handleDealerCost(response.pendingContracts)),
            map((response: PendingContractResponse) => {
              return getPendingContractsSuccess({
                contracts: response,
                filters: RemitService.fetchContractGroups(response.pendingContracts),
              });
            }),
            catchError((error) => of(remitFailure({ error })))
          );
      })
    );
  });

  submitPendingContracts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitAllPendingRemit),
      concatLatestFrom(() => [
        this.store.select(selectPendingToRemitContracts),
        this.store.select(selectPendingToDeclineContracts),
        this.store.select(selectUser),
      ]),
      switchMap(([, pendingToRemitContracts, pendingToDeclineContracts, user]) =>
        environment.features.useNewSaveContractApi
          ? this._handleSubmitPendingContractsWithNewRatesApi(pendingToRemitContracts, pendingToDeclineContracts, user)
          : this._handleSubmitPendingContracts(pendingToRemitContracts, pendingToDeclineContracts, user)
      )
    );
  });

  submitPendingContractsSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(submitAllPendingRemitSuccess),
        switchMap(({ contracts }) =>
          this.dialog.open(RemitSubmittedPopupComponent, { data: { contracts } }).afterClosed()
        )
      );
    },
    { dispatch: false }
  );

  getRemittedContracts$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(getRemittedContracts),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([, user]) =>
        this.httpClient
          .post<RemittedContractResponse>(`${environment.apiUrl}/getremittedcontracts`, {
            cmsdealernumber: user.cmsDealerNumber,
            programId: user.programId,
            allDealers: user.allDealers,
          })
          .pipe(
            tap((response: RemittedContractResponse) => RemitService.handleDealerCost(response.remittedContracts)),
            map((response: RemittedContractResponse) =>
              getRemittedContractsSuccess({
                contracts: response,
                filters: RemitService.fetchRemittedContractGroups(response.remittedContracts),
              })
            ),
            catchError((error) => of(remitFailure({ error })))
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private econtractApiService: EContractApiService,
    private store: Store,
    private dialog: PhDialog
  ) {}

  private _handleSubmitPendingContracts(
    pendingToRemitContracts: ContractType[],
    pendingToDeclineContracts: ContractType[],
    user: UserResponse
  ): Observable<Action> {
    const request: SubmitPendingContractsRequest = {
      cmsdealernumber: user.cmsDealerNumber,
      pendingToRemitContracts: pendingToRemitContracts,
      pendingToDeclineContracts: pendingToDeclineContracts,
      programId: user.programId,
      allDealers: user.allDealers,
      pendingDays: user.pendingDays,
    };

    return this.httpClient.post<PendingContractResponse>(`${environment.apiUrl}/remitcontracts`, request).pipe(
      catchError((error) => throwError(() => error)),
      map((response: PendingContractResponse) => {
        const pendingFilters = RemitService.fetchContractGroups(response.pendingContracts);
        response = RemitService.resetContracts(response);

        return submitAllPendingRemitSuccess({
          submittedRemittedRequest: pendingToRemitContracts,
          contracts: response,
          filters: pendingFilters,
        });
      }),
      catchError((error) => {
        console.error(error);

        return of(remitFailure({ error }));
      })
    );
  }

  private _handleSubmitPendingContractsWithNewRatesApi(
    pendingToRemitContracts: ContractType[],
    pendingToDeclineContracts: ContractType[],
    user: UserResponse
  ): Observable<Action> {
    const pendingToRemitContractsRequests = pendingToRemitContracts.map((contract: ContractType) => {
      const request = new UpdateStatusEContractRequest(contract.econContractNumber, EContractStatusType.Remit);

      // convert failed request to regular data to execute result evaluation in switchMap pipe operator
      return this.econtractApiService.updateStatusEContract(request);
    });

    const pendingToDeclineContractsRequests = pendingToDeclineContracts.map((contract: ContractType) => {
      const request = new UpdateStatusEContractRequest(contract.econContractNumber, EContractStatusType.Decline);

      // convert failed request to regular data to execute result evaluation in switchMap pipe operator
      return this.econtractApiService.updateStatusEContract(request);
    });

    return forkJoin([...pendingToRemitContractsRequests, ...pendingToDeclineContractsRequests]).pipe(
      switchMap((response: StandardEContractResponse[]) => {
        const allDealers = JSON.parse(JSON.stringify(user.allDealers));

        if (allDealers && allDealers.length === 1) {
          allDealers[0].cmsDealerNumber = user.cmsDealerNumber;
        }

        const erroredContracts: ErroredContractResponse = {
          declinedErrorContracts: [],
          remittedErrorContracts: [],
        };

        const allSubmittedContracts = [...pendingToRemitContracts, ...pendingToDeclineContracts];

        response.forEach((res: StandardEContractResponse, index: number) => {
          if (res?.status && res?.status !== 'SUCCESS') {
            const erroredContract = allSubmittedContracts[index];
            erroredContract.standardResponse = { ...res };

            if (index < pendingToRemitContracts.length) {
              erroredContracts.remittedErrorContracts.push(erroredContract);
            } else {
              erroredContracts.declinedErrorContracts.push(erroredContract);
            }
          }
        });

        const { cmsDealerNumber, programId, pendingDays } = user;

        return this.httpClient
          .post<PendingContractResponse>(`${environment.apiUrl}/getpendingcontracts`, {
            cmsdealernumber: cmsDealerNumber,
            programId,
            pendingDays,
            allDealers,
            pendingToRemitContracts: [],
            pendingToDeclineContracts: [],
          })
          .pipe(
            map((response: PendingContractResponse) => {
              const pendingFilters = RemitService.fetchContractGroups(response.pendingContracts);
              response = RemitService.resetContracts(response);
              response.erroredContracts = erroredContracts;

              return submitAllPendingRemitSuccess({
                submittedRemittedRequest: pendingToRemitContracts,
                contracts: response,
                filters: pendingFilters,
              });
            }),
            catchError((error) => {
              console.error(error);

              return of(remitFailure({ error }));
            })
          );
      })
    );
  }
}
