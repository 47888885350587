import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PrismicData } from '@ph-model/api/response/prismic-response.model';

import { featureKey, PrismicState } from './prismic.reducer';

const isFrenchSelected: boolean = localStorage.getItem('languageCode') === 'fr-CA';

const selectPrismicState = createFeatureSelector<PrismicState>(featureKey);
export const selectPrismicData = createSelector(selectPrismicState, (state) => state.data);
export const selectBrandLogoUrl = createSelector(selectPrismicData, (data: PrismicData) =>
  isFrenchSelected ? data?.login_page_logo_fr.url || data?.login_page_logo.url : data?.login_page_logo.url
);
export const selectLoginWarning = createSelector(selectPrismicData, (data) => data && data.login_warning);
export const selectBrandBackgroundLogoUrl = createSelector(selectPrismicData, (data: PrismicData) =>
  isFrenchSelected
    ? data?.home_page_background_logo_fr.url || data?.home_page_background_logo.url
    : data?.home_page_background_logo.url
);
export const selectBrandSupportContacts = createSelector(
  selectPrismicData,
  (data: PrismicData) => data?.support_emails_and_numbers
);
export const selectBrandIcons = createSelector(selectPrismicData, (data: PrismicData) => data?.icons[0]);
export const selectBrandFavicon = createSelector(selectPrismicData, (data: PrismicData) => data?.favicon.url);
export const selectProductImages = createSelector(selectPrismicState, (state) => state.productImages);
export const selectBrandProductImages = createSelector(selectPrismicState, (state) => state.data.products);
export const selectLienholders = createSelector(selectPrismicData, (data: PrismicData) => data?.lienholders);
