import { Filter } from '@ph-model/filters/filter';
import { defaultProductFilterSetup, ProductFilter } from '@ph-model/filters/product-filter';
import { ProductFilters } from '@ph-model/filters/product-filters';
import { ProductItem } from '@ph-model/response';

import { SortingService } from '../services/sorting.service';

function fetchFiltersByProduct(products: ProductItem[] | undefined): ProductFilter[] {
  const productFilters = products?.reduce((filters: ProductFilter[], elem: ProductItem) => {
    const match: ProductFilter = filters.find((item) => {
      return (
        item.name === (elem.productDescription.enUsProductDescription || elem.productDescription.frCaProductDescription)
      );
    });
    if (!match) {
      filters.push({
        ...defaultProductFilterSetup,
        name: elem.productDescription.enUsProductDescription || elem.productDescription.frCaProductDescription,
        property: 'product',
      });
    }

    return filters;
  }, []);

  return SortingService.sortStrings(productFilters);
}

function fetchFiltersByPlan(products: ProductItem[] | undefined): ProductFilter[] {
  const planFilters = products?.reduce((filters: ProductFilter[], elem: ProductItem) => {
    const match: ProductFilter = filters.find((item) => {
      return item.name === (elem.planName.enUsPlanName || elem.planName.frCaPlanName);
    });
    if (!match) {
      filters.push({
        ...defaultProductFilterSetup,
        name: elem.planName.enUsPlanName || elem.planName.frCaPlanName,
        property: 'product',
      });
    }

    return filters;
  }, []);

  return SortingService.sortStrings(planFilters);
}

function fetchFiltersByCoverage(products: ProductItem[] | undefined): ProductFilter[] {
  const coverageFilters = products?.reduce((filters: ProductFilter[], elem: ProductItem) => {
    const match: ProductFilter = filters.find((item) => {
      return item.name === elem.coverageDescription;
    });
    if (!match) {
      filters.push({
        ...defaultProductFilterSetup,
        name: elem.coverageDescription,
        property: 'product',
      });
    }

    return filters;
  }, []);

  return SortingService.sortStrings(coverageFilters);
}

function fetchFiltersByDeductible(products: ProductItem[] | undefined): ProductFilter[] {
  const deductibleFilters = products?.reduce((filters: ProductFilter[], elem: ProductItem) => {
    const index = filters.some((item) => {
      let deductibleName: string = elem.deductible.toString();
      if (elem.disappearingDeductible) {
        deductibleName = elem.deductible + '(D)';
      }

      return item.name === deductibleName;
    });
    if (!index) {
      let deductibleName: string = elem.deductible.toString();
      if (elem.disappearingDeductible) {
        deductibleName = elem.deductible + '(D)';
      }
      filters.push({ ...defaultProductFilterSetup, name: deductibleName, property: 'deductible' });
    }

    return filters;
  }, []);

  return deductibleFilters.sort((d1, d2) => Number(d1.name) - Number(d2.name));
}

function fetchFiltersByTerm(products: ProductItem[] | undefined): ProductFilter[] {
  const termFilters = products?.reduce((filters: ProductFilter[], elem: ProductItem) => {
    const index = filters.some((item) => {
      return item.name === elem.maxTerm;
    });
    if (!index) {
      filters.push({ ...defaultProductFilterSetup, name: elem.maxTerm, property: 'term' });
    }

    return filters;
  }, []);

  return termFilters.sort((t1, t2) => Number(t1.name) - Number(t2.name));
}

function fetchFiltersByMileage(products: ProductItem[] | undefined): ProductFilter[] {
  const mileageFilters = products?.reduce((filters, elem: ProductItem) => {
    const index = filters.some((item) => {
      return item.name === elem.termMileage;
    });
    if (!index && Number(elem.termMileage) !== 0) {
      filters.push(new Filter(elem.termMileage));
    }

    return filters;
  }, []);

  return mileageFilters.sort((m1, m2) => Number(m1.name) - Number(m2.name));
}

function fetchFiltersBySurcharge(products: ProductItem[] | undefined): ProductFilter[] {
  return products?.reduce((filters, product: ProductItem) => {
    const surcharges = product.surchargeOptions.surchargeOption;
    surcharges.forEach((surcharge) => {
      const found = filters.find((item) => {
        return item.name === (surcharge.label.enUsLabel || surcharge.label.frCaLabel);
      });
      if (!found) {
        filters.push(new Filter(surcharge.label.enUsLabel || surcharge.label.frCaLabel));
      }
    });

    return filters;
  }, []);
}

export function calculateFilters(products: ProductItem[]): ProductFilters {
  return {
    product: fetchFiltersByProduct(products),
    plan: fetchFiltersByPlan(products),
    coverage: fetchFiltersByCoverage(products),
    term: fetchFiltersByTerm(products),
    deductible: fetchFiltersByDeductible(products),
    mileage: fetchFiltersByMileage(products),
    surcharge: fetchFiltersBySurcharge(products),
  };
}
