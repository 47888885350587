import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { nextStep, prevStep, switchToStep, switchToStepByUrl } from './shopping-stepper.actions';
import { selectCurrentStep } from './shopping-stepper.selectors';

@Injectable()
export class ShoppingStepperEffects {
  nextShoppingStep$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(switchToStep, switchToStepByUrl, nextStep, prevStep),
        concatLatestFrom(() => this.store.select(selectCurrentStep)),
        filter(([, currentStep]) => !!currentStep),
        tap(([, currentStep]) => this.router.navigate(['/home/shopping', currentStep?.url])),
        map(() => null)
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router
  ) {}
}
