export const sanitizeDataForCsv: (data: Record<string, string>[]) => Record<string, string>[] = (
  data: Record<string, string>[]
): Record<string, string>[] => {
  const result = [];

  data.forEach((d: Record<string, string>) => {
    const obj = {};

    Object.entries(d).forEach(([key, value]) => {
      if (
        typeof value === 'string' &&
        (value.startsWith('=') || value.startsWith('+') || value.startsWith('-') || value.startsWith('@'))
      ) {
        obj[key] = `‘${value}`;
      } else {
        obj[key] = value;
      }
    });

    result.push(obj);
  });

  return result;
};
