import { ShoppingFlowType } from '@ph-core/enums';

import { ShoppingStepsUrls } from './shopping-step-urls.model';

export interface ShoppingStepDefinition {
  title: string;
  url: ShoppingStepsUrls;
  paths?: { name: ShoppingFlowType; steps: ShoppingStepDefinition[] }[];
  currentPath?: string;
}
