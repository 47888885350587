<div class="d-flex justify-content-between">
  <div class="table-title">{{ verbiage.remitPage.print.register | translate }}</div>
  <div class="logo">
    <img [src]="navbarLogo()" alt="Safe-guard-products" style="width: 100%" />
  </div>
</div>

<div class="w-100 mt-5">
  <!-- FIRST DEALER ROW -->
  <div class="d-flex">
    <div class="div-cell t-col-6 border-left">
      <div class="cell-label">DEALER/SELLER NAME</div>
      <div class="cell-content">
        {{ user().name }}
      </div>
    </div>
    <div class="div-cell t-col-6">
      <div class="cell-label">DEALER #</div>
      <div class="cell-content">
        {{ user().cmsDealerNumber }}
      </div>
    </div>
  </div>

  <!--SECOND DEALER ROW-->
  <div class="d-flex">
    <div class="div-cell t-col-6 border-left">
      <div class="cell-label">STREET ADDRESS</div>
      <div class="cell-content">
        {{ user().dealerInfo?.dealerAddress1 }}
      </div>
    </div>
    <div class="div-cell t-col-2">
      <div class="cell-label">CITY</div>
      <div class="cell-content">
        {{ user().dealerInfo?.dealerCity }}
      </div>
    </div>
    <div class="div-cell t-col-1">
      <div class="cell-label">STATE</div>
      <div class="cell-content">
        {{ user().dealerInfo?.dealerState }}
      </div>
    </div>
    <div class="div-cell t-col-1">
      <div class="cell-label">ZIP CODE</div>
      <div class="cell-content">
        {{ user().dealerInfo?.dealerZipCode }}
      </div>
    </div>
    <div class="div-cell t-col-2">
      <div class="cell-label">DEALER PHONE #</div>
      <div class="cell-content">
        {{ user().dealerInfo?.dealerPhone ? user().dealerInfo?.dealerPhone : 'N/A' }}
      </div>
    </div>
  </div>

  <!--THIRD DEALER ROW-->
  <div class="d-flex">
    <div class="div-cell border-left border-bottom col-customer-name">
      <div class="cell-label">DATE SUBMITTED</div>
      <div class="cell-content">
        {{ remitted() ? (date | date) : 'PENDING' }}
      </div>
    </div>
    <div class="div-cell border-bottom t-col-4">
      <div class="cell-label">COMPLETED BY</div>
      <div class="cell-content">
        {{ user().fullName }}
      </div>
    </div>
    <div class="div-cell border-bottom t-col-3">
      <div class="cell-label">TITLE</div>
      <div class="cell-content">N/A</div>
    </div>
    <div class="div-cell border-bottom t-col-3">
      <div class="cell-label">EXTENSION</div>
      <div class="cell-content">N/A</div>
    </div>
  </div>

  <!-- FOURTH ROW -->
  <div class="d-flex">
    <div class="center-note border-left border-right w-100">
      NOTE: The following contracts have been {{ verbiage.remitPage.print.registered | translate }} and no further
      action is required at this time.
    </div>
  </div>

  <!-- FIFTH ROW -->
  <div class="d-flex text-grey">
    <div class="row-cell border-left col-customer-name">CUSTOMER NAME</div>
    <div class="row-cell t-col-1">SALE DATE</div>
    <div class="row-cell col-year">YEAR</div>
    <div class="row-cell col-model">MODEL</div>
    <div class="row-cell col-vin">VIN / SERIAL NUMBER</div>
    <div class="row-cell col-product">PRODUCT</div>
    <div class="row-cell col-term">TERM</div>
    <div class="row-cell col-contract">CONTRACT#</div>
    <div class="row-cell col-dealer-cost">DEALER COST</div>
    <div class="row-cell col-total-cost">TOTAL COST</div>
  </div>
  @for (item of contracts(); track item; let i = $index) {
    <div>
      @if (
        i !== 0 &&
        (i === amountOfContractOnFirstPage || (i - amountOfContractOnFirstPage) % amountOfContractOnRestPages === 0)
      ) {
        <div>
          <div style="page-break-after: always"></div>
          <div style="height: 50px"></div>
        </div>
      }

      <div class="d-flex">
        <div
          class="row-cell border-left col-customer-name"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{
            item.businessFlag === 'Y' ? item.businessName.trim() : item.firstName.trim() + ' ' + item.lastName.trim()
          }}
        </div>
        <div
          class="row-cell t-col-1"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.saleDate }}
        </div>
        <div
          class="row-cell col-year"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.year }}
        </div>
        <div
          class="row-cell col-model"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.productCode === 'GMWC' ? 'POWERUP' : item.model }}
        </div>
        <div
          class="row-cell col-vin"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.vin }}
        </div>
        <div
          class="row-cell col-product"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.productCode }}
        </div>
        <div
          class="row-cell col-term"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.termMonths }}
        </div>
        <div
          class="row-cell col-contract"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          {{ item.econContractNumber }}
        </div>
        <div
          class="row-cell col-dealer-cost"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          ${{ item.contractDealerCost }}
        </div>
        <div
          class="row-cell col-total-cost"
          [class.border-bottom]="
            $last ||
            (i !== 0 &&
              (i === amountOfContractOnFirstPage - 1 ||
                (i - (amountOfContractOnFirstPage - 1)) % amountOfContractOnRestPages === 0))
          "
        >
          ${{ item.customerCost }}
        </div>
      </div>
    </div>
  }
  <div class="bottom-line d-flex justify-content-end border-bottom border-right border-left">
    <div class="total">
      <span> TOTAL DEALER COST&nbsp;&nbsp; </span>
      <span style="font-size: 1.5em"> ${{ totalDealerCost() }} </span>
    </div>
  </div>
</div>
