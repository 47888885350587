export class Markup {
  markupType: string;
  markupDollar: number;
  markupMultiplier: number;
  markupDisabled: boolean;

  constructor() {
    this.markupType = 'multiplier';
    this.markupMultiplier = 2;
    this.markupDollar = 100;
    this.markupDisabled = false;
  }
}
