export const tireProductCodes: string[] = ['BUDT', 'CADT', 'CHDT', 'GMDT', 'NODT', 'CBDT', 'CBTN'];
export const tireCoverageCodesAllowedInSameCart: string[] = [
  'GMDOT-1',
  'GMDOT-2',
  '2GMDOT-1',
  '2GMDOT-2',
  '3GMDOT-1',
  '3GMDOT-2',
  '4GMDOT-1',
  '4GMDOT-2',
];
