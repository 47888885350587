import { FinanceType } from '@ph-core/enums';
import { CreditInsuranceCoverageOption } from '@ph-model/form/credit-insurance-coverage-option.model';
import { OptionType } from '@ph-model/form/option-type.model';

export const loanProtectionCoverageOptions: OptionType[] = [
  {
    value: '752',
    description: 'coverageOptions.platinumBasic',
    checked: true,
  },
  {
    value: '753',
    description: 'coverageOptions.platinumEnhanced',
    checked: true,
  },
  {
    value: '754',
    description: 'coverageOptions.diamondBasic',
    checked: true,
  },
  {
    value: '755',
    description: 'coverageOptions.diamondEnhanced',
    checked: true,
  },
];

export const creditInsuranceCoverageOptions: CreditInsuranceCoverageOption[] = [
  {
    value: { single: '701', joint: '801' },
    description: 'coverageOptions.lifeDecreasingTerm',
    type: 'life',
    financeType: [FinanceType.Finance, FinanceType.Balloon],
  },
  {
    value: { single: '702', joint: '802' },
    description: 'coverageOptions.lifeDecreasingTermLevelTerm',
    type: 'life',
    financeType: [FinanceType.Balloon],
  },
  {
    value: { single: '721', joint: '821' },
    description: 'coverageOptions.criticalIllnessDecreasingTerm',
    type: 'criticalIllness',
    financeType: [FinanceType.Finance, FinanceType.Balloon],
  },
  {
    value: { single: '722', joint: '822' },
    description: 'coverageOptions.criticalIllnessDecreasingTermLevelTerm',
    type: 'criticalIllness',
    financeType: [FinanceType.Balloon],
  },
  {
    value: { single: '501', joint: '601' },
    description: 'coverageOptions.totalDisability30DayRetro',
    type: 'disability',
    financeType: [FinanceType.Finance, FinanceType.Balloon],
  },
  {
    value: { single: '511', joint: '611' },
    description: 'coverageOptions.totalDisability30DayElim',
    type: 'disability',
    financeType: [FinanceType.Finance, FinanceType.Balloon],
  },
  {
    value: { single: '513', joint: '613' },
    description: 'coverageOptions.totalDisability30DayElim12months',
    type: 'disability',
    financeType: [FinanceType.Balloon],
  },
];
