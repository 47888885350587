export enum Country {
  USA = 'USA',
  CAN = 'CAN',
}

export enum CountryPostalLengths {
  USAPostal = 5,
  //   Canada postal code with and without space
  CANPostalWS = 6,
  CANPostalWIS = 7,
}
