<header ph-dialog-header>
  <h2 class="title" ph-notification-title>{{ 'invalidCheckoutPopup.title' | translate }}</h2>
  <ph-icon name="cross" width="24px" height="24px" ph-overlay-close />
</header>

<ph-icon name="close" width="50px" height="50px" />

<table class="table">
  <thead>
    <tr>
      <th scope="col">{{ 'invalidCheckoutPopup.contract' | translate }}</th>
      <th scope="col">{{ 'invalidCheckoutPopup.errorMessage' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    @for (item of invalidCart; track item) {
      <tr>
        <td>{{ item.product.productCategory }} - {{ item.product.planName.enUsPlanName }}</td>
        <td>{{ item.message }}</td>
      </tr>
    }
  </tbody>
</table>

<button ui-ph-button size="small" ph-overlay-close>{{ 'invalidCheckoutPopup.continue' | translate }}</button>
