import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CarfaxResponse } from '@ph-model/api/response/carfax-response.model';

@Injectable({ providedIn: 'root' })
export class CarfaxService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCarDetails(vin: string): Observable<CarfaxResponse> {
    return this.http.get(`${this.apiUrl}/carfax`, { params: { vin } }).pipe(
      map((res: { standardResponse: { statusCode: number; responseData: CarfaxResponse } }) => {
        const { statusCode, responseData: result } = res.standardResponse;

        return statusCode === 200 ? result : null;
      })
    );
  }
}
