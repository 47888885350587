import { environment } from '@ph-env/environment';

import { Vendor } from '@ph-core/enums';

export class RequestBase {
  constructor(
    public applicationName: string = environment.vendorName === Vendor.GM ? 'Portal' : 'GM',
    public vendorName: string = environment.vendorName === Vendor.GM ? 'SGIPORTAL_GM' : 'GM-POWER UP',
    public channel: string = environment.vendorName === Vendor.GM ? Vendor.GM : 'GM-POWER UP'
  ) {
    this.applicationName = applicationName;
    this.vendorName = vendorName;
    this.channel = channel;
  }
}
