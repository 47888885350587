import { ChangeDetectionStrategy, Component, computed, HostBinding, input, InputSignal, Signal } from '@angular/core';

import { IconComponent } from '@ph-shared/components/icon/icon-component';
import { IconName } from '@ph-shared/components/icon/icons';

import { getNotificaitonIcon, getNotificationLevel } from '../notification/notification.helpers';
import { PhNotificationType } from '../notification/notification.interface';

@Component({
  selector: 'ph-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class PhNotificationIconComponent {
  type: InputSignal<PhNotificationType> = input.required();
  icon: Signal<IconName> = computed(() => getNotificaitonIcon(this.type()));

  @HostBinding('class') get colorClass(): { [key: string]: boolean } {
    return { [getNotificationLevel(this.type())]: true };
  }
}
