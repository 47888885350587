import { AsyncPipe } from '@angular/common';
import { Component, Inject, output, OutputEmitterRef } from '@angular/core';

import { Observable } from 'rxjs';

import { PH_OVERLAY_DATA } from '@ph-core/services/overlay';

import { PhNotificationPanelData } from './notification-panel-data';
import { notificationRevealTrigger } from './notification-panel.animation';
import { PhNotificationComponent } from '../notification/notification.component';
import { PhNotificationItem } from '../notification/notification.interface';

@Component({
  selector: 'ph-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  animations: [notificationRevealTrigger],
  standalone: true,
  imports: [PhNotificationComponent, AsyncPipe],
})
export class PhNotificationPanelComponent {
  closedNotification: OutputEmitterRef<void> = output();

  constructor(@Inject(PH_OVERLAY_DATA) private data: PhNotificationPanelData) {}

  get notifications$(): Observable<PhNotificationItem[]> {
    return this.data.notification.notifications$();
  }

  closeNotification(id: string): void {
    this.data.notification.remove(id);
  }

  trackByID(_: number, { id }: PhNotificationItem): string {
    return id;
  }
}
