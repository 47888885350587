import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostListener, input, InputSignal } from '@angular/core';

@Directive({
  selector: '[noSpecialCharacters]',
  standalone: true,
})
export class NoSpecialCharactersDirective {
  noSpecialCharacters: InputSignal<boolean | string> = input.required({ transform: coerceBooleanProperty });

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event): void {
    if (this.noSpecialCharacters()) {
      const initialValue = this._el.nativeElement.value;
      this._el.nativeElement.value = initialValue.replace(/[^0-9a-zA-Z\.\s]+/g, '');
      if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }
}
