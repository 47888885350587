import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { DecodeVinResponse } from '@ph-model/api/response/decode-vin.response';
import { Country, CountryPostalLengths } from '@ph-model/country-code';
import { Field } from '@ph-model/form/field';

import { ValidationService } from './validation.service';
import * as fromValidations from '../validations/forms/generic.validation';

export interface ExtendedFormControl extends FormControl {
  label?: string;
  taxPercentage?: number;
}

export const getFormattedPostalCode: (postalCode: string) => string = (postalCode: string): string => {
  if (!postalCode) {
    return '';
  }
  let formattedValue = postalCode.replaceAll(' ', '');

  if (formattedValue.length === 6) { // Canadian postal code, that have to be separated with space
    formattedValue = `${formattedValue.slice(0, 3)} ${formattedValue.slice(3)}`;
  }

  return formattedValue.toUpperCase();
}

@Injectable({ providedIn: "root" })
export class FormService {
  constructor(private formBuilder: FormBuilder) {}

  triggerValidityOfAllFields(form: FormGroup): void {
    Object.keys(form.controls).forEach((field: string) => form.get(field).markAsTouched({ onlySelf: true }));
  }

  // CONVERT FIELD ARRAY TO FORM GROUP
  setForm(fields: Field[]): FormGroup {
    const form: FormGroup = this.formBuilder.group({});
    fields.forEach((element: Field) => {
      if (element.display) {
        const field = this._setDefault(element);
        const validations = this.getValidations(element);
        field.setValidators(validations);
        form.addControl(element.name, field);
      }
    });
    ValidationService.BusinessFieldValidate(form);

    return form;
  }

  setProductFormAfterVinDecodedSuccessfully(productForm: FormGroup, vinData: DecodeVinResponse): void {
    productForm.get('vin').setValue(vinData.vin);
    productForm.get('year').setValue(vinData.year[0]);
    productForm.get('make').setValue(vinData.make[0]);
    productForm.get('model').setValue(vinData.model[0]);
    productForm.get('trim').setValue(vinData.trim[0]);
  }

  setProductFormForManualDecode(productForm: FormGroup): void {
    if (productForm) {
      // don't overwrite existing values in case we're loading a stored form
      if (!productForm.get('year').value) {
        productForm.get('year').setValue(null);
      }
      if (!productForm.get('make').value) {
        productForm.get('make').setValue(null);
      }
      if (!productForm.get('model').value) {
        productForm.get('model').setValue(null);
      }
      if (!productForm.get('trim').value) {
        productForm.get('trim').setValue(null);
      }
    }
  }

  getValidations(element: Field): ValidatorFn[] {
    let validations: ValidatorFn[] = [];
    if (element.required) {
      validations.push(fromValidations.RequiredValidator);
    }
    element.validations.forEach((elementValidation: string) => {
      // running all validations from validation.service with switch statement
      validations = ValidationService.addValidation(validations, elementValidation);
    });

    return validations;
  }

  getFormattedPostalCode(postalCode: string): string {
    return getFormattedPostalCode(postalCode);
  }

  setCountryCode(postalCode: string): string {
    if (postalCode === null) {
      return '';
    }
    const postalCodeLength: CountryPostalLengths = postalCode.length;

    if (postalCodeLength === CountryPostalLengths.USAPostal) {
      return Country.USA;
    }
    if (postalCodeLength === CountryPostalLengths.CANPostalWS || postalCodeLength === CountryPostalLengths.CANPostalWIS) {
      return Country.CAN;
    }

    return '';
  }

  private _setDefault(element: Field): ExtendedFormControl {
    const field: ExtendedFormControl = new FormControl();
    field.label = element.label;
    field.setValue(element.default);
    if (element.readOnly) {
      field.disable();
    }
    // only for tax fields
    if (element.taxPercentage) {
      field.taxPercentage = element.taxPercentage / 100.0;
    }

    return field;
  }
}


