import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';

import { ContractType } from '@ph-model/api';
import { Filter } from '@ph-model/filters/filter';
import { SearchFilter } from '@ph-model/filters/search-filter';

import { SortingService } from './sorting.service';

@Injectable({ providedIn: 'root' })
export class HistoryService {
  static fetchContractGroup(contracts: ContractType[]): Filter[] {
    return contracts.reduce((prevVal, elem: ContractType) => {
      const index = prevVal.some((item) => item.name === elem.contractGroup1);

      if (!index) {
        prevVal.push(new Filter(elem.contractGroup1));
      }

      return prevVal;
    }, []).sort((a: Filter, b: Filter) => new Date(b.name).getTime() - new Date(a.name).getTime());
  }

  static fetchStatus(contracts: ContractType[]): Filter[] {
    const status = contracts.reduce((prevVal, elem: ContractType) => {
      // const statusName = this.getStatusName(elem.status);
      const index = prevVal.some((item) => item.name === elem.status);

      if (!index) {
        prevVal.push(new Filter(elem.status));
      }

      return prevVal;
    }, []);

    return SortingService.sortStrings(status);
  }

  static fetchFilters(contracts: ContractType[]): SearchFilter {
    if (contracts) {
      const month: Filter[] = this.fetchContractGroup(contracts);
      const status: Filter[] = this.fetchStatus(contracts);

      return new SearchFilter(month, status);
    }
  }

  ////////// STATIC FILTER CONTRACTS ////////////////////////////////////////////////////////
  static filter(filters: SearchFilter, contracts: ContractType[]): ContractType[] {
    let filteredContracts = this.filterByGroup(filters, contracts);
    filteredContracts = this.filterByStatus(filters, filteredContracts);
    filteredContracts = this.filterBySearchPhrase(filters.searchPhrase, filteredContracts);

    return filteredContracts;
  }

  static filterByGroup(pendingContractFilter: SearchFilter, contracts: ContractType[]): ContractType[] {
    let allContracts = true;
    // Filter products by product name
    const filteredPendingContracts = contracts.filter((data: ContractType) => {
      const index = pendingContractFilter.month.findIndex((x) => x.name === data.contractGroup1);

      if (index > -1) {
        // uncheck allProduct button
        if (pendingContractFilter.month[index].selected) {
          allContracts = false;
        }

        return pendingContractFilter.month[index].selected;
      } else {
        return true;
      }
    });

    if (allContracts) {
      return contracts;
    }

    return filteredPendingContracts;
  }

  static filterByStatus(pendingContractFilter: SearchFilter, contracts: ContractType[]): ContractType[] {
    let allContracts = true;
    // reset term filter display
    pendingContractFilter.status.forEach((_: Filter, index: number) => {
      pendingContractFilter.status[index].display = false;
    });

    // Filter products by term
    const filteredContracts = contracts.filter((data) => {
      const index = pendingContractFilter.status.findIndex((x) => x.name === data.status);

      if (index > -1) {
        // uncheck allProduct button

        if (pendingContractFilter.status.some(({ selected }) => selected)) {
          allContracts = false;
        }

        pendingContractFilter.status[index].display = true;

        return pendingContractFilter.status[index].selected;
      } else {
        return true;
      }
    });

    if (allContracts) {
      return contracts;
    }

    return filteredContracts;
  }

  static filterBySearchPhrase(searchText: string, contracts: ContractType[]): ContractType[] {
    if (!searchText) {
      return contracts;
    }

    return contracts.filter((contract: ContractType) => {
      return (
        `${contract.firstName} ${contract.lastName} ${contract.businessName}`
          .toLowerCase()
          .includes(searchText.toLowerCase()) || contract.vin.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }

  static adaptStatusesToPPWL(contracts: ContractType[]): ContractType[] {
    if (environment.application === 'powerprotectdealercom') {
      return contracts.map((contract: ContractType) => ({
        ...contract,
        status: contract.status.toLowerCase() === 'registered' ? 'Remitted' : contract.status,
      }));
    } else {
      return contracts;
    }
  }
}
