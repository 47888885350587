import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';

import { TabsComponent } from '../tabs/tabs.component';

@Component({
  selector: 'app-tab',
  template: '<ng-content />',
  styleUrls: ['./tab.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  title: InputSignal<string> = input.required();

  active: boolean = false;

  constructor(tabs: TabsComponent) {
    tabs.addTab(this);
  }
}
