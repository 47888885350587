import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'ph-accordion',
  template: '<ng-content />',
  styleUrls: ['./accordion.component.scss'],
  standalone: true,
})
export class AccordionComponent {
  autocollapse: InputSignal<string | boolean> = input<boolean, string | boolean>(false, {
    transform: coerceBooleanProperty,
  });
}
