import { ProductFilters } from './product-filters';

export interface ProductFilter {
  name: string;
  selected: boolean;
  display: boolean;
  property: keyof ProductFilters;
  label?: string;
}

export const defaultProductFilterSetup: Omit<ProductFilter, 'name' | 'property' | 'label'> = {
  selected: false,
  display: true,
};
