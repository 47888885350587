import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ProductImage } from '@ph-model/api/product-images.model';
import { PrismicData, PrismicDataResponse } from '@ph-model/api/response/prismic-response.model';
import { setClientCssValues } from '@ph-shared/utils';
import { PrismicService } from '@ph-store/prismic/prismic.service';

import {
  getProductImages,
  getProductImagesFailure,
  getProductImagesSuccess,
  loadPrismicData,
  loadPrismicDataFailure,
  loadPrismicDataSuccess,
} from './prismic.actions';

@Injectable()
export class PrismicEffects {
  constructor(
    private actions$: Actions,
    private prismicService: PrismicService
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getPrismicData$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(loadPrismicData),
      switchMap(() =>
        this.prismicService.getPrismicData().pipe(
          map((response: PrismicDataResponse) => {
            // Set Client CSS to global variables
            setClientCssValues(response.results[0].data.variables[0]);

            const data: PrismicData = {
              ...response.results[0].data,
              products: this.prismicService.remapProductImages(response.results[0].data.products),
            };

            return loadPrismicDataSuccess({ data });
          }),
          catchError((error) => {
            console.error('Prismic response error: ', error);

            return of(loadPrismicDataFailure({ error }));
          })
        )
      )
    ) }
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getProductImages$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(getProductImages),
      switchMap(() =>
        this.prismicService.getPrismicProductImages().pipe(
          map((productImages: { [key: string]: ProductImage }) => getProductImagesSuccess({ productImages })),
          catchError(() => of(getProductImagesFailure()))
        )
      )
    ) }
  );
}
