@if (label) {
  <ph-form-control-label
    [label]="label()"
    [required]="required()"
    [disabled]="disabled || inactive()"
    [invalid]="showError"
  >
    <ng-content select="[label]" />
  </ph-form-control-label>
}

<div class="input-block">
  <input
    ui-ph-input
    class="input"
    [formControl]="date"
    [placeholder]="placeholder()"
    [invalid]="showError"
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="datepicker"
    (dateChange)="datepickerDateChange($event)"
    phDateMask
    [class.disabledInput]="disabled || inactive()"
    [attr.disabled]="disabled || inactive()"
    #input
  />
  <div class="input-suffix">
    <mat-datepicker-toggle [disabled]="disabled || inactive()" matSuffix [for]="datepicker" />
  </div>

  <mat-datepicker #datepicker />
</div>

@if (showError) {
  <ph-form-control-error [errorMessage]="errorMessage" />
}
