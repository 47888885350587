import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  CONTRACT_HISTORY_CONFIGURATION,
  ContractHistoryConfiguration,
} from '../models/contract-history-configuration.model';
import { ContractOperationRecord } from '../models/contract-operation-record.model';

@Injectable()
export class ContractHistoryService {
  constructor(
    @Inject(CONTRACT_HISTORY_CONFIGURATION) private contractHistoryConfiguration: ContractHistoryConfiguration,
    private httpClient: HttpClient
  ) {}

  getContractHistory(contractId: string): Observable<ContractOperationRecord[]> {
    return of(contractId).pipe(
      switchMap((contractId) =>
        this.httpClient.get<ContractOperationRecord[]>(
          `${this.contractHistoryConfiguration.apiRoot}/contractactivity/${contractId}`
        )
      )
    );
  }
}
