import { Field } from '@ph-model/form/field';

export const financeForm: Field[] = [
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'financeType',
    label: 'Finance Type',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'financeAmount',
    label: 'Finance Amount',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'apr',
    label: 'APR',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'loanTerm',
    label: 'Loan Term',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'monthlyPayment',
    label: 'Monthly Payment',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'amortizationPeriod',
    label: 'Amortization Period',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  // {
  //   section: 'financeForm',
  //   id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
  //   name: 'monthsUntilBalloon',
  //   label: 'Months until Balloon',
  //   display: true,
  //   required: false,
  //   readOnly: false,
  //   default: '',
  //   validations: [],
  // },
  {
    section: 'financeForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'residualValue',
    label: 'Balloon Residual',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
];
