import { RetailRateType } from '@ph-core/enums';
import { ChildProducts, PlanName, ProductDescription, SurchargeOptions, Tax } from '@ph-model/response/interface';

export class ProductItem {
  dealer?: string;
  vendor?: string;
  productType: string;
  productCode: string;
  productCategory: string;
  productDescription: ProductDescription;
  planCode: string;
  planName: PlanName;
  planSKU: string;
  usageType: string;
  minTerm: string;
  maxTerm: string;
  isBundled: boolean;
  isDiscounted: boolean;
  termMileage: string;
  termInput?: string;
  deductible: string;
  disappearingDeductible: boolean;
  vehicleClass: string;
  financeAmountFrom: string;
  financeAmountTo: string;
  msrpTo: string;
  msrpFrom: string;
  vehicleSellingPriceFrom: string;
  vehicleSellingPriceTo: string;
  grossCapCostFrom: string;
  grossCapCostTo: string;
  dealerCost: number;
  regulatedRate?: string;
  retailRateType: RetailRateType;
  retailRate: number;
  surchargeOptions: SurchargeOptions;
  startingInterval: string;
  formNumber: string;
  formDescription: string;
  vehicleClassing: string;
  coverageCode: string;
  coverageDescription: string;
  coverageOptions: { coverageOption: string[] };
  minDollarAmount: string;
  maxDollarAmount: string;
  paymentType: string;
  startingIntervalName: string;
  minOdometer: string;
  maxOdometer: string;
  programType: string;
  planType: string;
  childProducts: ChildProducts;
  taxes?: Tax;
  externalProductReferenceCode?: string;
  vehicleCondition?: string;
  riskType?: string;

  // retail error handler
  customerCost?: number;
  costPlaceholder?: number = 0;
  totalCost?: number;

  maxCost?: number;

  // taxes
  qstTax?: number;
  gstTax?: number;
  pstTax?: number;
  hstTax?: number;

  // for display purposes
  surchargeAdded?: boolean;
  isAddedToCart?: boolean;
  key?: string;

  // for display purposes - used for taxes
  displayCost?: number;
  customerTerm?: number;
  customerTermError?: boolean;
  errorFlag?: boolean;
  errorMessage?: string;

  showMilesAndDeductible?: boolean;
  unique?: string;

  // GM specific properties
  programAgentCode?: string;
  hideDealerCost?: boolean;

  // PowerUp flag
  isPowerUpProduct?: boolean;

  baseDealerCost?: number;
  baseRetailRate?: number;
  manualCustomerCostDelta?: number;
}
