import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, output, OutputEmitterRef } from '@angular/core';

import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  tabClick: OutputEmitterRef<string> = output<string>();

  tabs: TabComponent[] = [];

  selectTab(tab: TabComponent): void {
    this.tabs.forEach((t: TabComponent) => (t.active = false));
    tab.active = true;
    this.tabClick.emit(tab.title());
  }

  addTab(tab: TabComponent): void {
    if (!this.tabs.length) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  trackByTitle(_: number, tab: TabComponent): string {
    return tab.title();
  }
}
