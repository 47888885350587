import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { Vendor } from '@ph-core/enums';
import { TokenService } from '@ph-core/services/auth/token.service';
import { ConfigService } from '@ph-core/services/config/config.service';
import { ErrorService } from '@ph-core/services/error/error.service';
import { resetLoading, setLoading } from '@ph-store/config/config.actions';
import { logout } from '@ph-store/user/user.actions';

import { notificationDefaultDuration } from './static-data/notification-durations';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private _apiCallsToIgnoreLoading: string[] = [
    '/refreshtoken',
    '/getlienholder',
    '/getclientinfo',
    '/getcaptcha',
    '/verifyusername',
    '/contractactivity',
  ];
  private _apiCallsToIgnoreHidingError: string[] = ['/refreshtoken', '/manualdecode', '/getcaptcha'];
  private _apiCallsToIgnoreToken: string[] = ['token', 'creditins', 'ssologin'];
  private _apiErrorsToIgnore: { url: string; status: number }[] = [
    { url: '/manualdecode', status: 330 },
    { url: '/updateStatusEContract', status: 0 }, // status: 0 to ignore all statuses
    { url: '/savePowerUpEContract', status: 0 }, // status: 0 to ignore all statuses
    { url: '/saveAncillaryEContract', status: 0 }, // status: 0 to ignore all statuses
  ];

  constructor(
    private configService: ConfigService,
    private errorService: ErrorService,
    private tokenService: TokenService,
    private store: Store
  ) {}

  intercept<T, B>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<B>> {
    let byPassLoading = false;
    this._apiCallsToIgnoreLoading.forEach((url) => {
      byPassLoading ||= request.url.includes(url);
    });

    // Bypass loading bar when API calls are made for refresh token
    if (!byPassLoading && !request.url.endsWith('/refreshtoken')) {
      this.store.dispatch(setLoading());
    }

    const bypassToken = this._apiCallsToIgnoreToken.reduce(
      (prev, url) => prev || request.url.split(/[\/\?]+/).includes(url),
      false
    );

    let token = '';
    const tokenValue = this.tokenService.getToken();
    if (tokenValue) {
      token = `Bearer ${tokenValue}`;
    }

    let langCode: SupportedLanguageCodes | '' = '';
    if (this.configService.getLanguage()) {
      langCode = this.configService.getLanguage();
    }

    let callToken = token;
    if (request.headers.get('TokenType') === 'looker') {
      callToken = request.headers.get('Authorization');
    }

    let authReq = request;

    if (!bypassToken) {
      const customLocale = request?.body && request.body['locale-to-be-removed'];

      authReq = request.clone({
        headers: new HttpHeaders({
          Authorization: callToken,
          'Accept-Language': customLocale ? customLocale : langCode,
        }),
      });

      if (customLocale) {
        delete request.body['locale-to-be-removed'];
      }
    }

    return next.handle(authReq).pipe(
      tap((response: HttpResponse<B>) => {
        if (response.status === 200) {
          let noByPass = false;

          this._apiCallsToIgnoreHidingError.forEach((url) => {
            if (request.url.endsWith(url)) {
              noByPass = true;
            }
          });
          if (!noByPass) {
            this.errorService.hideErrorMessage();
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // Network Error - When service is down
        if (err.status === 403) {
          this.store.dispatch(logout({ newVersion: false, version: '' }));
        }

        if (err.status === 0) {
          err = new HttpErrorResponse({
            status: 100,
            statusText: 'Network Error - service may be down, please try again later.',
          });
        }

        if (this._shouldDisplayError(request.url, err.status)) {
          let text = err?.error?.responseDescription || err.statusText;
          let duration = notificationDefaultDuration;
          if (
            !environment.features.supportManualVinDecoding &&
            environment.vendorName === Vendor.GM &&
            request.url.includes('/vindecode/v2')
          ) {
            text =
              'VIN details do not exist in the system. Contact Dealer Support dealersupport@gmprotection.com or (833) 955-0051';
            duration = 15000;
          }
          this.errorService.displayErrorMessage(err.status, text, duration);
        }

        return throwError(() => err);
      }),
      finalize(() => this.store.dispatch(resetLoading()))
    );
  }

  private _shouldDisplayError(url: string, status: number): boolean {
    return !this._apiErrorsToIgnore.some(
      (call) => url.includes(call.url) && (status === call.status || call.status === 0)
    );
  }
}
