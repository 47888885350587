import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { PhOverlayRef } from '@ph-core/services/overlay';
import { LazyTemplateService } from '@ph-core/services/template/lazy-template.service';
import { Coverage, InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { CreditInsuranceCoverageOption } from '@ph-model/form/credit-insurance-coverage-option.model';
import { OptionType } from '@ph-model/form/option-type.model';
import { untilDestroyed } from '@ph-shared/utils';
import {
  selectCreditInsurancePackageCoverageOptions,
  selectLoanProtectionCoverageOptions,
  selectRequestedQuote,
} from '@ph-store/cart/cart.selectors';
import { selectBrandLogoUrl } from '@ph-store/prismic/prismic.selectors';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  templateUrl: './requested-quote-popup.component.html',
  styleUrls: ['./requested-quote-popup.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormInputControlComponent, PhButtonComponent, TranslateModule, IconComponent],
})
export class RequestQuotePopupComponent implements OnInit {
  requestQuoteForm: FormGroup = new FormGroup({});

  private templateService = inject(LazyTemplateService);
  private _navbarLogo: string = '';
  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private translate: TranslateService,
    private store: Store,
    private fb: FormBuilder,
    private ref: PhOverlayRef<RequestQuotePopupComponent>
  ) {}

  ngOnInit(): void {
    this.requestQuoteForm = this.fb.group({ coverages: this.fb.array([]) });

    this.store
      .select(selectBrandLogoUrl)
      .pipe(
        tap((logoUrl: string) => (this._navbarLogo = logoUrl)),
        this.#untilDestroyed()
      )
      .subscribe();

    combineLatest([
      this.store.select(selectRequestedQuote),
      this.store.select(selectLoanProtectionCoverageOptions),
      this.store.select(selectCreditInsurancePackageCoverageOptions),
    ])
      .pipe(
        filter(
          ([requestedQuote]: [
            { insResponse: InsuranceResponseModel },
            OptionType[],
            CreditInsuranceCoverageOption[],
          ]) => requestedQuote.insResponse.insurance.coverage.length > 0
        ),
        this.#untilDestroyed()
      )
      .subscribe(([requestedQuote, loanProtectionCoverageOptions, creditInsurancePackageCoverageOptions]) => {
        const coverageDetails = requestedQuote.insResponse.insurance.coverage;
        const coverageOptionDescriptions = [
          ...loanProtectionCoverageOptions,
          ...creditInsurancePackageCoverageOptions,
        ].reduce((acc: { [key: string]: string }, option: OptionType | CreditInsuranceCoverageOption) => {
          if (typeof option.value === 'string') {
            acc[option.value] = this.translate.instant(option.description);
          } else {
            acc[option.value.single] = this.translate.instant(option.description);
            acc[option.value.joint] = this.translate.instant(option.description);
          }

          return acc;
        }, {});

        const arr: FormArray = this.requestQuoteForm.get('coverages') as FormArray;
        while (arr.length) {
          arr.removeAt(0);
        }

        const groupedByBorrower: {
          [key: string]: {
            coverage: Coverage;
            coverageNames: string[];
            terms: string[];
            premiums: string[];
            taxes: string[];
          };
        } = {};

        for (const cov of coverageDetails) {
          const coverages = groupedByBorrower[cov.borrCovered] || {
            coverage: cov,
            coverageNames: [],
            terms: [],
            premiums: [],
            taxes: [],
          };
          coverages.coverageNames.push(coverageOptionDescriptions[cov.covCode] || cov.covCode);
          coverages.premiums.push(cov.premium);
          coverages.taxes.push(cov.tax);
          coverages.terms.push(cov.insTerm);
          groupedByBorrower[cov.borrCovered] = coverages;
        }

        for (const key of Object.keys(groupedByBorrower)) {
          const value = groupedByBorrower[key];
          arr.push(
            this.fb.group({
              ...value.coverage,
              coverage: value.coverageNames.join(', '),
              insTerm: value.terms.join(', '),
              premium: this._formatSum(value.premiums),
              tax: this._formatSum(value.taxes),
              borrower: this._getBorrowerLabel(value.coverage.borrCovered),
            })
          );
        }
      });
  }

  printQuote(): void {
    this.templateService.printQuote(this._navbarLogo);
    this.ref.close();
  }

  close(): void {
    this.ref.close();
  }

  private _getBorrowerLabel(borrowerCode: string): string {
    switch (borrowerCode) {
      default:
      case '1':
        return this.translate.instant('shoppingPage.checkout.grid.primaryBorrower');
      case '2':
        return this.translate.instant('shoppingPage.checkout.grid.coBorrower');
      case '3':
        return this.translate.instant('shoppingPage.checkout.grid.jointBorrower');
    }

    return borrowerCode;
  }

  private _formatSum(values: string[]): string {
    if (values.length === 1) {
      return values[0];
    }

    return (
      values.join(', ') + ' (Total ' + values.reduce((sum: number, prem: string) => sum + +prem, 0).toFixed(2) + ')'
    );
  }
}
