import { createAction, props } from '@ngrx/store';

import { Claim } from '@ph-model/api/response/claims.response';
import { SearchFilter } from '@ph-model/filters/search-filter';

export const getClaims = createAction('[Claims] GET CLAIMS');
export const getContractClaims = createAction('[Claims] GET CONTRACT CLAIMS', props<{ contractNumber: string }>());
export const getClaimsSuccess = createAction(
  '[Claims] GET CLAIMS SUCCESS',
  props<{ claims: Claim[]; filters: SearchFilter }>()
);
export const filterClaimsGrid = createAction('[Claims] FILTER GRID', props<{ filter: SearchFilter }>());
export const claimsFailure = createAction('[Claims] CLAIMS FAILURE');
