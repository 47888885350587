import { Injectable } from '@angular/core';

import { environment } from '@ph-env/environment';

import { ContractType } from '@ph-model/api';
import { PendingContractResponse } from '@ph-model/api/response/pending-contract.response.model';
import { ContractFilter } from '@ph-model/filters/contract-filter.model';
import { Filter } from '@ph-model/filters/filter';

import { hideDealerCostProductCodes } from 'app/static-data/hide-dealer-cost';

@Injectable()
export class RemitService {
  static fetchContractGroups(contracts: ContractType[]): Filter[] {
    return contracts.reduce((prevVal, elem: ContractType) => {
      const index = prevVal.some((item) => item.name === elem.contractGroup1);
      if (!index) {
        prevVal.push(new Filter(elem.contractGroup1));
      }

      return prevVal;
    }, []).sort((a: ContractFilter, b: ContractFilter) => new Date(b.name).getTime() - new Date(a.name).getTime());
  }

  static fetchRemittedContractGroups(contracts: ContractType[]): ContractFilter[] {
    return contracts.reduce((prevVal, elem: ContractType) => {
      const index = prevVal.some((item) => item.name === elem.contractGroup1);
      if (!index) {
        const filterGroup = this.fetchRemittedContractGroup2(elem.contractGroup1, contracts);
        prevVal.push(new ContractFilter(elem.contractGroup1, false, filterGroup));
      }

      return prevVal;
    }, []).sort((a: ContractFilter, b: ContractFilter) => new Date(b.name).getTime() - new Date(a.name).getTime());
  }

  static fetchRemittedContractGroup2(contractGroup1: string, contracts: ContractType[]): Filter[] {
    return contracts.reduce((prevVal, elem: ContractType) => {
      if (elem.contractGroup1 === contractGroup1) {
        const index = prevVal.some((item) => item.name === elem.contractGroup2);
        if (!index) {
          prevVal.push(new Filter(elem.contractGroup2));
        }
      }

      return prevVal;
    }, []).sort((a: ContractFilter, b: ContractFilter) => new Date(b.name).getTime() - new Date(a.name).getTime());
  }

  static filterBySearchTerm(searchTerm: string, contracts: ContractType[]): ContractType[] {
    if (!searchTerm) {
      return contracts;
    }

    return contracts.filter((contract: ContractType) => {
      return (
        `${contract.firstName} ${contract.lastName} ${contract.businessName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) || contract.vin.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  ////////// STATIC FILTER PRODUCTS ////////////////////////////////////////////////////////
  static filterPendingGrid(
    pendingContractFilter: Filter[],
    searchTerm: string,
    contracts: ContractType[]
  ): ContractType[] {
    let filteredContracts = this.filterBySearchTerm(searchTerm, contracts);

    const checkedFilters = pendingContractFilter.filter((filter) => filter.selected);
    if (checkedFilters.length > 0) {
      filteredContracts = filteredContracts.filter((contract) =>
        checkedFilters.some((filter) => filter.name === contract.contractGroup1)
      );
    }

    return filteredContracts;
  }

  static filterRemittedGrid(
    remittedContractFilter: ContractFilter[],
    searchTerm: string,
    contracts: ContractType[]
  ): ContractType[] {
    let filteredContracts = this.filterBySearchTerm(searchTerm, contracts);

    const checkedMonthFilters = remittedContractFilter.filter((filter) => filter.selected);

    if (checkedMonthFilters.length > 0) {
      filteredContracts = filteredContracts.filter((contract) =>
        checkedMonthFilters.some((filter) => filter.name === contract.contractGroup1)
      );

      const checkedDayFilters = [].concat(
        ...checkedMonthFilters.map((monthFilter) => monthFilter.batch.filter((dayFilter) => dayFilter.selected))
      );

      if (checkedDayFilters.length > 0) {
        filteredContracts = filteredContracts.filter((contract) =>
          checkedDayFilters.some((filter) => filter.name === contract.contractGroup2)
        );
      }
    }

    return filteredContracts;
  }

  // /////////////////////////////////////////////////////////////////////////
  ///////////////////  VALIDATION FUNCTIONS  /////////////////////////////////
  static declineAndUpdateContracts(
    pendingGridContracts: ContractType[],
    contractToDecline: ContractType
  ): ContractType[] {
    if (contractToDecline.dupError) {
      // check for more than 1 duplicate. if more than 1, do not change change duplicate flag.
      let dupNums = 0;
      contractToDecline.dupArray.forEach((dupContractNumber: string) => {
        const otherDupContract = pendingGridContracts.findIndex(
          (contract: ContractType) => contract.econContractNumber === dupContractNumber
        );
        if (otherDupContract > -1) {
          if (pendingGridContracts[otherDupContract].dupError) {
            dupNums += 1;
          }
        }
      });

      contractToDecline.dupArray.forEach((dupContractNumber: string) => {
        const otherDupContract = pendingGridContracts.findIndex(
          (contract: ContractType) => contract.econContractNumber === dupContractNumber
        );
        if (otherDupContract > -1) {
          if (pendingGridContracts[otherDupContract].dupError && dupNums <= 1) {
            pendingGridContracts[otherDupContract].dupError = false;
          }
        }
      });
    }

    contractToDecline.decline = true;
    contractToDecline.dupError = false;

    const foundContractIndx = pendingGridContracts.findIndex(
      (contract: ContractType) => contract.econContractNumber === contractToDecline.econContractNumber
    );

    pendingGridContracts[foundContractIndx] = contractToDecline;

    return pendingGridContracts;
  }

  static reinstateAndUpdateContracts(
    pendingGridContracts: ContractType[],
    contractToReinstate: ContractType
  ): ContractType[] {
    // check for duplicate array to see if have not been remitted/declined.
    // if any still in this list and was a original dup with no decline/remit, then change the
    // contract to duplicate.
    if (contractToReinstate.origDup) {
      let actionTakenOnAllDuplicates = true;

      contractToReinstate.dupArray.forEach((dupContractNumber: string) => {
        const otherDupContract = pendingGridContracts.findIndex(
          (contract: ContractType) => contract.econContractNumber === dupContractNumber
        );
        if (otherDupContract > -1) {
          const currentContract = pendingGridContracts[otherDupContract];
          // if action hasnt been taken on ANY contracts whether its declined or remitted
          if (currentContract.origDup && currentContract.addToRemit) {
            pendingGridContracts[otherDupContract].addToRemit = false;
          }
        }
      });

      contractToReinstate.dupArray.forEach((dupContractNumber: string) => {
        const otherDupContract = pendingGridContracts.findIndex(
          (contract: ContractType) => contract.econContractNumber === dupContractNumber
        );
        if (otherDupContract > -1) {
          const currentContract = pendingGridContracts[otherDupContract];
          // if action hasn't been taken on ANY contracts whether its declined or remitted
          if (currentContract.origDup && !currentContract.decline && !currentContract.addToRemit) {
            pendingGridContracts[otherDupContract].dupError = true;
            actionTakenOnAllDuplicates = false;
          }
        }
      });

      // if action has been taken on all duplicates then do not set duplicate to true
      if (actionTakenOnAllDuplicates) {
        contractToReinstate.decline = false;
        contractToReinstate.dupError = false;
      } else {
        contractToReinstate.decline = false;
        contractToReinstate.dupError = true;
      }
    } else {
      contractToReinstate.decline = false;
      contractToReinstate.dupError = false;
    }

    const foundContractIndx = pendingGridContracts.findIndex(
      (contract: ContractType) => contract.econContractNumber === contractToReinstate.econContractNumber
    );

    pendingGridContracts[foundContractIndx] = contractToReinstate;

    return pendingGridContracts;
  }

  ///////////////////  RESET CONTRACTS  /////////////////////////////////
  static resetContracts(response: PendingContractResponse): PendingContractResponse {
    response.pendingContracts = response.pendingContracts.map((contract: ContractType) => {
      contract.addToRemit = false;
      contract.decline = false;

      return contract;
    });

    return response;
  }

  static handleDealerCost(contracts: ContractType[]): ContractType[] {
    const isSupressDealerCost = environment.features.suppressDealerCost;
    if (isSupressDealerCost) {
      contracts.forEach((contract: ContractType) => {
        contract.hideDealerCost = hideDealerCostProductCodes.includes(contract.productCode);
        contract.contractDealerCost = contract.hideDealerCost ? 0 : contract.contractDealerCost;
      });
    }

    return contracts;
  }
}
