import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostListener, input, InputSignal } from '@angular/core';

import { numberKeys } from '@ph-static-data/keyboard-key-codes';

@Directive({
  selector: '[numbersOnly]',
  standalone: true,
})
export class NumberDirective {
  numbersOnly: InputSignal<boolean | string> = input.required({ transform: coerceBooleanProperty });

  private readonly _keycodes = numberKeys;

  @HostListener('keydown', ['$event']) onInputChange(ev: KeyboardEvent): void {
    if (this.numbersOnly()) {
      const isCopyPasteAction = (ev.metaKey || ev.ctrlKey) && (ev.key === 'c' || ev.key === 'v');
      if (!this._keycodes.includes(ev.key) && !isCopyPasteAction) {
        ev.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(ev: ClipboardEvent): void {
    if (this.numbersOnly()) {
      const clipboardData = ev.clipboardData || window['clipboardData'];
      const pastedData = clipboardData.getData('Text');
      const reg = new RegExp('^[0-9]+$');

      if (!reg.test(pastedData)) {
        ev.preventDefault();
      }
    }
  }
}
