<div class="labels-container">
  <ul class="tabs-block">
    <li
      class="tabs-item"
      *ngFor="let tab of tabs; trackBy: trackByTitle"
      [class.active]="tab.active"
      (click)="selectTab(tab)"
    >
      <span class="tabs-item-title">
        {{ tab.title() }}
      </span>
    </li>
  </ul>
</div>
<ng-content />
