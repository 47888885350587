import { environment } from '@ph-env/environment';

import { isNonReg } from '@ph-core/enums';
import { SurchargeSelectionItem } from '@ph-model/filters/surcharge-selection-item';
import { Markup } from '@ph-model/login';
import { ProductItem } from '@ph-model/response';

export const recalculateCustomerCost = (
  product: ProductItem,
  markup: Markup,
  surchargesSelection: SurchargeSelectionItem[]
): ProductItem =>
  correctForRange(applyManualOverride(applySurcharges(applyMarkup(product, markup), surchargesSelection)));

const applyManualOverride = (product: ProductItem): ProductItem => ({
  ...product,
  customerCost: product.manualCustomerCostDelta + product.customerCost,
});
const applyMarkup = (product: ProductItem, markup: Markup): ProductItem => {
  let customerCost;
  if (
    product.retailRateType !== 'FIXED' &&
    product.retailRateType !== 'NON-REG-CAP' &&
    product.retailRateType !== 'MAX'
  ) {
    if (environment.features.zeroCustomerCostForNonRegRates && isNonReg(product.retailRateType)) {
      customerCost = 0;
    } else if (environment.features.useMarkup) {
      switch (markup.markupType) {
        case '$': {
          customerCost = product.baseDealerCost + +markup.markupDollar;
          break;
        }
        case 'x': {
          customerCost = product.baseDealerCost * +markup.markupMultiplier;
          break;
        }
        default: {
          customerCost = product.baseDealerCost;
          break;
        }
      }
    } else {
      customerCost = product.baseDealerCost;
    }
  } else {
    customerCost = +product.retailRate;
  }

  return {
    ...product,
    customerCost,
    costPlaceholder: +product.retailRate,
  };
};

const applySurcharges = (product: ProductItem, surchargesSelection: SurchargeSelectionItem[]): ProductItem => {
  if (!surchargesSelection) {
    return product;
  }
  const productItem = { ...product, dealerCost: product.baseDealerCost, retailRate: product.baseRetailRate };
  productItem.surchargeAdded = false;
  surchargesSelection.forEach((selection: SurchargeSelectionItem) => {
    const matchingInProduct = productItem.surchargeOptions.surchargeOption.filter(
      ({ label }) => (label.enUsLabel || label.frCaLabel)?.trim() === selection.name?.trim()
    );
    if (matchingInProduct.length === 1) {
      const optionInProduct = matchingInProduct[0];
      if (selection.selected) {
        productItem.customerCost = Number(productItem.customerCost) + Number(optionInProduct.surchargeAmount);
        productItem.dealerCost = Number(productItem.dealerCost) + Number(optionInProduct.surchargeAmount);
      }

      optionInProduct.added = selection.selected;
      if (selection.selected) {
        productItem.surchargeAdded = true;
      }
    }
  });

  return productItem;
};

const correctForRange = (product: ProductItem): ProductItem => {
  let { manualCustomerCostDelta, customerCost } = product;
  if (product.customerCost < 0) {
    manualCustomerCostDelta = product.manualCustomerCostDelta - product.customerCost;
    customerCost = 0;
  }

  if (product.retailRateType === 'MAX' && product.customerCost > product.retailRate) {
    manualCustomerCostDelta = product.manualCustomerCostDelta + product.retailRate - product.customerCost;
    customerCost = product.retailRate;
  }

  if (product.retailRateType === 'NON-REG-CAP' && product.customerCost > product.maxCost) {
    manualCustomerCostDelta = product.manualCustomerCostDelta + product.maxCost - product.customerCost;
    customerCost = product.maxCost;
  }

  return { ...product, manualCustomerCostDelta, customerCost };
};

export const productResponseDataMapper = (products: ProductItem[]): ProductItem[] =>
  products?.map((product: ProductItem) => ({
    ...product,
    customerTerm: product.customerTerm ? product.customerTerm : +product.maxTerm,
    baseDealerCost: product.baseDealerCost || +product.dealerCost,
    baseRetailRate: product.baseRetailRate || +product.retailRate,
    manualCustomerCostDelta: product.manualCustomerCostDelta || 0,
    errorFlag: false,
    errorMessage: '',
  }));
