import { Field } from '@ph-model/form/field';

export const coverageInfoForm: Field[] = [
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF5HAS0',
    name: 'coverageSelection',
    label: 'coverageSelection',
    display: true,
    required: false,
    readOnly: false,
    default: 'loanProtection',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: '73E2BFAD-8E8F-4198-A015-7A112236D2AC',
    name: 'coverageOptions',
    label: 'Coverage Options',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: '73E2BFAD-8E8F-4198-A015-7A112236D2AD',
    name: 'coverageOptions2',
    label: 'Coverage Options',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236R0AC',
    name: 'borrowerCoverage',
    label: 'Borrower Coverage',
    display: true,
    required: true,
    readOnly: false,
    default: '1',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB2',
    name: 'borrowerCoverage2',
    label: 'Borrower Coverage',
    display: true,
    required: false,
    readOnly: false,
    default: '2',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB0',
    name: 'insuranceTerm',
    label: 'Insurance Term',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'insuranceTerm2',
    label: 'Insurance Term',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'lifeInsuranceTerm',
    label: 'Insurance Term',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'criticalIllnessInsuranceTerm',
    label: 'Insurance Term',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'disabilityInsuranceTerm',
    label: 'Insurance Term',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'lifeCoverageOptions',
    label: 'Life Benefit',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'criticalIllnessCoverageOptions',
    label: 'Critical Illness',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'criticalIllnessBorrowerCoverage',
    label: 'Critical Illness',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'disabilityCoverageOptions',
    label: 'Disability (A&S)',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'disabilityBorrowerCoverage',
    label: 'Disability (A&S)',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'coverageInfoForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB1',
    name: 'dismemberment',
    label: 'Dismemberment',
    display: true,
    required: false,
    readOnly: false,
    default: false,
    validations: [],
  },
];
