import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureKey, ShoppingStepperState } from './shopping-stepper.reducer';
import { ShoppingStep } from '../models/shopping-step.model';

export const flattenedSteps = (steps: ShoppingStep[]): ShoppingStep[] => {
  if (!steps) {
    return;
  }
  let collected = [];
  for (const step of steps) {
    collected = [...collected, step];
    if (step.paths?.length && step.currentPath) {
      const path = step.paths.find((v) => (v.name as string) === step.currentPath);
      if (path) {
        collected = [...collected, ...path.steps];
      }
    }
  }

  return collected;
};

export const selectShoppingStepperState = createFeatureSelector<ShoppingStepperState>(featureKey);

export const selectCurrentShoppingSteps = createSelector(selectShoppingStepperState, (state) =>
  flattenedSteps(state.steps)
);

export const selectCurrentShoppingStepIndex = createSelector(
  selectShoppingStepperState,
  (state) => state.currentStepIdx
);

export const selectCurrentStep = createSelector(selectShoppingStepperState, (state) => state.currentStep);

export const selectShoppingStepIndexByUrl = (url: string) =>
  createSelector(selectCurrentShoppingSteps, (steps) => steps.findIndex((elem) => (elem.url as string) === url));

export const selectShoppingStepperData = createSelector(selectShoppingStepperState, (state) => state.data);
