import { createReducer, on } from '@ngrx/store';

import { HistoryService } from '@ph-core/services/history.service';
import { ContractType } from '@ph-model/api';
import { SearchFilter } from '@ph-model/filters/search-filter';

import { filterHistoryGrid, getContractsSuccess, resetContracts, undoDeclineContractSuccess } from './history.actions';

export const featureKey = 'historyState';
export interface HistoryState {
  contracts: ContractType[];
  contractFilters: SearchFilter;
  gridContracts: ContractType[];
}

const InitialState: HistoryState = {
  contracts: [],
  contractFilters: new SearchFilter(),
  gridContracts: [],
};

export const reducer = createReducer(
  InitialState,
  on(
    getContractsSuccess,
    (state, { contracts, filters }): HistoryState => ({
      ...state,
      contracts: contracts.contracts,
      gridContracts: contracts.contracts,
      contractFilters: filters,
    })
  ),
  on(
    undoDeclineContractSuccess,
    (state, { contracts, filters, gridContracts }): HistoryState => ({
      ...state,
      contracts,
      gridContracts,
      contractFilters: filters,
    })
  ),
  on(
    filterHistoryGrid,
    (state, { filters }): HistoryState => ({
      ...state,
      contractFilters: { ...filters },
      gridContracts: HistoryService.filter(filters, state.contracts),
    })
  ),
  on(resetContracts, (): HistoryState => ({ ...InitialState }))
);
