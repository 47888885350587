import { Injectable } from '@angular/core';
import { ValidatorFn, FormGroup } from '@angular/forms';

import { Options } from '@ph-core/enums';

import * as fromGenericValidations from '../validations/forms/generic.validation';
import * as fromVinValidations from '../validations/forms/vin.validation';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  static addValidation(validateArray: ValidatorFn[] = [], validation: string): ValidatorFn[] {
    /// Add all Validation checks here
    if (validation === 'postalcode') {
      validateArray.push(fromGenericValidations.ZipCodeValidator);
    }
    /// Add all Validation checks here
    if (validation === 'daterequired') {
      validateArray.push(fromGenericValidations.RequiredDateValidator);
    }
    if (validation === 'email') {
      validateArray.push(fromGenericValidations.EmailValidator);
    }
    if (validation === 'vinlimit') {
      validateArray.push(fromVinValidations.VinLimitValidator);
    }
    if (validation === 'vininvalidchar') {
      validateArray.push(fromVinValidations.VinInvalidCharValidator);
    }
    if (validation === 'pidlimit') {
      validateArray.push(fromVinValidations.PidLimitValidator);
    }
    if (validation === 'pidnvalidchar') {
      validateArray.push(fromVinValidations.PidInvalidCharValidator);
    }
    if (validation === 'tinlimit') {
      validateArray.push(fromVinValidations.TinLimitValidator);
    }
    if (validation === 'tininvalidchar') {
      validateArray.push(fromVinValidations.TinInvalidCharValidator);
    }
    if (validation === 'hinlimit') {
      validateArray.push(fromVinValidations.HinLimitValidator);
    }
    if (validation === 'hininvalidchar') {
      validateArray.push(fromVinValidations.HinInvalidCharValidator);
    }
    if (validation === 'numeric') {
      validateArray.push(fromVinValidations.engineCCValidator);
    }
    if (validation === 'character') {
      validateArray.push(fromVinValidations.characterValidator);
    }
    if (validation === 'engineMaxLength') {
      validateArray.push(fromVinValidations.engineCCLimitValidator);
    }
    if (validation === 'aprLimit') {
      validateArray.push(fromVinValidations.aprLimitValidator);
    }
    if (validation === 'unitIdLimit') {
      validateArray.push(fromVinValidations.unitIdValidator);
    }
    if (validation === 'unitIdvalidchar') {
      validateArray.push(fromVinValidations.unitIdCharValidator);
    }
    if (validation === 'numbersOnly') {
      validateArray.push(fromGenericValidations.NumberValidator);
    }
    if (validation === 'numberGreaterThanZero') {
      validateArray.push(fromGenericValidations.GreaterThanZeroNumberValidator);
    }

    return validateArray;
  }

  static BusinessFieldValidate(form: FormGroup): void {
    if (form.get('businessFlag')) {
      const businessField = form.get('businessFlag').value;
      if (businessField === Options.NO) {
        form.get('businessName').disable();
        form.get('firstName').enable();
        form.get('middleInitial').enable();
        form.get('lastName').enable();
      }
      if (businessField === Options.YES) {
        form.get('businessName').enable();
        form.get('firstName').disable();
        form.get('middleInitial').disable();
        form.get('lastName').disable();
      }
    }
  }
}
