import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';

import { catchError, Observable } from 'rxjs';

import { SupportedLanguageCodes } from 'app/static-data/languages';

export class TranslationLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(language: string): Observable<string> {
    return this.httpClient
      .get<string>(this._getLanguagePath({ application: localStorage.getItem('domain'), language }))
      .pipe(catchError(() => this.httpClient.get<string>('../../../assets/i18n/en-US.json')));
  }

  private _getLanguagePath(request: { application: string; language: string }): string {
    const language = request.language;
    switch (language as SupportedLanguageCodes) {
      case SupportedLanguageCodes.English:
      case SupportedLanguageCodes.EnglishCA:
      default: {
        return '../../../assets/i18n/en-US.json';
      }
      case SupportedLanguageCodes.French: {
        return '../../../assets/i18n/fr-CA.json';
      }
    }
  }
}
