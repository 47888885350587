import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { ContractSearchDetails } from '@ph-model/api/contract-search-details';
import { ContractSearchItem } from '@ph-model/api/contract-search-item';

export const loadContractSearchData = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT SEARCH DATA',
  props<{ vin: string; firstName: string; lastName: string }>()
);

export const updateContractSearchCurrentPage = createAction(
  '[CONTRACT_SEARCH] UPDATE CONTRACT SEARCH PAGE',
  props<{ pageNumber: number }>()
);

export const loadContractSearchDataSuccess = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT SEARCH DATA SUCCESS',
  props<{ contracts: ContractSearchItem[]; rowCount: number; pagesCount: number }>()
);
export const loadContractSearchDataFailure = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT SEARCH DATA FAILURE',
  props<{ error: HttpErrorResponse }>()
);
export const resetContractSearchParams = createAction('[CONTRACT_SEARCH] RESET CONTRACT SEARCH PARAMS');

export const loadContractDetailsData = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT DETAILS DATA',
  props<{ contractId: string }>()
);
export const loadContractDetailsDataSuccess = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT DETAILS DATA SUCCESS',
  props<{ contractDetails: ContractSearchDetails }>()
);
export const loadContractDetailsDataFailure = createAction(
  '[CONTRACT_SEARCH] LOAD CONTRACT DETAILS DATA FAILURE',
  props<{ error: HttpErrorResponse }>()
);

export const resetContractSearchData = createAction('[CONTRACT_SEARCH] RESET CONTRACT DETAILS DATA');
