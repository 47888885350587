import { ChangeDetectionStrategy, Component, output, OutputEmitterRef } from '@angular/core';

@Component({
  selector: 'ph-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  close: OutputEmitterRef<void> = output();
}
