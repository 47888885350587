import { NgStyle } from '@angular/common';
import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';

import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { PH_OVERLAY_DATA, PhOverlayRef } from '@ph-core/services/overlay';
import { PhOptionComponent, getNotificationLevel } from '@ph-shared/components';
import { IconComponent } from '@ph-shared/components/icon/icon-component';
import { PhButtonComponent } from '@ph-shared/components/ph-button/ph-button.component';

import { PhNotificationAction, PhNotificationItem, PhNotificationType } from './notification.interface';
import { PhNotificationIconComponent } from '../notification-icon/notification-icon.component';

@Component({
  selector: 'ph-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [PhNotificationIconComponent, NgStyle, PhButtonComponent, IconComponent],
})
export class PhNotificationComponent implements PhNotificationItem, OnInit {
  @HostBinding('class.ph-dialog-card') card: boolean = true;

  @Input({ required: true }) actions: PhNotificationAction[];
  @Input({ required: true }) type: PhNotificationType;
  @Input({ required: true }) standalone: boolean = true;
  @Input({ required: true }) duration: number = 0;
  @Input({ required: true }) value: { message: string; title: string };
  @Input({ required: true }) id: string = '';

  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    @Inject(PH_OVERLAY_DATA) public notification: PhNotificationItem,
    private ref: PhOverlayRef<PhNotificationComponent>
  ) {}

  ngOnInit(): void {
    this.duration > 0 &&
      of([])
        .pipe(delay(this.duration))
        .subscribe(() => this.close());
  }

  act(
    action: (
      notifications:
        | PhOverlayRef<PhNotificationComponent>
        | PhOverlayRef<PhOptionComponent>
        | PhNotificationComponent
        | PhOptionComponent
    ) => void
  ): void {
    action(this.standalone ? this.ref : this);
  }

  update(value: PhNotificationItem) {
    Object.assign(this.notification.value, value);
  }

  close(): void {
    this.standalone ? this.ref.close() : this.closed.next(this.id);
  }

  @HostBinding('class') get colorClass(): { [klass: string]: boolean } {
    return {
      [getNotificationLevel(this.type || this.notification?.type)]: true,
      wideView: this.notification.wideView,
    };
  }
}
