export class DecodeVinResponse {
  vin: string;
  make: string[];
  model: string[];
  trim: string[];
  year: string[];
  inserviceDate: string;
  cartFound: boolean;
  inserviceDateFound: boolean;
  status: boolean;
  message: string;
  manualDecode: boolean;
  loadQuote: boolean;

  constructor() {
    this.vin = '';
    this.make = [];
    this.model = [];
    this.trim = [];
    this.year = [];
    this.inserviceDate = '';
    this.inserviceDateFound = false;
    this.cartFound = false;
    this.status = false;
    this.message = '';
    this.manualDecode = false;
    this.loadQuote = false;
  }
}
