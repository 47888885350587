import * as CartState from '@ph-store/cart/cart.reducer';
import * as ProductState from '@ph-store/product/product.reducer';
import * as UserState from '@ph-store/user/user.reducer';
import * as VehicleState from '@ph-store/vehicle/vehicle.reducer';

import { GetProductsRequest } from '../request/product.request';

export class SaveQuote {
  VehicleState: VehicleState.VehicleState;
  cartState: CartState.CartState;
  userState: UserState.UserState;
  getProductRequest: GetProductsRequest;
  productState?: ProductState.ProductState;

  constructor(
    getProductsRequest: GetProductsRequest,
    vehicleState: VehicleState.VehicleState,
    cartState: CartState.CartState,
    userState: UserState.UserState
  ) {
    this.userState = userState;
    this.VehicleState = vehicleState;
    this.cartState = cartState;
    this.getProductRequest = getProductsRequest;
  }
}
