import { Pipe, PipeTransform } from '@angular/core';

import { ContractOperationRecord } from '@ph-shared/components';

@Pipe({
  name: 'operationsByDate',
  standalone: true,
})
export class OperationsByDatePipe implements PipeTransform {
  transform(records: ContractOperationRecord[] | null): ContractOperationRecord[] | null {
    return records ? records.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : records;
  }
}
