import { Observable, take } from 'rxjs';

import { FinanceType, Vendor } from '@ph-core/enums';
import { PrismicLienholder } from '@ph-model/api/response/prismic-response.model';
import { Lienholder, MappedPrismicLienholder } from '@ph-model/checkout/submit_cart/response/lienholder';

export function mapLienholderFromPrismic(
  lienholder$: Observable<PrismicLienholder[]>,
  financeType: FinanceType,
  vendor: Vendor
): Lienholder {
  const isLease: boolean = financeType === FinanceType.Lease;
  const mappedLienholder: MappedPrismicLienholder = {
    address1: '',
    address2: '',
    alternativePhone: '',
    city: '',
    countryCode: '',
    email: '',
    id: '',
    mobilePhone: '',
    name: '',
    officePhone: '',
    postalCode: '',
    stateProvince: '',
    status: false,
    tradingPartner: '',
    fax: '',
    phone: '',
    financeType: '',
  };

  // Acura/Honda use only one default lienholder for all transactions types
  // GM uses different default lienhoders for finance vs lease

  if (isLease && vendor === Vendor.GM) {
    lienholder$.pipe(take(1)).subscribe((prismicLienholder: PrismicLienholder[]) => {
      let index = 0;

      prismicLienholder.forEach((lh: PrismicLienholder, idx: number) => {
        if ((lh.finance_type as FinanceType) === FinanceType.Lease) {
          index = idx;
        }
      });

      Object.entries(prismicLienholder[index]).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          switch (key) {
            case 'alternative_phone':
              mappedLienholder.alternativePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'mobile_phone':
              mappedLienholder.mobilePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'office_phone':
              mappedLienholder.officePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'postal_code':
              mappedLienholder.postalCode = value.length > 0 ? value[0].text : '';
              break;

            case 'state_province':
              mappedLienholder.stateProvince = value.length > 0 ? value[0].text : '';
              break;

            case 'trading_partner':
              mappedLienholder.tradingPartner = value.length > 0 ? value[0].text : '';
              break;

            default:
              mappedLienholder[`${key}`] = value.length > 0 ? value[0].text : '';
          }
        } else {
          switch (key) {
            case 'country_code':
              mappedLienholder.countryCode = value;
              break;

            case 'finance_type':
              mappedLienholder.financeType = value;
              break;

            default:
              mappedLienholder[`${key}`] = value;
          }
        }
      });
    });

    return mappedLienholder;
  } else {
    lienholder$.pipe(take(1)).subscribe((prismicLienholder) => {
      Object.entries(prismicLienholder[0]).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          switch (key) {
            case 'alternative_phone':
              mappedLienholder.alternativePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'mobile_phone':
              mappedLienholder.mobilePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'office_phone':
              mappedLienholder.officePhone = value.length > 0 ? value[0].text : '';
              break;

            case 'postal_code':
              mappedLienholder.postalCode = value.length > 0 ? value[0].text : '';
              break;

            case 'state_province':
              mappedLienholder.stateProvince = value.length > 0 ? value[0].text : '';
              break;

            case 'trading_partner':
              mappedLienholder.tradingPartner = value.length > 0 ? value[0].text : '';
              break;

            default:
              mappedLienholder[`${key}`] = value.length > 0 ? value[0].text : '';
          }
        } else {
          switch (key) {
            case 'country_code':
              mappedLienholder.countryCode = value;
              break;

            case 'finance_type':
              mappedLienholder.financeType = value;
              break;

            default:
              mappedLienholder[`${key}`] = value;
          }
        }
      });
    });

    return mappedLienholder;
  }
}
