import { ProductFilter } from './product-filter';

export interface ProductFilters {
  product: ProductFilter[];
  plan: ProductFilter[];
  coverage: ProductFilter[];
  deductible: ProductFilter[];
  term: ProductFilter[];
  mileage: ProductFilter[];
  surcharge: ProductFilter[];
}

export const productFiltersInitialState: ProductFilters = {
  product: [],
  plan: [],
  coverage: [],
  deductible: [],
  term: [],
  mileage: [],
  surcharge: [],
};
