import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(text: string): string {
    if (!text) {
      return text;
    }

    const separators = [' ', '/'];

    return separators.reduce((result: string, s: string) => this._capitalize(result, s), text.toLowerCase());
  }

  private _capitalize(text: string, separator: string): string {
    const words = text.split(separator).filter((w: string) => w.length > 0);

    return words.map((w) => w[0].toUpperCase() + w.substr(1)).join(separator);
  }
}
