import { createReducer, on } from '@ngrx/store';

import { InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { CartTaxFormValues } from '@ph-model/cart/cart-tax-form-values.model';
import { CheckoutFormValues } from '@ph-model/checkout/checkout-form-values.model';
import { Lienholder } from '@ph-model/checkout/submit_cart/response/lienholder';
import { SubmittedCartItem } from '@ph-model/checkout/submit_cart/response/submitted-cart-item.model';
import { CreditInsuranceCoverageOption } from '@ph-model/form/credit-insurance-coverage-option.model';
import { CreditInsuranceForm } from '@ph-model/form/credit-insurance-form';
import { FinanceForm } from '@ph-model/form/finance-form';
import { Forms } from '@ph-model/form/form';
import { OptionType } from '@ph-model/form/option-type.model';
import { productIdGenerator } from '@ph-shared/utils';
import { manualCustomerCostOverride } from '@ph-store/product/product.actions';
import { recalculateCustomerCost } from '@ph-store/product/product.helpers';

import { creditInsuranceCoverageOptions, loanProtectionCoverageOptions } from 'app/static-data/coverage-options';

import {
  addAndValidateCartSuccess,
  addTaxesSuccess,
  addToCart,
  cartFailure,
  deleteFromCart,
  getCheckoutFormSuccess,
  getCosignerPostalSuccess,
  getCustomerPostalSuccess,
  loadQuoteSuccess,
  recalculateCartCustomerCosts,
  recalculateCartTotal,
  resetCart,
  resetCreditInsuranceQuote,
  saveAsUMUCreditFailure,
  saveAsUMUCreditSuccess,
  saveAsUMUQuoteFailure,
  saveasUMUQuoteSuccess,
  setCreditFormEnable,
  submitCartSuccess,
  toggleCosignerForm,
  updateAddCreditInsurance,
  updateCartItem,
  updateCartItemError,
  updateCartItemTaxAmounts,
  updateCartTotal,
  updateCheckoutForm,
  updateCreditInsuranceMonthlyPayment,
  updateFinanceMontlyPayment,
  updateFirstPaymentDate,
  updateItemProduct,
  updateTaxFormValues,
} from './cart.actions';

export interface CartState {
  cart: CartItem[];
  validatedCart: CartItem[];
  cartTaxFormValues: CartTaxFormValues[];
  submittedCart: SubmittedCartItem[];
  checkoutForms: Forms;
  checkoutFormValues: CheckoutFormValues;
  total: number;
  error: string;
  success: boolean;
  savedQuote: boolean;
  submittedCartSuccess: boolean;
  checkoutCartContainsError: boolean;
  customerPostal: PostalResponse;
  cosignerPostal: PostalResponse;
  cosignerFormEnable: boolean;
  exculdingCreditFormValid: boolean;
  creditFormEnable: boolean;
  isInValidRequestQuote: boolean;
  isValidSaveCredit: boolean;
  requestedQuote: { insResponse: InsuranceResponseModel };
  loanProtectionCoverageOptions: OptionType[];
  creditInsurancePackageCoverageOptions: CreditInsuranceCoverageOption[];
  cartTotal: number;
}

const initialCheckoutForms = {
  customerForm: [],
  cosignerForm: [],
  vehicleForm: [],
  lienholderForm: [],
  addlienholderForm: [],
  lienholder: {} as Lienholder,
  creditInsuranceForm: [],
  coverageInfoForm: [],
  financeForm: [],
};

export const InitialState: CartState = {
  cart: [],
  validatedCart: [],
  submittedCart: [],
  cartTaxFormValues: [],
  checkoutForms: initialCheckoutForms,
  total: 0.0,
  error: '',
  success: false,
  savedQuote: false,
  submittedCartSuccess: false,
  checkoutCartContainsError: false,
  customerPostal: new PostalResponse(),
  cosignerPostal: new PostalResponse(),
  checkoutFormValues: new CheckoutFormValues(),
  cosignerFormEnable: false,
  requestedQuote: null,
  creditFormEnable: false,
  exculdingCreditFormValid: false,
  isInValidRequestQuote: true,
  isValidSaveCredit: false,
  loanProtectionCoverageOptions: loanProtectionCoverageOptions,
  creditInsurancePackageCoverageOptions: creditInsuranceCoverageOptions,
  cartTotal: 0,
};

const getSynchronizedFinances = (
  state: CartState,
  updatedFields: Partial<CheckoutFormValues>
): Partial<CheckoutFormValues> => {
  const oldFinanceForm = { ...state.checkoutFormValues.financeForm } as FinanceForm;
  const newFinanceForm = updatedFields.financeForm;
  const oldCreditInsuranceForm = { ...state.checkoutFormValues.creditInsuranceForm } as CreditInsuranceForm;
  const newCreditInsuranceForm = updatedFields.creditInsuranceForm as CreditInsuranceForm;

  const updatedFinance: Partial<FinanceForm> = {};
  const updatedCreditInsurance: Partial<CreditInsuranceForm> = {};

  if (newFinanceForm && !newCreditInsuranceForm) {
    if (newFinanceForm.financeAmount !== oldFinanceForm.financeAmount) {
      updatedCreditInsurance.loanAmount = newFinanceForm.financeAmount;
    }
    if (newFinanceForm.loanTerm !== oldFinanceForm.loanTerm) {
      updatedCreditInsurance.loanTerm = newFinanceForm.loanTerm;
    }
    if (newFinanceForm.amortizationPeriod !== oldFinanceForm.amortizationPeriod) {
      updatedCreditInsurance.amortizationPeriod = newFinanceForm.amortizationPeriod;
    }
    if (newFinanceForm.apr !== oldFinanceForm.apr) {
      updatedCreditInsurance.interestRate = newFinanceForm.apr;
    }
  }

  if (newCreditInsuranceForm && !newFinanceForm) {
    if (newCreditInsuranceForm.loanTerm !== oldCreditInsuranceForm.loanTerm) {
      updatedFinance.loanTerm = newCreditInsuranceForm.loanTerm;
    }
    if (newCreditInsuranceForm.amortizationPeriod !== oldCreditInsuranceForm.amortizationPeriod) {
      updatedFinance.amortizationPeriod = newCreditInsuranceForm.amortizationPeriod;
    }
    if (newCreditInsuranceForm.interestRate !== oldCreditInsuranceForm.interestRate) {
      updatedFinance.apr = newCreditInsuranceForm.interestRate;
    }
  }

  const stateToUpdate: Partial<CheckoutFormValues> = { ...updatedFields };

  if (Object.keys(updatedFinance).length > 0) {
    stateToUpdate.financeForm = { ...oldFinanceForm, ...updatedFinance };
  }
  if (Object.keys(updatedCreditInsurance).length > 0) {
    stateToUpdate.creditInsuranceForm = { ...oldCreditInsuranceForm, ...updatedCreditInsurance };
  }

  // insuranceTerm
  if (newCreditInsuranceForm) {
    if (oldCreditInsuranceForm.loanTerm !== newCreditInsuranceForm.loanTerm) {
      stateToUpdate.coverageInfoForm = {
        ...state.checkoutFormValues.coverageInfoForm,
        insuranceTerm: newCreditInsuranceForm.loanTerm,
      };
    }
  }

  return stateToUpdate;
};

const _calculateCartTotal = (cartTaxFormValues: CartTaxFormValues[]): number => {
  return cartTaxFormValues.reduce((total, item) => {
    const taxTotal = item.tax['total'] || item.tax['cost'];

    return total + taxTotal;
  }, 0);
};

export const featureKey = 'cartState';

export const reducer = createReducer(
  InitialState,
  on(
    addToCart,
    (state, { cartItem }): CartState => ({
      ...state,
      // VERIFY - would be nice to find a better method to deep copy
      // Javascript's only method of doing a deep copy requires the parse and stringify
      cart: [...state.cart, JSON.parse(JSON.stringify(cartItem))],
      cartTotal: _calculateCartTotal(state.cartTaxFormValues),
      savedQuote: false,
    })
  ),
  on(
    deleteFromCart,
    (state, { cartItem }): CartState => ({
      ...state,
      cart: state.cart.filter((item: CartItem) => item.key !== cartItem.key),
      cartTaxFormValues: state.cartTaxFormValues.filter((item: CartTaxFormValues) => item.key !== cartItem.key),
      validatedCart: state.validatedCart.filter((item) => item.key !== cartItem.key),
      cartTotal: _calculateCartTotal(
        state.cartTaxFormValues.filter((item: CartTaxFormValues) => item.key !== cartItem.key)
      ),
      savedQuote: false,
    })
  ),
  on(
    updateCheckoutForm,
    (state, { updatedFields }): CartState => ({
      ...state,
      checkoutFormValues: { ...state.checkoutFormValues, ...getSynchronizedFinances(state, updatedFields) },
    })
  ),
  on(
    updateAddCreditInsurance,
    (state, { addInsurance }): CartState => ({
      ...state,
      checkoutFormValues: {
        ...state.checkoutFormValues,
        creditInsuranceForm: {
          ...state.checkoutFormValues.creditInsuranceForm,
          addInsurance,
        },
      },
    })
  ),
  on(
    updateCreditInsuranceMonthlyPayment,
    (state, { updatedFields }): CartState => ({
      ...state,
      checkoutFormValues: {
        ...state.checkoutFormValues,
        creditInsuranceForm: {
          ...state.checkoutFormValues.creditInsuranceForm,
          ...updatedFields,
        },
      },
    })
  ),
  on(
    updateFinanceMontlyPayment,
    (state, { updatedFields }): CartState => ({
      ...state,
      checkoutFormValues: {
        ...state.checkoutFormValues,
        financeForm: {
          ...state.checkoutFormValues.financeForm,
          ...updatedFields,
        },
      },
    })
  ),
  on(
    updateFirstPaymentDate,
    (state, { firstPaymentDate }): CartState => ({
      ...state,
      checkoutFormValues: {
        ...state.checkoutFormValues,
        creditInsuranceForm: {
          ...state.checkoutFormValues.creditInsuranceForm,
          firstPaymentDate,
        },
      },
    })
  ),
  on(
    addTaxesSuccess,
    (state, { validatedCart }): CartState => ({
      ...state,
      validatedCart,
    })
  ),
  on(
    updateTaxFormValues,
    (state, { cartTaxFormValues }): CartState => ({
      ...state,
      cartTaxFormValues: [...cartTaxFormValues],
    })
  ),
  on(updateCartItemTaxAmounts, (state, { cartItem, taxValues }): CartState => {
    const itemToUpdate = state.cartTaxFormValues.find((currentTaxValues) => cartItem.key === currentTaxValues.key);
    if (!itemToUpdate) {
      const cartTaxFormValues = [...state.cartTaxFormValues, { key: cartItem.key, tax: taxValues }];

      return {
        ...state,
        cartTaxFormValues,
        cartTotal: _calculateCartTotal(cartTaxFormValues),
      };
    } else {
      if (JSON.stringify(itemToUpdate.tax) === JSON.stringify(taxValues)) {
        return state;
      } else {
        const cartTaxFormValues = state.cartTaxFormValues.map((currentTaxValues) =>
          cartItem.key === currentTaxValues.key
            ? { ...currentTaxValues, tax: { ...currentTaxValues.tax, ...taxValues } }
            : currentTaxValues
        );

        return {
          ...state,
          cartTaxFormValues,
          cartTotal: _calculateCartTotal(cartTaxFormValues),
        };
      }
    }
  }),
  on(
    getCheckoutFormSuccess,
    (state, { forms }): CartState => ({
      ...state,
      checkoutForms: { ...state.checkoutForms, ...forms },
      submittedCartSuccess: false,
    })
  ),
  on(
    submitCartSuccess,
    (state, { invalidCart, submittedCart }): CartState => ({
      ...state,
      cart: invalidCart,
      validatedCart: invalidCart,
      submittedCart: [...state.submittedCart, ...submittedCart],
      savedQuote: false,
      submittedCartSuccess: submittedCart.length > 0,
      checkoutCartContainsError: invalidCart.length > 0,
    })
  ),
  on(
    toggleCosignerForm,
    (state, { cosignerFormEnable }): CartState => ({
      ...state,
      cosignerFormEnable,
    })
  ),
  on(
    getCustomerPostalSuccess,
    (state, { customerPostal }): CartState => ({
      ...state,
      customerPostal,
    })
  ),
  on(
    getCosignerPostalSuccess,
    (state, { cosignerPostal }): CartState => ({
      ...state,
      cosignerPostal,
    })
  ),
  on(
    addAndValidateCartSuccess,
    (state, { validatedCart }): CartState => ({
      ...state,
      validatedCart: validatedCart.validatedCart,
      success: true,
      savedQuote: false,
    })
  ),
  on(
    loadQuoteSuccess,
    (state, { quote }): CartState => ({
      ...state,
      validatedCart: quote.cartState.validatedCart,
      cart: quote.cartState.cart,
      submittedCart: quote.cartState.submittedCart,
      total: quote.cartState.total,
      success: quote.cartState.success,
      error: quote.cartState.error,
      checkoutFormValues: quote.cartState.checkoutFormValues,
      customerPostal: quote.cartState.customerPostal,
      cosignerPostal: quote.cartState.cosignerPostal,
      cosignerFormEnable: quote.cartState.cosignerFormEnable,
      checkoutForms: quote.cartState.checkoutForms,
      submittedCartSuccess: false,
      checkoutCartContainsError: false,
      savedQuote: true,
      requestedQuote: quote.cartState.requestedQuote,
      // use conditional operator for objects that may not be in load quote
      cartTaxFormValues: quote.cartState['cartTaxFormValues'] ? quote.cartState['cartTaxFormValues'] : [],
    })
  ),
  on(
    resetCart,
    (): CartState => ({
      cart: [],
      validatedCart: [],
      submittedCart: [],
      cartTaxFormValues: [],
      checkoutForms: initialCheckoutForms,
      total: 0.0,
      error: '',
      success: false,
      savedQuote: false,
      submittedCartSuccess: false,
      checkoutCartContainsError: false,
      customerPostal: new PostalResponse(),
      cosignerPostal: new PostalResponse(),
      checkoutFormValues: new CheckoutFormValues(),
      cosignerFormEnable: false,
      requestedQuote: null,
      creditFormEnable: false,
      exculdingCreditFormValid: false,
      isInValidRequestQuote: false,
      isValidSaveCredit: false,
      loanProtectionCoverageOptions: InitialState.loanProtectionCoverageOptions,
      creditInsurancePackageCoverageOptions: InitialState.creditInsurancePackageCoverageOptions,
      cartTotal: 0,
    })
  ),
  on(
    cartFailure,
    (state, { error }): CartState => ({
      ...state,
      success: false,
      error,
      savedQuote: false,
    })
  ),
  on(
    saveasUMUQuoteSuccess,
    (state, { requestedQuote }): CartState => ({
      ...state,
      requestedQuote,
      isInValidRequestQuote: false,
    })
  ),
  on(
    saveAsUMUQuoteFailure,
    (state, { error }): CartState => ({
      ...state,
      error,
      isInValidRequestQuote: true,
    })
  ),
  on(
    saveAsUMUCreditSuccess,
    (state): CartState => ({
      ...state,
      isValidSaveCredit: true,
    })
  ),
  on(
    saveAsUMUCreditFailure,
    (state): CartState => ({
      ...state,
      isValidSaveCredit: false,
    })
  ),
  on(
    setCreditFormEnable,
    (state, { creditFormEnable }): CartState => ({
      ...state,
      creditFormEnable,
    })
  ),
  on(updateItemProduct, (state, { key, product }): CartState => {
    const itemIndex = state.validatedCart.findIndex((i: CartItem) => i.key === key);
    const item = state.validatedCart[itemIndex];

    return {
      ...state,
      validatedCart: [
        ...state.validatedCart.slice(0, itemIndex),
        { ...item, product: { ...item.product, ...product } },
        ...state.validatedCart.slice(itemIndex + 1),
      ],
    };
  }),
  on(updateCartTotal, (state, { cartTotal }): CartState => {
    return { ...state, cartTotal };
  }),
  on(resetCreditInsuranceQuote, (state): CartState => {
    const { financeAmount, monthlyPayment, apr, loanTerm, amortizationPeriod, residualValue } =
      state.checkoutFormValues.financeForm;

    return {
      ...state,
      checkoutFormValues: {
        ...state.checkoutFormValues,
        creditInsuranceForm: {
          ...state.checkoutFormValues.creditInsuranceForm,
          loanAmount: financeAmount,
          interestRate: apr,
          loanTerm,
          amortizationPeriod,
          monthlyPayment,
          residualValue,
        },
      },
      requestedQuote: null,
    };
  }),
  on(recalculateCartCustomerCosts, (state, { markup, surchargesSelection }): CartState => {
    return {
      ...state,
      cart: state.cart.map((cartItem) => ({
        ...cartItem,
        product: recalculateCustomerCost(cartItem.product, markup, surchargesSelection),
      })),
      validatedCart: state.validatedCart.map((cartItem) => ({
        ...cartItem,
        product: recalculateCustomerCost(cartItem.product, markup, surchargesSelection),
      })),
    };
  }),
  on(
    manualCustomerCostOverride,
    (state, { product, customerCost }): CartState => ({
      ...state,
      cart: state.cart.map((item) =>
        productIdGenerator(item.product) === productIdGenerator(product)
          ? {
              ...item,
              product: {
                ...item.product,
                customerCost: +customerCost,
                manualCustomerCostDelta:
                  item.product.manualCustomerCostDelta + +customerCost - item.product.customerCost,
              },
            }
          : item
      ),
      validatedCart: state.validatedCart.map((item) =>
        productIdGenerator(item.product) === productIdGenerator(product)
          ? {
              ...item,
              product: {
                ...item.product,
                customerCost: +customerCost,
                manualCustomerCostDelta:
                  item.product.manualCustomerCostDelta + +customerCost - item.product.customerCost,
              },
            }
          : item
      ),
    })
  ),
  on(
    updateCartItemError,
    (state, { cartItem, error }): CartState => ({
      ...state,
      validatedCart: state.validatedCart.map((item) => (item.key === cartItem.key ? { ...item, error } : item)),
    })
  ),
  on(
    updateCartItem,
    (state, { dest, src }): CartState => ({
      ...state,
      validatedCart: state.validatedCart.map((item) => (item.key === dest.key ? { ...item, ...src } : item)),
      cartTotal: _calculateCartTotal(state.cartTaxFormValues),
    })
  ),
  on(
    recalculateCartTotal,
    (state): CartState => ({ ...state, cartTotal: _calculateCartTotal(state.cartTaxFormValues) })
  )
);
