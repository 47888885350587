import { createAction, props } from '@ngrx/store';

import { InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { PostalResponse } from '@ph-model/api/response/postal.response.model';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { CartTaxFormValues } from '@ph-model/cart/cart-tax-form-values.model';
import { LoadQuote } from '@ph-model/cart/load-quote.model';
import { InsRequest } from '@ph-model/cart/request-quote';
import { SubmitCartModel } from '@ph-model/cart/submit-cart.model';
import { ValidatedCart } from '@ph-model/cart/validate-cart.model';
import { CheckoutFormValues } from '@ph-model/checkout/checkout-form-values.model';
import { Lienholder } from '@ph-model/checkout/submit_cart/response/lienholder';
import { SubmittedCartItem } from '@ph-model/checkout/submit_cart/response/submitted-cart-item.model';
import { CreditInsuranceForm } from '@ph-model/form/credit-insurance-form';
import { FinanceForm } from '@ph-model/form/finance-form';
import { Forms } from '@ph-model/form/form';
import { Markup } from '@ph-model/login';
import { SubmitCartRequest } from '@ph-model/request/submit-cart.request';
import { ProductItem } from '@ph-model/response';
import { IComplexDate } from '@ph-shared/utils';

export const addToCart = createAction('[Cart] ADD ITEM TO CART', props<{ cartItem: CartItem }>());
export const deleteFromCart = createAction('[Cart] DELETE ITEM FROM CART', props<{ cartItem: CartItem }>());
export const addTaxes = createAction('[Cart] ADD_TAXES');
export const addTaxesSuccess = createAction('[Cart] ADD_TAXES_SUCCESS', props<{ validatedCart: CartItem[] }>());
export const getCustomerPostalSuccess = createAction(
  '[Cart] GET CUSTOMER POSTAL SUCCESS',
  props<{ customerPostal: PostalResponse }>()
);
export const getCosignerPostalSuccess = createAction(
  '[Cart] GET COSIGNER POSTAL SUCCESS',
  props<{ cosignerPostal: PostalResponse }>()
);

export const toggleCosignerForm = createAction('[Cart] TOGGLE COSIGNER FORM', props<{ cosignerFormEnable: boolean }>());

export const getCheckoutForm = createAction('[Cart] GET CHECKOUT FORM');

export const getCheckoutFormSuccess = createAction(
  '[Cart] GET CHECKOUT FORM SUCCESS',
  props<{ forms: Partial<Forms> }>()
);
export const updateCheckoutForm = createAction(
  '[Cart] UPDATE CHECKOUT FORM',
  props<{ updatedFields: Partial<CheckoutFormValues> }>()
);
export const updateAddCreditInsurance = createAction(
  '[Cart] UPDATE ADD CREDIT INSURANCE',
  props<{ addInsurance: string }>()
);
export const updateCreditInsuranceMonthlyPayment = createAction(
  '[Cart] UPDATE CREDIT INSURANCE MONTHLY PAYMENT',
  props<{ updatedFields: Partial<CreditInsuranceForm> }>()
);
export const updateFinanceMontlyPayment = createAction(
  '[Cart] UPDATE FINANCE MONTHLY PAYMENT',
  props<{ updatedFields: Partial<FinanceForm> }>()
);
export const updateFirstPaymentDate = createAction(
  '[Cart] UPDATE FIRST PAYMENT DATE',
  props<{ firstPaymentDate: IComplexDate }>()
);
export const updateLienholderWithNewlyAdded = createAction(
  '[Cart] UPDATE LIENHOLDER WITH NEWLY ADDED',
  props<{ payload: Lienholder }>()
);
export const updateTaxFormValues = createAction(
  '[Cart] UPDATE TAX FORM VALUES',
  props<{ cartTaxFormValues: CartTaxFormValues[] }>()
);
export const updateItemProduct = createAction(
  '[Cart] UPDATE ITEM PRODUCT',
  props<{ key: string; product: Partial<ProductItem> }>()
);
export const recalculateCartCustomerCosts = createAction(
  '[Cart] RECALCULATE CUSTOMER COSTS',
  props<{ markup: Markup; surchargesSelection: { name: string; selected: boolean }[] }>()
);
export const updateCartTotal = createAction('[Cart] UPDATE CART TOTAL', props<{ cartTotal: number }>());
export const submitCart = createAction('[Cart] SUBMIT CART', props<{ payload: SubmitCartModel | SubmitCartRequest }>());
export const submitCartSuccess = createAction(
  '[Cart] SUBMIT_CART SUCCESS',
  props<{ invalidCart: CartItem[]; submittedCart: SubmittedCartItem[] }>()
);
export const saveAsQuote = createAction('[Cart] SAVE AS QUOTE');
export const saveAsQuoteSuccess = createAction('[Cart] SAVE AS QUOTE SUCCESS');
export const saveAsQuoteFailure = createAction('[Cart] SAVE AS QUOTE FAILURE');
export const saveAsUMUQuote = createAction('[Cart] SAVE AS UMU QUOTE', props<{ insRequest: InsRequest }>());
export const saveasUMUQuoteSuccess = createAction(
  '[Cart] SAVE AS UMU QUOTE SUCCESS',
  props<{ requestedQuote: { insResponse: InsuranceResponseModel } }>()
);
export const saveAsUMUQuoteFailure = createAction('[Cart] SAVE AS UMU QUOTE FAILURE', props<{ error: string }>());
export const saveAsUMUCredit = createAction(
  '[Cart] SAVE AS UMU CREDIT',
  props<{ request: InsRequest; locale: string }>()
);
export const saveAsUMUCreditSuccess = createAction('[Cart] SAVE AS UMU CREDIT SUCCESS');
export const saveAsUMUCreditFailure = createAction('[Cart] SAVE AS UMU CREDIT FAILURE', props<{ error: string }>());
export const loadQuote = createAction('[Cart] LOAD QUOTE');
export const loadQuoteSuccess = createAction('[Cart] LOAD QUOTE SUCCESS', props<{ quote: LoadQuote }>());
export const cartFailure = createAction(
  '[Cart] VALIDATE FAILURE',
  props<{
    error: string;
    operation:
      | 'addToCart'
      | 'deleteFromCart'
      | 'getCheckoutForm'
      | 'addTaxes'
      | 'submitCart'
      | 'loadQuote'
      | 'getCustomerPostal'
      | 'getCosignerPostal';
  }>()
);
export const resetCart = createAction('[Cart] RESET CART');
export const resetCreditInsuranceQuote = createAction('[Cart] RESET CREDIT INSURANCE QUOTE');
export const setCreditFormEnable = createAction(
  '[Cart] SET CREDIT FORM ENABLE',
  props<{ creditFormEnable: boolean }>()
);
export const addAndValidateCartSuccess = createAction(
  '[Cart] ADD AND VALIDATE CART SUCCESS',
  props<{ validatedCart: ValidatedCart }>()
);
export const updateCartItemError = createAction(
  '[Cart] UPDATE CART ITEM ERROR',
  props<{ cartItem: CartItem; error: string | undefined }>()
);
export const updateCartItem = createAction(
  '[Cart] UPDATE CART ITEM',
  props<{ dest: CartItem; src: Partial<CartItem> }>()
);

export const updateCartItemTaxAmounts = createAction(
  '[Cart] UPDATE CART ITEM TAX AMOUNTS',
  props<{ cartItem: CartItem; taxValues: Record<string, number> }>()
);

export const recalculateCartTotal = createAction('[Cart] RECALCULATE CART TOTAL');
