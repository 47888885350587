export class ClientConfigImages {
  loginPageBackground: string;
  logo: string;
  logo_fr_CA: string;
  logoMiddle: string;
  logoRight: string;
  navbarLogo: string;
  navbarLogo_fr_CA: string;
  homePageLinks: string;
}
