import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { PH_OVERLAY_DATA } from '@ph-core/services/overlay';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { PhOverlayCloseDirective } from '@ph-shared/directives';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';
import { SelectDropdownOptionComponent } from '../select-dropdown/select-dropdown-option.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

@Component({
  templateUrl: './invalid-checkout-popup.component.html',
  styleUrls: ['./invalid-checkout-popup.component.scss'],
  standalone: true,
  imports: [
    FormInputControlComponent,
    IconComponent,
    PhButtonComponent,
    PhOverlayCloseDirective,
    ReactiveFormsModule,
    SelectDropdownComponent,
    SelectDropdownOptionComponent,
    TranslateModule,
  ],
})
export class InvalidCheckoutPopupComponent {
  invalidCart: CartItem[] = [];

  constructor(@Inject(PH_OVERLAY_DATA) private data: { cart }) {
    this.invalidCart = this.data.cart;
  }
}
