import { DestroyRef, inject } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function untilDestroyed() {
  const replaySubject: ReplaySubject<boolean> = new ReplaySubject(1);

  inject(DestroyRef).onDestroy(() => {
    replaySubject.next(true);
    replaySubject.complete();
  });

  return <T>() => takeUntil<T>(replaySubject.asObservable());
}
