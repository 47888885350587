export class FinanceInfo {
  constructor(
    public financeTerm: number,
    public purchasePrice: number|string,
    public contractDealerCost: number,
    public msrp: number|string,
    public deductible: number|string,
    public financeType: string,
    public grossCapCost: number|string,
    public currencyCode: string,
    public customerCost: number,
    public apr: number,
    public paymentType: string,
    public amountFinanced: number|string
  ) {}
}
