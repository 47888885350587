import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, InputSignal } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';

import { ContractOperationRecord } from '../contract-history/models/contract-operation-record.model';
import { ContractOperationType } from '../contract-history/models/contract-operation-type.model';
import { StatusChipComponent } from '../status-chip/status-chip.component';
import { PhStatusChipType } from '../status-chip/status-chip.interface';

@Component({
  selector: 'app-contract-operation-record[record]',
  templateUrl: './contract-operation-record.component.html',
  styleUrls: ['./contract-operation-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, StatusChipComponent],
})
export class ContractOperationRecordComponent {
  record: InputSignal<ContractOperationRecord> = input.required();
  displayUserName: boolean = environment.features.contractHistory.displayUserName;
  verbiage = environment.verbiage;

  displayName = computed(() => this.record && (this.record().userDisplayName || this.record().userName));

  userName = computed(() =>
    this.record() &&
    this.record().userDisplayName &&
    this.record().userName &&
    this.record().userDisplayName !== this.record().userName
      ? this.record().userName
      : undefined
  );

  getChipType(operation: ContractOperationType): PhStatusChipType {
    switch (operation) {
      case 'CREATED':
      case 'UNDO_DECLINED':
      default:
        return PhStatusChipType.open;
      case 'CANCELED':
      case 'UPDATED':
      case 'EXPIRED':
        return PhStatusChipType.info;
      case 'REMITTED':
        return PhStatusChipType.registered;
      case 'DECLINED':
        return PhStatusChipType.denied;
    }
  }

  getChipLabel(operation: ContractOperationType): string {
    switch (operation) {
      case 'CREATED':
      default:
        return 'contractOperationRecord.created';
      case 'UNDO_DECLINED':
        return 'contractOperationRecord.undeclined';
      case 'CANCELED':
        return 'contractOperationRecord.canceled';
      case 'UPDATED':
        return 'contractOperationRecord.updated';
      case 'EXPIRED':
        return 'contractOperationRecord.expired';
      case 'REMITTED':
        return this.verbiage.contractOperationRecord.registered;
      case 'DECLINED':
        return 'contractOperationRecord.declined';
    }
  }
}
