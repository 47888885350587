import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { LoggerService } from '@ph-core/services/logger/logger.service';

import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const logger = inject(LoggerService);

  return authService
    .isAuthenticated()
    .then((isAuthenticated) => {
      logger.log('authentication check: ', { isAuthenticated });
      if (!isAuthenticated) {
        void router.navigate(['/login']);

        return false;
      } else {
        return true;
      }
    })
    .catch(() => {
      logger.log({ authGuard: 'catch' });

      return false;
    });
};
