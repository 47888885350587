import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';
import { NgxCaptchaModule } from 'ngx-captcha';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { PH_OVERLAY_DATA, PhOverlayRef } from '@ph-core/services/overlay';
import { RequiredValidator } from '@ph-core/validations';
import { untilDestroyed } from '@ph-shared/utils';
import { selectProgramId } from '@ph-store/config/config.selectors';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss'],
  standalone: true,
  imports: [
    FormInputControlComponent,
    IconComponent,
    NgxCaptchaModule,
    PhButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class ForgotPasswordPopupComponent implements OnInit {
  usernameForm: FormGroup<{ username: FormControl<string>; captcha: FormControl<string> }>;
  emailSuccess: WritableSignal<boolean> = signal(false);
  langValue: SupportedLanguageCodes = SupportedLanguageCodes.EnglishCA;
  supportCaptcha: boolean = environment.features.supportCaptcha;
  recaptchaSiteKey: string = environment.recaptchaSiteKey;

  private _isSSOFlow: boolean = environment.auth.sso;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private ref: PhOverlayRef<ForgotPasswordPopupComponent>,
    @Inject(PH_OVERLAY_DATA) private data: { username: string }
  ) {}

  ngOnInit(): void {
    this.usernameForm = this.fb.group({
      username: this.fb.control(this.data?.username || null, [RequiredValidator]),
      captcha: this.fb.control(null, this.supportCaptcha ? [RequiredValidator] : []),
    });
  }

  submitPasswordUpdate(): void {
    if (this.usernameForm.invalid) {
      return;
    }

    this.store
      .select(selectProgramId)
      .pipe(
        take(1),
        switchMap((programId) => {
          if (this._isSSOFlow) {
            const headers = new HttpHeaders({
              'Cache-Control': 'no-cache',
              'Content-Type': 'application/json',
            });

            const { username: identifier, captcha } = this.usernameForm.getRawValue();

            const payload = {
              identifier,
              programId,
              application: environment.application,
              portalUrl: location.origin,
              captcha,
            };

            return this.httpClient
              .post<{ success: boolean; status: boolean }>(`${environment.apiUrl}/okta/forgotpassword`, payload, {
                headers,
              })
              .pipe(filter((data) => data?.success || data?.status));
          } else {
            return this.httpClient
              .post<{ status: boolean }>(`${environment.apiUrl}/forgotpassword`, {
                application: environment.application,
                programId: programId,
                username: this.usernameForm.get('username').value,
                captcha: this.usernameForm.get('captcha').value,
              })
              .pipe(filter((data) => data?.status));
          }
        }),
        catchError((error) => throwError(() => error)),
        tap(() => this.emailSuccess.set(true)),
        this.#untilDestroyed()
      )
      .subscribe();
  }

  close(): void {
    this.ref.close();
  }
}
