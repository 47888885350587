import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';

import { ConfigService } from '@ph-core/services/config/config.service';
import { PhOverlayRef } from '@ph-core/services/overlay';
import { Country } from '@ph-model/country-code';
import { RadioOption } from '@ph-model/form/radio-option';
import { PhOverlayCloseDirective } from '@ph-shared/directives';

import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';
import { SelectDropdownOptionComponent } from '../select-dropdown/select-dropdown-option.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

@Component({
  templateUrl: './contact-print-language-selection-popup.component.html',
  styleUrls: ['./contact-print-language-selection-popup.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    PhButtonComponent,
    PhOverlayCloseDirective,
    ReactiveFormsModule,
    SelectDropdownComponent,
    SelectDropdownOptionComponent,
    TranslateModule,
  ],
})
export class ContactPrintLanguageSelectionPopupComponent {
  customerAgreementLanguage: FormControl<SupportedLanguageCodes> = new FormControl(
    this.configService.getLanguage() || SupportedLanguageCodes.EnglishCA
  );

  customerAgreementLanguageOptions: RadioOption[] =
    environment.countryCode === Country.CAN
      ? [
          { label: 'customerAgreementLanguage.en', value: 'en-CA' },
          { label: 'customerAgreementLanguage.fr', value: 'fr-CA' },
        ]
      : [];

  constructor(
    private translate: TranslateService,
    private configService: ConfigService,
    private ref: PhOverlayRef<ContactPrintLanguageSelectionPopupComponent>
  ) {
    this.customerAgreementLanguageOptions = this.customerAgreementLanguageOptions.map((o: RadioOption) => ({
      ...o,
      label: this.translate.instant('dropdownOptions.' + o.label),
    }));
  }

  confirmLang(): void {
    this.ref.close(this.customerAgreementLanguage.value);
  }

  close(): void {
    this.ref.close();
  }
}
