import { PhStatusChipType } from './status-chip.interface';
import { IconName } from '../icon/icons';

export function getStatusChipLevel(type: PhStatusChipType): string {
  switch (type) {
    case PhStatusChipType.submit:
    case PhStatusChipType.open:
    case PhStatusChipType.info:
      return 'ph-notification-level-1';

    case PhStatusChipType.inaudit:
    case PhStatusChipType.days30:
    case PhStatusChipType.days45:
      return 'ph-notification-level-2';

    case PhStatusChipType.approved:
    case PhStatusChipType.registered:
    case PhStatusChipType.credit:
      return 'ph-notification-level-3';

    case PhStatusChipType.cancel:
    case PhStatusChipType.denied:
    case PhStatusChipType.duplicate:
    case PhStatusChipType.days60:
    case PhStatusChipType.noben:
      return 'ph-notification-level-4';

    case PhStatusChipType.inactive:
    case PhStatusChipType.closed:
      return 'ph-notification-level-5';

    default:
      return 'ph-notification-level-0';
  }
}

export function getStatusChipIcon(type: PhStatusChipType): IconName {
  const icons: IconName[] = ['check', 'warning'];

  switch (type) {
    case PhStatusChipType.approved:
    case PhStatusChipType.credit:
    case PhStatusChipType.registered:
    case PhStatusChipType.open:
      return icons[0];

    default:
      return icons[1];
  }
}

export function getStatusChipLabel(type: PhStatusChipType): string {
  switch (type) {
    case PhStatusChipType.submit:
      return 'statusChip.submit';
    case PhStatusChipType.open:
      return 'statusChip.open';
    case PhStatusChipType.info:
      return 'statusChip.info';
    case PhStatusChipType.inaudit:
      return 'statusChip.inaudit';
    case PhStatusChipType.days30:
      return 'statusChip.days30';
    case PhStatusChipType.days45:
      return 'statusChip.days45';
    case PhStatusChipType.approved:
      return 'statusChip.approved';
    case PhStatusChipType.credit:
      return 'statusChip.credit';
    case PhStatusChipType.cancel:
      return 'statusChip.cancel';
    case PhStatusChipType.denied:
      return 'statusChip.denied';
    case PhStatusChipType.duplicate:
      return 'statusChip.duplicate';
    case PhStatusChipType.days60:
      return 'statusChip.days60';
    case PhStatusChipType.noben:
      return 'statusChip.noben';
    case PhStatusChipType.inactive:
      return 'statusChip.inactive';
    case PhStatusChipType.closed:
      return 'statusChip.closed';
    default:
      console.error('No translation found!');

      return type;
  }
}

export function getProductCardSpate(statuses: PhStatusChipType[]): PhStatusChipType {
  if (statuses.length > 1) {
    if (
      statuses.includes(PhStatusChipType.cancel) ||
      statuses.includes(PhStatusChipType.denied) ||
      statuses.includes(PhStatusChipType.duplicate) ||
      statuses.includes(PhStatusChipType.days60) ||
      statuses.includes(PhStatusChipType.noben)
    ) {
      return PhStatusChipType.cancel;
    } else if (
      statuses.includes(PhStatusChipType.inaudit) ||
      statuses.includes(PhStatusChipType.days30) ||
      statuses.includes(PhStatusChipType.days45)
    ) {
      return PhStatusChipType.inaudit;
    } else {
      return statuses[0];
    }
  } else if (statuses.length === 1) {
    return statuses[0];
  }
}
