export class Dot {
  constructor(
    public rimSize: number,
    public quantity: number,
    dot: string[],
  ) {
    dot.forEach((d: string, index: number) => {
        this[`dot${index+1}`] = d;
      }
    )
  }
}
