import { AsyncPipe, DatePipe, NgStyle } from '@angular/common';
import { Component, inject, Signal, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { SupportedLanguageCodes } from '@ph-static-data/languages';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@ph-core/services/config/config.service';
import { DecodeVinResponse } from '@ph-model/api/response/decode-vin.response';
import { Coverage, InsuranceResponseModel } from '@ph-model/api/response/insurance.response.model';
import { CartItem } from '@ph-model/cart/cart-item.model';
import { CreditInsuranceCoverageOption } from '@ph-model/form/credit-insurance-coverage-option.model';
import { OptionType } from '@ph-model/form/option-type.model';
import { UserResponse } from '@ph-model/login/user.response.model';
import { selectCartState, selectRequestedQuote, selectValidatedCart } from '@ph-store/cart/cart.selectors';
import { selectUser } from '@ph-store/user/user.selectors';
import { selectVehicleState } from '@ph-store/vehicle/vehicle.selectors';

@Component({
  templateUrl: './quote-print-template.component.html',
  styleUrls: ['./quote-print-template.component.scss'],
  standalone: true,
  imports: [NgStyle, AsyncPipe, DatePipe, TranslateModule],
})
export class QuotePrintTemplateComponent {
  navbarLogo: WritableSignal<string | ArrayBuffer> = signal('');

  user: Signal<UserResponse> = toSignal(this.store.select(selectUser));
  cart: Signal<CartItem[]> = toSignal(this.store.select(selectValidatedCart));
  creditIns: Signal<{ insResponse: InsuranceResponseModel }> = toSignal(this.store.select(selectRequestedQuote));
  coverage$: Observable<Coverage[]>;
  vehicle$: Observable<DecodeVinResponse>;
  date: Date = new Date();
  language: SupportedLanguageCodes = inject(ConfigService).getLanguage();
  vinLabel: 'VIN' | 'Serial Number' = 'VIN';
  isChargerFlow: boolean = false;
  showTermInQuotePdf: boolean = environment.features.showTermInQuotePdf;

  constructor(
    private store: Store,
    private translate: TranslateService
  ) {
    this.coverage$ = this.store.select(selectCartState).pipe(
      map((cartState) => {
        if (cartState?.requestedQuote?.insResponse?.insurance?.coverage) {
          for (let i = 0; i < cartState.requestedQuote.insResponse.insurance.coverage.length; i++) {
            if (cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered === '1') {
              const { coverageSelection, borrowerCoverage } = cartState.checkoutFormValues.coverageInfoForm;
              if (coverageSelection === 'loanProtection' && borrowerCoverage === '2') {
                cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered = this.translate.instant(
                  'shoppingPage.checkout.grid.coBorrower'
                );
              } else {
                cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered = this.translate.instant(
                  'shoppingPage.checkout.grid.primaryBorrower'
                );
              }
            }

            if (cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered === '2') {
              cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered = this.translate.instant(
                'shoppingPage.checkout.grid.coBorrower'
              );
            }

            if (cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered === '3') {
              cartState.requestedQuote.insResponse.insurance.coverage[i].borrCovered = this.translate.instant(
                'shoppingPage.checkout.grid.jointBorrower'
              );
            }

            const coverageCode: string = cartState.requestedQuote.insResponse.insurance.coverage[i].covCode;

            const coverage =
              cartState.loanProtectionCoverageOptions.find((opt: OptionType) => opt.value === coverageCode) ||
              cartState.creditInsurancePackageCoverageOptions.find(
                (opt: CreditInsuranceCoverageOption) =>
                  opt.value.single === coverageCode || opt.value.joint === coverageCode
              );

            if (coverage) {
              cartState.requestedQuote.insResponse.insurance.coverage[i].coverageDescription = coverage.description;
            }
          }

          return cartState.requestedQuote.insResponse.insurance.coverage;
        }

        return [];
      })
    );

    this.vehicle$ = this.store.select(selectVehicleState).pipe(
      map((vehicleState) => {
        const vehicle = new DecodeVinResponse();
        const values = vehicleState.values;
        const chargerValues = vehicleState.chargerForm;
        this.isChargerFlow = vehicleState.chargerFormEnabled && !vehicleState.vehicleFormEnabled;
        this.vinLabel = this.isChargerFlow ? 'Serial Number' : 'VIN';
        vehicle.vin = this.isChargerFlow ? chargerValues.serialNumber : values.vin || 'NA';
        vehicle.make = (
          this.isChargerFlow ? (chargerValues.serialNumber ? 'POWERUP' : null) : values.make || ['NA']
        ) as string[];
        vehicle.model = (
          this.isChargerFlow ? chargerValues.chargerModel : values.other || values.model || ['NA']
        ) as string[];
        vehicle.year = (this.isChargerFlow ? chargerValues.chargerYear : values.year || ['NA']) as string[];

        return vehicle;
      })
    );
  }
}
