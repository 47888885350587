export enum VehicleType {
  ATV = 'ATV',
  Boat = 'BOAT',
  Motorcycle = 'MOTORCYCLE',
  Outboard = 'OUTBOARD',
  Scooters = 'SCOOTERS',
  SideBySides = 'SIDE-BY-SIDES',
  Snowmobile = 'SNOWMOBILE',
  Trailer = 'TRAILER',
  Watercraft = 'WATERCRAFT',
}
