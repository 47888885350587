import { Field } from '@ph-model/form/field';

export const creditInsuranceForm: Field[] = [
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'addInsurance',
    label: 'Do you want to add Credit Insurance?',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB0',
    name: 'loanTerm',
    label: 'Loan Term',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'loanAmount',
    label: 'Loan Amount',
    display: true,
    required: true,
    readOnly: true,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'monthlyPayment',
    label: 'Monthly Payment',
    display: true,
    required: true,
    readOnly: true,
    default: '',
    validations: [],
  },
  // {
  //   section: 'creditInsuranceForm',
  //   id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
  //   name: 'comonthlyPayment',
  //   label: 'CoBorrower Monthly Payment',
  //   display: true,
  //   required: false,
  //   readOnly: true,
  //   default: '',
  //   validations: [],
  // },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'loanEndDate',
    label: 'Loan End Date',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB0',
    name: 'downPayment',
    label: 'Down Payment',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'firstPaymentDate',
    label: 'First Payment Date',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'interestRate',
    label: 'Interest Rate',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'amortizationPeriod',
    label: 'Amortization Period',
    display: true,
    required: true,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: 'B78EEEA8-A838-41DF-B4DD-13D81CF53DB0',
    name: 'loanType',
    label: 'Loan Type',
    display: true,
    required: true,
    readOnly: true,
    default: 'C',
    validations: [],
  },
  // {
  //   section: 'creditInsuranceForm',
  //   id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
  //   name: 'residualBallon',
  //   label: 'Residual/Balloon',
  //   display: true,
  //   required: false,
  //   readOnly: false,
  //   default: '',
  //   validations: [],
  // },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'residualValue',
    label: 'Residual Value',
    display: true,
    required: true,
    readOnly: true,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'coMonthlyPayment',
    label: 'CoBorrower Monthly Payment',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  {
    section: 'creditInsuranceForm',
    id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
    name: 'primaryMonthlyPayment',
    label: 'Primary Monthly Payment',
    display: true,
    required: false,
    readOnly: false,
    default: '',
    validations: [],
  },
  // {
  //   section: 'creditInsuranceForm',
  //   id: '73E2BFAD-8E8F-4198-A015-7A13E236D0AC',
  //   name: 'newResidualValue',
  //   label: 'New Balloon Residual',
  //   display: true,
  //   required: false,
  //   readOnly: false,
  //   default: '',
  //   validations: [],
  // }
];
