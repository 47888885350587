export class LienholderInfo {
  constructor(
    public name: string,
    public address1: string,
    public address2: string,
    public city: string,
    public state: string,
    public zipCode: string,
    public countryCode: string,
    public email: string,
    public faxNumber: string,
    public primaryPhone: string,
    public seconderyPhone: string
  ) {}
}
