import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';

export const loginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const service = inject(AuthService);

  return service
    .isAuthenticated()
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        void router.navigate(['/home']);

        return false;
      } else {
        return true;
      }
    })
    .catch(() => true);
};
