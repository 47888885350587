import { AfterContentInit, Component, ElementRef, input, InputSignal } from '@angular/core';

@Component({
  selector: 'ph-select-dropdown-option',
  template: '<ng-content />',
  standalone: true,
})
export class SelectDropdownOptionComponent implements AfterContentInit {
  value: InputSignal<string> = input.required();

  private _label: string = '';

  get label(): string {
    return this._label;
  }

  constructor(private element: ElementRef) {}

  ngAfterContentInit(): void {
    this._label = this.element.nativeElement.innerHTML;
  }
}
