@for (notification of notifications$ | async; track trackByID($index, notification)) {
  <ph-notification
    (closed)="closeNotification($event)"
    [duration]="notification?.duration"
    [actions]="notification?.actions"
    [value]="notification?.value"
    [type]="notification?.type"
    [id]="notification?.id"
    [standalone]="false"
    @notificationReveal
  />
}
