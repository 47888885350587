import { FormGroup } from '@angular/forms';

import { environment } from '@ph-env/environment';

import { Options } from '@ph-core/enums';
import { Country } from '@ph-model/country-code';
import { UserResponse } from '@ph-model/login';
import { parseToNumber } from '@ph-shared/utils';
import * as CartState from '@ph-store/cart/cart.reducer';
import { VehicleState } from '@ph-store/vehicle/vehicle.reducer';

export interface SurchargeField {
  name: string;
  selected: boolean;
}

export class GetProductsRequest {
  pid?: string;
  hin?: string;
  tin?: string;
  financeTerm: string;
  programName: string;
  vehicleCost: string;
  lienholderName: string;
  version: string;
  methodName: string;
  applicationName: string;
  languageCode: string;
  channel: string;
  vendorName: string;
  userName: string;
  password: string;

  constructor(
    public dealer: string,
    public vehicleType: string = '',
    public vin: string = '',
    public engineCC: string = '',
    public saleDate: string,
    public inServiceDate: string,
    public isAfterSale: string,
    public vehiclePurchaseDate: string,
    public vehicleCondition: string,
    public financeType: string,
    public financeAmount: number | string,
    public vehicleYear: string,
    public vehicleMake: string,
    public vehicleModel: string,
    public vehicleTrim: string,
    public odometer: string,
    public msrp: string,
    public termMonth: string = '',
    public country: Country,
    public customerState: string = '',
    public dealerState: string = '',
    public defaultGetProduct: string = null,
    public surcharges: SurchargeField[] = [],
    public programId: string = '',
    public customerTaxExempt?: boolean,
    public negativeEquity?: boolean
  ) {
    this.financeTerm = '';
    this.lienholderName = '';
    this.languageCode = localStorage.getItem('languageCode')
      ? localStorage.getItem('languageCode').replace('-', '_')
      : 'en_US';
  }

  static getDynamicVinNumber(form: FormGroup, unitTypeLabel) {
    return form.get(unitTypeLabel).value;
  }

  static setGetProductsRequestByForm(form: FormGroup, user: UserResponse, unitTypeLabel: string): GetProductsRequest {
    // checking inservice date
    let inServiceDate = '';
    // as of 8/2 there is no functional date picker component
    // so had to add 'singleDate' after value to get formatted date value
    if (form.get('inServiceDate') && form.get('inServiceDate').value) {
      inServiceDate = form.get('inServiceDate').value.formatted;
    } else {
      inServiceDate = null;
    }

    const dynamicVin = GetProductsRequest.getDynamicVinNumber(form, unitTypeLabel);
    const stateProvince: string = environment.features.supportProvince ? form.get('province').value : null;
    const country: Country = environment.countryCode;

    return new GetProductsRequest(
      user.cmsDealerNumber,
      /* form.get('vehicleType').value */ null,
      dynamicVin,
      /* form.get('engineCC').value */ null,
      form.get('saleDate').value.formatted,
      inServiceDate,
      form.get('isAfterSale').value,
      form.get('purchaseDate').value.formatted,
      form.get('vehicleCondition').value,
      form.get('financeType').value,
      form.get('financeAmount').value,
      form.get('year').value,
      form.get('make').value,
      /* form.get('other').value || */ form.get('model').value,
      form.get('trim').value,
      form.get('odometer').value,
      form.get('msrp').value,
      /* form.get('termMonth').value */ null,
      country,
      stateProvince,
      user.dealerInfo.dealerState,
      user.defaultGetProduct,
      form.get('surcharges') ? form.get('surcharges').value : null,
      user.programId,
      form.get('customerTaxExempt').value ? form.get('customerTaxExempt').value === Options.YES : null,
      form.get('negativeEquity').value ? form.get('negativeEquity').value === Options.YES : null
    );
  }

  static setGetProductsRequestByState(
    user: UserResponse,
    cartState: CartState.CartState,
    vehicleState: VehicleState
  ): GetProductsRequest {
    const { cmsDealerNumber, defaultGetProduct, programId, dealerInfo } = user;
    const { serialNumber, chargerPurchaseDate, chargerModel, chargerYear } = vehicleState.chargerForm;
    const {
      vin,
      hin,
      pid,
      tin,
      saleDate,
      inServiceDate,
      year,
      make,
      model,
      odometer,
      msrp,
      trim,
      isAfterSale,
      vehicleCondition,
      financeType,
      financeAmount,
      surcharges,
      customerTaxExempt,
      negativeEquity,
    } = vehicleState.values;
    const vinValue: string = vin || hin || pid || tin || serialNumber;
    const saleDateValue: string = saleDate?.formatted || chargerPurchaseDate?.formatted;
    const { customer } = cartState.checkoutFormValues;

    return new GetProductsRequest(
      cmsDealerNumber,
      null,
      vinValue,
      null,
      saleDateValue,
      inServiceDate?.formatted || null,
      isAfterSale || null,
      saleDate?.formatted || null,
      (vehicleCondition as string) || 'USED',
      (financeType as string) || null,
      parseToNumber(financeAmount),
      year || chargerYear || '',
      make || '',
      model || chargerModel?.toUpperCase() || '',
      trim || '',
      odometer || '0',
      msrp || '0',
      null,
      customer.country as Country,
      customer.stateProvince,
      dealerInfo.dealerState,
      defaultGetProduct,
      (surcharges as SurchargeField[]) || null,
      programId,
      customerTaxExempt ? customerTaxExempt === Options.YES : null,
      negativeEquity ? negativeEquity === Options.YES : null
    );
  }
}
