<header ph-dialog-header>
  <h2 class="title" ph-notification-title>
    {{ (!errorInContracts ? 'remitSubmitPopup.contractsSuccess' : 'remitSubmitPopup.registerError') | translate }}
  </h2>
  <ph-icon name="cross" width="24px" height="24px" ph-overlay-close />
</header>

@if (!errorInContracts) {
  <ph-icon class="success-icon" height="90px" width="90px" name="check" />
  <p>{{ 'remitSubmitPopup.contractsSuccessReg' | translate }}</p>
}

@if (errorInContracts) {
  <ph-icon class="warning-icon" name="close" width="50px" height="50px" />
  @for (error of displayErrors; track error) {
    <p class="row">
      <span>{{ error.name }}</span>
      <span>{{ error.error }}</span>
    </p>
  }
  <p>{{ 'remitSubmitPopup.contactDealer' | translate }}</p>
}

<div class="popup-actions">
  @if (atLeastOneSuccessfulContract && supportContractRemittance) {
    <button ui-ph-button size="small" state="secondary" (click)="exportClicked()">
      {{ 'remitSubmitPopup.export' | translate }}
    </button>
    <button ui-ph-button size="small" state="secondary" (click)="printRemit()">
      {{ 'remitSubmitPopup.print' | translate }}
    </button>
  }
  <button ui-ph-button size="small" ph-overlay-close>{{ 'remitSubmitPopup.continue' | translate }}</button>
</div>
