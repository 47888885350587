import { Directive, ElementRef, HostListener, input, InputSignal } from '@angular/core';

import { specialKeys } from '@ph-static-data/keyboard-key-codes';

@Directive({
  selector: '[decimalNumbersOnly]',
  standalone: true,
})
export class DecimalNumberDirective {
  decimalNumbersOnly: InputSignal<boolean> = input.required();

  private readonly _specialKeys = specialKeys;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

  constructor(private _element: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.decimalNumbersOnly()) {
      const isCopyPasteAction = (event.metaKey || event.ctrlKey) && (event.key === 'c' || event.key === 'v');

      if (this._specialKeys.includes(event.key)) {
        return;
      }

      const current: string = this._element.nativeElement.value;
      const position = this._element.nativeElement.selectionStart;
      const next: string = [
        current.slice(0, position),
        event.key == 'Decimal' ? '.' : event.key,
        current.slice(position),
      ].join('');

      if (next && !String(next).match(this.regex) && !isCopyPasteAction) {
        event.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    if (this.decimalNumbersOnly()) {
      const clipboardData = event.clipboardData || window['clipboardData'];
      const pastedData = clipboardData.getData('Text');

      if (!pastedData.match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
