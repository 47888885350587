import { environment } from '@ph-env/environment';

import { UserResponse } from '@ph-model/login';

import { CartItem } from './cart-item.model';
import { GetProductsRequest } from '../request/product.request';

export class Cart {
  application: string;
  programId: string;
  userName: string;
  cmsDealerNumber: string;
  productForm: GetProductsRequest;
  products: CartItem[];

  constructor(products: CartItem[] = [], productForm?: GetProductsRequest, user: UserResponse = new UserResponse()) {
    // application: user.
    this.application = environment.application;
    this.programId = user.programId;
    this.userName = user.userName;
    this.cmsDealerNumber = user.cmsDealerNumber;
    this.productForm = productForm;
    this.products = products;
  }
}
