import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  GlobalPositionStrategy,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import { ElementRef, Injectable } from '@angular/core';

import {
  PhConnectedPosition,
  PhOverlayHorizontalPosition,
  PhOverlayPosition,
  PhOverlayVerticalPosition,
} from './overlay.interface';

@Injectable({
  providedIn: 'root',
})
export class PhOverlayPositionBuilder {
  constructor(private pb: OverlayPositionBuilder) {}

  connectedPosition(
    origin: ElementRef | Element,
    position: PhConnectedPosition | PhConnectedPosition[] | string[] | string
  ): FlexibleConnectedPositionStrategy {
    return this.pb.flexibleConnectedTo(origin).withPositions(this._parseConnectedPositions(position)).withPush(false);
  }

  position({ horizontal, vertical }: PhOverlayPosition): GlobalPositionStrategy {
    const position = this.pb.global();
    this._parseHorizontalPosition(position, horizontal);
    this._parseVerticalPosition(position, vertical);

    return position;
  }

  private _parseVerticalPosition(
    builder: GlobalPositionStrategy,
    position: PhOverlayVerticalPosition
  ): GlobalPositionStrategy {
    switch (position) {
      case PhOverlayVerticalPosition.center:
      default:
        return builder.centerVertically();
      case PhOverlayVerticalPosition.bottom:
        return builder.bottom();
      case PhOverlayVerticalPosition.top:
        return builder.top();
    }
  }

  private _parseHorizontalPosition(
    builder: GlobalPositionStrategy,
    position: PhOverlayHorizontalPosition
  ): GlobalPositionStrategy {
    switch (position) {
      case PhOverlayHorizontalPosition.center:
      default:
        return builder.centerHorizontally();
      case PhOverlayHorizontalPosition.start:
        return builder.left();
      case PhOverlayHorizontalPosition.end:
        return builder.right();
    }
  }

  private _parseConnectedPositions(
    position: PhConnectedPosition[] | PhConnectedPosition | string[] | string
  ): ConnectedPosition[] {
    const positions = [];

    if (typeof position === 'string') {
      positions.push(position);
    } else if (position) {
      positions.push(...position);
    } else {
      positions.push(PhConnectedPosition.after);
    }

    return [...positions].map((value) => this._parseConnectedPosition(value));
  }

  private _parseConnectedPosition(position: PhConnectedPosition | string): ConnectedPosition {
    const horizontal = { overlayY: 'center', originY: 'center' };
    const vertical = { overlayX: 'center', originX: 'center' };

    const above = { overlayY: 'bottom', originY: 'top' };
    const below = { overlayY: 'top', originY: 'bottom' };
    const before = { overlayX: 'end', originX: 'start' };
    const after = { overlayX: 'start', originX: 'end' };

    switch (position as PhConnectedPosition) {
      case PhConnectedPosition.before:
      default:
        return { ...horizontal, ...before } as ConnectedPosition;
      case PhConnectedPosition.after:
        return { ...horizontal, ...after } as ConnectedPosition;
      case PhConnectedPosition.above:
        return { ...vertical, ...above } as ConnectedPosition;
      case PhConnectedPosition.below:
        return { ...vertical, ...below } as ConnectedPosition;

      case PhConnectedPosition.belowStart:
        return { ...below, ...before } as ConnectedPosition;
      case PhConnectedPosition.belowEnd:
        return { ...below, ...after } as ConnectedPosition;
      case PhConnectedPosition.aboveStart:
        return { ...above, ...before } as ConnectedPosition;
      case PhConnectedPosition.aboveEnd:
        return { ...above, ...after } as ConnectedPosition;

      case PhConnectedPosition.belowAlignLeft:
        return { ...below, ...before, overlayX: 'start' } as ConnectedPosition;
      case PhConnectedPosition.belowAlignRight:
        return { ...below, ...after, overlayX: 'end' } as ConnectedPosition;
      case PhConnectedPosition.aboveAlignLeft:
        return { ...above, ...before, overlayX: 'start' } as ConnectedPosition;
      case PhConnectedPosition.aboveAlignRight:
        return { ...above, ...after, overlayX: 'end' } as ConnectedPosition;
    }
  }
}
