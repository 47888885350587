import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  input,
  InputSignal,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';

import { PH_OVERLAY_DATA } from '@ph-core/services/overlay';
import { ContractHistoryComponent } from '@ph-shared/components';

@Directive({
  selector: '[phContractHistoryPopup]',
  standalone: true,
})
export class ContractHistoryDirective implements OnDestroy {
  contractId: InputSignal<string> = input.required();

  private overlayRef: OverlayRef | null = null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef
  ) {}

  @HostListener('click')
  showTooltip(): void {
    if (this.overlayRef?.hasAttached()) {
      return;
    }

    this.attachTooltip();
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  private attachTooltip(): void {
    if (!this.overlayRef) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        panelClass: 'dialog',
        hasBackdrop: true,
      });
    }

    this.overlayRef.backdropClick().subscribe(() =>
      setTimeout(() => {
        if (this.overlayRef?.hasAttached()) {
          this.overlayRef?.detach();
        }
      }, 0)
    );

    const injector = Injector.create({
      providers: [
        { provide: PH_OVERLAY_DATA, useValue: { contractId: this.contractId() } },
        { provide: OverlayRef, useValue: this.overlayRef },
      ],
    });
    const component = new ComponentPortal(ContractHistoryComponent, this.viewContainer, injector);
    this.overlayRef.attach(component);
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          panelClass: 'top',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          panelClass: 'bottom',
        },
      ]);
  }
}
