export class TaxInfo {
  constructor(
    public country: string,
    public taxType: string,
    public taxPercentage: string,
    public taxAmount: number,
    public taxRegistrationNumber: string,
    public taxVendor: string,
    public taxVendorTransactionId: string,
    public taxOverride: boolean
  ) {}
}
