import { FormControl } from '@angular/forms';

export function ChargerSerialValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  const letterNumber = /^[0-9a-zA-Z]+$/;
  if (value && (value.length !== 16 || !value.match(letterNumber))) {
    return {
      error: true,
      errorMessage: 'errors.chargerSerial',
    };
  }

  return null;
}

export function ChargerYearValidator(control: FormControl): null | { error: boolean; errorMessage: string } {
  const value = control.value;
  if (value && (value < 2022 || value > 2050)) {
    return {
      error: true,
      errorMessage: 'errors.chargerYear',
    };
  }

  return null;
}
