import { IComplexDate } from '@ph-shared/utils';

export interface BECompatibleDate {
  date: {
    year: number;
    month: number;
    day: number;
  };
  jsdate: Date;
  formatted: string;
  epoc: number;
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function convertToCompatibleDateFormat(date: Date | IComplexDate): BECompatibleDate | unknown {
  if (date instanceof Date) {
    const month = date.getMonth() + 1;
    const formattedMonth = `${month.toString().length === 1 ? '0' + month : month}`;
    const formattedDay = `${date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate()}`;
    const epoc = Math.round(date.getTime() / 1000);

    return {
      date: {
        year: date.getFullYear(),
        month,
        day: date.getDate(),
      },
      jsdate: date,
      formatted: `${date.getFullYear()}/${formattedMonth}/${formattedDay}`,
      epoc,
    };
  } else {
    return date;
  }
}
