import { ContractOperationType } from './contract-operation-type.model';

export interface ContractOperationRecord {
  id: string;
  contractId: string;
  userName: string;
  userDisplayName: string;
  channel?: string;
  vendor?: string;
  platform?: string;
  date: string;
  operation: ContractOperationType;
  status: string;
}
