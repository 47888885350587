import { Injectable } from '@angular/core';

import { GetProductsResponse, ProductItem } from '@ph-model/response';

@Injectable({ providedIn: 'root' })
export class SortingService {
  static sortStrings<T extends { name: string }>(arrayToSort: T[]): T[] {
    arrayToSort?.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        // sort string ascending
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0; // default return value (no sorting)
    });

    return arrayToSort;
  }

  static sortProductResponse(response: GetProductsResponse): GetProductsResponse {
    if (response.availableOptions?.products?.productItem) {
      const productItems = response.availableOptions.products.productItem?.sort((a: ProductItem, b: ProductItem) => {
        if (a.productCategory === 'PPM' || a.productCategory === 'LWT') {
          return a.planName.enUsPlanName.localeCompare(b.planName.enUsPlanName, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }
      });

      response.availableOptions.products.productItem = [...productItems];
    }

    return response;
  }
}
