import { Field } from '../../form/field';
import { OptionType } from '../../form/option-type.model';

export class ProductFormResponse {
  productForm: Field[];
  financeType: OptionType[];
  isAfterSale: OptionType[];
  vehicleCondition: OptionType[];
  vehicleType: OptionType[];
  province: OptionType[];
  powerUpModels: OptionType[];
  customerTaxExempt: OptionType[];

  constructor() {
    this.productForm = [];
    this.financeType = [];
    this.isAfterSale = [];
    this.vehicleCondition = [];
    this.vehicleType = [];
    this.province = [];
    this.powerUpModels = [];
  }
}
