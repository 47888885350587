import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureKey, ProductState } from './product.reducer';

export const selectProductState = createFeatureSelector<ProductState>(featureKey);
export const selectVisibleProducts = createSelector(selectProductState, (state) => state.visibleProducts);
export const selectAllProducts = createSelector(selectProductState, (state) => state.allProducts);
export const selectProductRequest = createSelector(selectProductState, (state) => state.productsRequest);
export const selectProductMarkup = createSelector(selectProductState, (state) => state.markup);
export const selectProductFilters = createSelector(selectProductState, (state) => state.filters);
