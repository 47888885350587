import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, computed, input, InputSignal, output, OutputEmitterRef, Signal } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { ToggleStyle } from './accordion.model';
import { IconComponent } from '../icon/icon-component';
import { IconName } from '../icon/icons';

@Component({
  selector: 'ph-accordion-section',
  templateUrl: './accordion-section.component.html',
  styleUrls: ['./accordion-section.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class AccordionSectionComponent {
  toggled: OutputEmitterRef<string> = output<string>();

  title: InputSignal<string> = input.required();
  disabled: InputSignal<boolean> = input(false);
  toggleStyle: InputSignal<ToggleStyle> = input(ToggleStyle.Arrow);
  expanded: InputSignal<string | boolean> = input<boolean, string | boolean>(false, {
    transform: coerceBooleanProperty,
  });

  id: string = uuidv4();

  iconName: Signal<IconName> = computed(() => {
    switch (this.toggleStyle()) {
      case ToggleStyle.Arrow:
      default:
        return this.expanded() ? 'expand_less' : 'expand_more';
      case ToggleStyle.Triangle:
        return this.expanded() ? 'arrow_up' : 'arrow_down';
    }
  });

  toggleExpand(): void {
    if (this.disabled()) {
      return;
    }

    this.toggled.emit(this.id);
  }
}
