import { Injectable, signal, WritableSignal } from '@angular/core';

import { notificationDefaultDuration } from '../../../static-data/notification-durations';

interface ErrorState {
  status: string;
  message: string;
  show: boolean;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  error: WritableSignal<ErrorState> = signal({
    status: '',
    message: '',
    show: false,
    duration: notificationDefaultDuration,
  });

  displayErrorMessage(status: number, text: string, duration: number = notificationDefaultDuration): void {
    this.error.set({ status: status.toString(), message: text, show: true, duration });
  }

  hideErrorMessage(): void {
    this.error.set({ status: '', message: '', show: false });
  }
}
