export enum ShoppingStepsUrls {
  Noop = 'noop-url',
  DealInformation = 'deal-information',
  AncillaryProductSelection = 'ancillary-product-selection',
  CartSummary = 'cart-summary',
  CreditorProductSelection = 'creditor-product-selection',
  CustomerInformation = 'customer-information',
  CreateContract = 'create-contract',
  map = 'map',
  Empty = '',
}
