import { createAction, props } from '@ngrx/store';

import { ShoppingFlowType } from '@ph-core/enums';
import { ShoppingStepDefinition, StepperNavigationOptions } from '@ph-shared/modules/shopping-stepper';

import { ShoppingStep } from '../models/shopping-step.model';

export const initializeSteps = createAction(
  '[SHOPPING_STEPPER] INITIALIZE STEPS',
  props<{ stepDefinitions: ShoppingStepDefinition[] }>()
);
export const switchShoppingPath = createAction('[SHOPPING_STEPPER] SWITCH PATH', props<{ name: ShoppingFlowType }>());
export const nextStep = createAction('[SHOPPING_STEPPER] NEXT STEP');
export const prevStep = createAction('[SHOPPING_STEPPER] PREV STEP');
export const switchToStep = createAction(
  '[SHOPPING_STEPPER] SWITCH TO STEP',
  props<{ index: number; options?: StepperNavigationOptions }>()
);
export const switchToStepByUrl = createAction(
  '[SHOPPING_STEPPER] SWITCH TO STEP BY URL',
  props<{ url: string; options?: StepperNavigationOptions }>()
);
export const updateShoppingStepsData = createAction(
  '[SHOPPING_STEPPER] UPDATE SHOPPING STEPS DATA',
  props<{ data: Record<string, unknown> }>()
);
export const resetShoppingProgress = createAction('[SHOPPING_STEPPER] RESET SHOPPING PROGRESS');
export const resetShoppingProgressToFirstStep = createAction(
  '[SHOPPING_STEPPER] RESET SHOPPING PROGRESS TO FIRST STEP'
);
export const updateCurrentStep = createAction(
  '[SHOPPING_STEPPER] UPDATE CURRENT STEP',
  props<{ data: Partial<ShoppingStep> }>()
);
export const updateStepByUrl = createAction(
  '[SHOPPING_STEPPER] UPDATE STEP BY URL',
  props<{ url: string; data: Partial<ShoppingStep> }>()
);
export const markAllStepsAsValidAndVisited = createAction('[SHOPPING_STEPPER] MARK ALL STEPS AS VALID AND VISITED');
export const markAllStepsWithNoDestination = createAction('[SHOPPING_STEPPER] MARK ALL STEPS WITH NO DESTINATION');
