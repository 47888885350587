import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, TemplateRef } from '@angular/core';

export type HintData = string | TemplateRef<void>;
export const HINT_DATA = new InjectionToken<HintData>('HINT_DATA');

@Component({
  templateUrl: './hint-container.component.html',
  styleUrls: ['./hint-container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet],
})
export class HintContainerComponent {
  get asString(): string | false {
    return typeof this.hintData === 'string' ? this.hintData : false;
  }

  get asTemplate(): TemplateRef<void> | false {
    return this.hintData instanceof TemplateRef ? this.hintData : false;
  }

  constructor(@Inject(HINT_DATA) public hintData: HintData) {}
}
