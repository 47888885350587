import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'econContractNumber',
  standalone: true,
})
export class EconContractNumberPipe implements PipeTransform {
  transform(text: string): string {
    if (!text || !text.startsWith('SG')) {
      return text;
    }

    return text.replace('SG', '');
  }
}
