import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { toSignal } from "@angular/core/rxjs-interop";

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { switchToStep } from '../store/shopping-stepper.actions';
import { selectCurrentShoppingStepIndex, selectCurrentShoppingSteps } from '../store/shopping-stepper.selectors';

@Component({
  selector: 'ph-shopping-stepper',
  templateUrl: './shopping-stepper.component.html',
  styleUrls: ['./shopping-stepper.component.scss'],
  standalone: true,
  imports: [NgClass, AsyncPipe, TranslateModule],
})
export class ShoppingStepperComponent {
  steps = toSignal(this.store.select(selectCurrentShoppingSteps));
  currentStepIdx = toSignal(this.store.select(selectCurrentShoppingStepIndex));

  constructor(private store: Store) {}

  goToStep(index: number): void {
    this.store.dispatch(switchToStep({ index, options: { updateProgress: false } }));
  }
}
