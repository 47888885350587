import { Injectable, Injector, ProviderToken } from '@angular/core';

import { Store } from '@ngrx/store';

import { ContractType } from '@ph-model/api';
import { resetTemplateServiceLoading, setTemplateServiceLoading } from '@ph-store/config/config.actions';

import { TemplateService } from './template.service';

@Injectable({
  providedIn: 'root',
})
export class LazyTemplateService {
  private _templateService: TemplateService | undefined;

  constructor(
    private injector: Injector,
    private store: Store
  ) {}

  async printRemit(contracts: ContractType[] = [], remitted: boolean, navbarLogo: string, totalDealerCost: number) {
    const service = await this._initTemplateService();

    return service.printRemit(contracts, remitted, navbarLogo, totalDealerCost);
  }

  async printQuote(navbarLogo: string) {
    const service = await this._initTemplateService();

    return service.printQuote(navbarLogo);
  }

  private async _initTemplateService(): Promise<TemplateService> {
    if (!this._templateService) {
      this.store.dispatch(setTemplateServiceLoading());
      this._templateService = await this.get<TemplateService>(() =>
        import('./template.service').then((m) => m.TemplateService)
      );
      this.store.dispatch(resetTemplateServiceLoading());
    }

    return Promise.resolve(this._templateService);
  }

  private async get<T>(providerLoader: () => Promise<ProviderToken<T>>) {
    return this.injector.get(await providerLoader());
  }
}
