import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductFormResponse } from '@ph-model/api/response/product-form.response.model';

import { VehicleState, featureKey } from './vehicle.reducer';

export const selectVehicleState = createFeatureSelector<VehicleState>(featureKey);
export const selectVehicleValues = createSelector(selectVehicleState, (state) => state.values);
export const selectVehicleHasIdentificationNumber = createSelector(
  selectVehicleValues,
  ({ vin, pid, hin, tin }) => !!vin || !!pid || !!hin || !!tin
);
export const selectVehicleFinanceForm = createSelector(selectVehicleState, (state) => state.financeForm);
export const selectProductForm = createSelector(selectVehicleState, (state) => state.productForm);
export const selectBorrowInfoForm = createSelector(selectVehicleState, (state) => state.borrowInfoForm);
export const selectCoBorrower = createSelector(selectVehicleState, (state) => state.values.coBorrower);
export const selectManualDecode = createSelector(selectVehicleState, (state) => state.manualDecode);
export const selectVinDecodeStatus = createSelector(selectVehicleState, (state) => state.vin.status);
export const selectProductFormValues = createSelector(selectVehicleState, (state) => state.values);
export const selectSurchargesSelection = createSelector(selectVehicleState, (state) => state.selectedSurcharges);

export const selectPowerUpOptions = createSelector(
  selectProductForm,
  (state: ProductFormResponse) => state.powerUpModels
);

export const selectFinanceAmount = createSelector(selectVehicleState, (state) => state.financeForm?.financeAmount);
export const selectDecodeVinResponse = createSelector(selectVehicleState, (state) => state.vin);
export const selectDisableInServiceDate = createSelector(selectVehicleState, (state) => state.disableInServiceDate);
