import { SupportedLanguageCodes } from '@ph-static-data/languages';

import { ClientConfigImages } from './client-config-images.model';
import { ClientInfoI18n } from './client-info-i18n.model';
import { ClientInfo } from './client-info.model';

export class ClientConfigResponse {
  status: string;
  captcha: {
    data: string;
    text: string;
    required: boolean;
    encText: string;
  };
  captchaRequired: boolean;
  programName: string;
  isProgramSSO: string;
  programMake: string;
  userSSOSuffix: string;
  programId: string;
  clientInfo: ClientInfo;
  clientInfo_i18n: ClientInfoI18n[];
  applicationId: string;
  loginBox: {
    boldTitle: boolean;
    leftJustify: boolean;
  };
  multilingual: string;
  leftJustify: boolean;
  languageCode: SupportedLanguageCodes;
  css: {
    logoBkgrdColor: string;
    primaryColor: string;
    secondaryColor: string;
    brandBarColor: string;
    fontColor: string;
    buttonColor: string;
  };
  images: ClientConfigImages;
  imageIds: {
    imageName: string;
    imageId: string;
    image: string;
  }[];
  languages: { name: string; code: SupportedLanguageCodes }[];

  constructor() {
    this.images = new ClientConfigImages();
    this.captchaRequired = false;
    this.imageIds = [];
  }
}
