import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  QueryList,
  Signal,
  TemplateRef,
  viewChild,
  ViewChild,
} from '@angular/core';

import { merge, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PhAutocompleteContentDirective } from './autocomplete-content.directive';
import { PhOptionComponent } from './ph-option/ph-option.component';

@Component({
  selector: 'ph-autocomplete',
  template: `
    <ng-template #root>
      <div class="autocomplete" #autocompleteRef>
        <ng-container *ngTemplateOutlet="content.tpl" />
      </div>
    </ng-template>
  `,
  exportAs: 'phAutocomplete',
  styleUrls: ['./ph-autocomplete.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class PhAutocompleteComponent {
  @ViewChild('autocompleteRef') autocompleteRef: ElementRef;

  @ContentChild(PhAutocompleteContentDirective) content: PhAutocompleteContentDirective;

  @ContentChildren(PhOptionComponent)
  set options(options: QueryList<PhOptionComponent>) {
    this._options = options;
    setTimeout(() => {
      if (this.autocompleteRef) {
        this.autocompleteRef.nativeElement.style.display = !options.length ? 'none' : 'block';
      }
    });
  }

  get options(): QueryList<PhOptionComponent> {
    return this._options;
  }

  rootTemplate: Signal<TemplateRef<unknown>> = viewChild.required('root', { read: TemplateRef });

  private _options: QueryList<PhOptionComponent>;

  optionsClick(): Observable<unknown> {
    return this.options.changes.pipe(switchMap((options) => merge(...options.map((option) => option.click$))));
  }
}
