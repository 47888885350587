import { createAction, props } from '@ngrx/store';

import { ProductFilters } from '@ph-model/filters/product-filters';
import { Markup } from '@ph-model/login';
import { GetPowerUpProductsRequest } from '@ph-model/request/powerup-product.request';
import { GetProductsRequest } from '@ph-model/request/product.request';
import { GetRatesRequest } from '@ph-model/request/rates.request';
import { GetProductsResponse, ProductItem } from '@ph-model/response';

export const getAncillaryProducts = createAction(
  '[PRODUCT] GET ANCILLARY PRODUCTS',
  props<{ request: GetProductsRequest; ratesRequest?: GetRatesRequest }>()
);
export const getPowerUpProducts = createAction(
  '[PRODUCT] GET POWER UP PRODUCTS',
  props<{ request: GetPowerUpProductsRequest }>()
);
export const getPowerUpAndAncillaryProducts = createAction(
  '[PRODUCT] GET POWER UP AND ANCILLARY PRODUCTS',
  props<{ powerUpRequest: GetPowerUpProductsRequest; ratesRequest: GetRatesRequest }>()
);
export const getProductsSuccess = createAction(
  '[PRODUCT] GET PRODUCTS SUCCESS',
  props<{ response: GetProductsResponse; products: ProductItem[]; filters: ProductFilters; markup: Markup }>()
);
export const getProductsFailure = createAction('[PRODUCT] Get PRODUCTS FAILURE', props<{ error: string }>());
export const applyFilters = createAction('[PRODUCT] FILTER PRODUCTS', props<{ filters: ProductFilters }>());
export const resetProducts = createAction('[PRODUCT] RESET PRODUCTS');
export const loadProductQuote = createAction(
  '[PRODUCT] LOAD PRODUCT QUOTE',
  props<{
    request: GetProductsRequest;
    response: GetProductsResponse;
    gridProducts: ProductItem[];
    filters: ProductFilters;
    markup: Markup;
  }>()
);
export const changeMarkup = createAction('[PRODUCT] CHANGE MARKUP', props<{ markup: Markup }>());
export const updateGrid = createAction(
  '[PRODUCT] UPDATE GRID',
  props<{ product: ProductItem; delta: Partial<ProductItem> }>()
);
export const applySurchargesSelection = createAction(
  '[PRODUCT] APPLY SURCHARGES SELECTION',
  props<{ surchargesSelection: { name: string; selected: boolean }[] }>()
);
export const manualCustomerCostOverride = createAction(
  '[PRODUCT] MANUAL CUSTOMER COST OVERRIDE',
  props<{ product: ProductItem; customerCost: string }>()
);
