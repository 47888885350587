export enum Role {
  CorporateSales = 'Corporate_Sales',
  Corporate = 'Corporate',
  Dealer = 'Dealer',
  DealerAdmin = 'DealerAdmin',
  DealerCorporateAdmin = 'DealerCorporateAdmin',
  DealerEmulateAll = 'Dealer Emulate All',
  DealerEmulateAllOkta = 'Corporate_Emulation',
  DealerEmulation = 'Dealer Emulation',
  FIManager = 'FIManager',
  Finance = 'Finance',
  Maintenance = 'Maintenance',
  SalesRep = 'SalesRep',
  BackOffice = 'BackOffice',
  Admin = 'admin',
}
