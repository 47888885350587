import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { PH_OVERLAY_DATA, PhOverlayRef } from '@ph-core/services/overlay';
import { Pattern, RequiredValidator, SameValue } from '@ph-core/validations';
import { UpdatePasswordRequest } from '@ph-model/request/update-password.request';
import { untilDestroyed } from '@ph-shared/utils';
import * as ConfigReducer from '@ph-store/config/config.reducer';
import { selectConfigState } from '@ph-store/config/config.selectors';

import { FormInputControlComponent } from '../form-input-control/form-input-control.component';
import { IconComponent } from '../icon/icon-component';
import { PhButtonComponent } from '../ph-button/ph-button.component';

@Component({
  templateUrl: './update-password-popup.component.html',
  styleUrls: ['./update-password-popup.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormInputControlComponent, PhButtonComponent, TranslateModule, IconComponent],
})
export class UpdatePasswordPopupComponent implements OnInit {
  requestForm: FormGroup;
  emailSuccess: boolean = false;

  private _isSSOFlow: boolean = environment.auth.sso;

  readonly #untilDestroyed = untilDestroyed();

  constructor(
    private store: Store,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    @Inject(PH_OVERLAY_DATA)
    public data: {
      username: string;
      stateToken?: string;
      flowType: 'OKTA' | 'LEGACY';
      actionType: 'UPDATE' | 'RESET';
    },
    private ref: PhOverlayRef<UpdatePasswordPopupComponent>
  ) {}

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      currentPassword: this.fb.control(null, this.data?.flowType === 'LEGACY' ? [RequiredValidator] : []),
      newPassword: this.fb.control(null, [
        RequiredValidator,
        Pattern(
          this.data?.flowType === 'OKTA'
            ? /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{12,}$/
            : /^(?=.*[A-Z])(?=.*[!@#$%^&*_=+-])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
          'errors.passwordComplexity'
        ),
      ]),
      confirmPassword: this.fb.control(null, [RequiredValidator, SameValue('newPassword', 'errors.samePassword')]),
    });
  }

  updatePassword(): void {
    if (this.requestForm.invalid) {
      return;
    }

    if (this._isSSOFlow && this.data?.flowType === 'OKTA' && this.data?.actionType === 'RESET') {
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      });

      const payload = {
        username: this.data?.username,
        stateToken: this.data?.stateToken,
        password: this.requestForm.get('newPassword').value,
      };

      this.httpClient
        .post<void>(`${environment.apiUrl}/okta/resetpassword`, payload, { headers })
        .pipe(
          catchError((error) => throwError(() => error)),
          tap(() => (this.emailSuccess = true))
        )
        .subscribe();
    } else {
      this.store
        .select(selectConfigState)
        .pipe(
          take(1),
          switchMap((data: ConfigReducer.ConfigState) => {
            const request: UpdatePasswordRequest = {
              application: environment.application,
              programId: data.clientConfig.programId,
              username: this.data.username,
              password: this.requestForm.get('currentPassword').value,
              newPassword: this.requestForm.get('newPassword').value,
              confirmNewPassword: this.requestForm.get('confirmPassword').value,
              noCaptcha: true,
            };

            return this.httpClient
              .post<{ status: boolean }>(`${environment.apiUrl}/updatepassword`, request)
              .pipe(catchError((error) => throwError(() => error)));
          }),
          filter((data) => data && data.status),
          tap(() => {
            this.emailSuccess = true;
            this.requestForm.reset();
          }),
          catchError((err: Error) => {
            this.requestForm.reset();

            return throwError(() => err);
          }),
          this.#untilDestroyed()
        )
        .subscribe();
    }
  }

  close(): void {
    this.ref.close();
  }
}
